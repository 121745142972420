import { MAIN_CHAIN } from 'containers/Connector/config';
import { NetworkId } from 'sdk/types/common';
import { Language } from 'translations/constants';

// app defaults
export const DEFAULT_LANGUAGE: Language = Language.EN;

// network defaults
export const DEFAULT_NETWORK_ID: NetworkId = MAIN_CHAIN.id;

// ui defaults
export const DEFAULT_SEARCH_DEBOUNCE_MS = 300;
export const DEFAULT_CRYPTO_DECIMALS = 4;
export const DEFAULT_FIAT_DECIMALS = 2;
export const DEFAULT_NUMBER_DECIMALS = 2;
export const DEFAULT_PERCENT_DECIMALS = 2;

// for Trading History
export const DEFAULT_NUMBER_OF_TRADES: number = 16;
export const MAX_TIMESTAMP: number = 8640000000000000;

// for perps v2
export const DEFAULT_PRICE_IMPACT_DELTA = '500000000000000000';
export const DEFAULT_DELAYED_EXECUTION_BUFFER = 30;

export const CROSS_MARGIN_ENABLED = false;

export const DEFAULT_LEVERAGE = '1';
