import NFTIcon from "assets/png/logo/synthesis_half_crop.png";
import FFIcon from "assets/png/logo/fflogo_transparent2.png";
import BaseModal from "components/BaseModal";
import Input from 'components/Input/Input';
import * as Text from 'components/Text';
import Webp from "components/Webp";
import { FlexDivColCentered } from "components/layout/flex";
import { GridDiv } from "components/layout/grid";
import { EXTERNAL_LINKS } from "constants/links";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StackSection } from "sections/homepage/section";
import { Copy } from 'sections/homepage/text';
import { MintFooterCard } from "sections/token/cards/MintFooterCard";
import { closeModal } from "state/balances/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { checkCode } from "state/token/actions";
import { selectCode, selectValidCode } from "state/token/selector";
import styled from "styled-components";
import media from "styles/media";

type TxInviteModalProps = {
    attemptRetry: () => void;
};

export const InviteModal: FC<TxInviteModalProps> = ({ attemptRetry }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    

    const onDismiss = useCallback(() => {
        dispatch(checkCode(''));
        dispatch(closeModal());
    }, [dispatch]);

    const callCheckCode = useCallback((e: string) => {
        dispatch(checkCode(e));
    }, [dispatch]);

    const onCodeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            callCheckCode(e.target.value);
        }
    , []);

    const validCode = useAppSelector(selectValidCode);
    const code = useAppSelector(selectCode);

    return (
        <StyledBaseModal onDismiss={onDismiss} isOpen title={""}>
			<StackSection>
                <Container>
                    <ExchangeTitle>{t('modals.invite.title')}</ExchangeTitle>
                    <FlexDivColCentered>
                        <Webp srcOrSrcset={FFIcon} StyledImg={NFTImage} />
                    </FlexDivColCentered>
                    <FlexDivColCentered>
                        <GrayCopy>{t('modals.invite.nft')}</GrayCopy>
                    </FlexDivColCentered>
                    <FlexDivColCentered>
                        <GrayCopy>
                        {}
                        </GrayCopy>
                    </FlexDivColCentered>
                </Container>
            </StackSection>
		</StyledBaseModal>
    );
};

const RedeemCardContainer = styled(FlexDivColCentered)`
    padding-top: 0px;
    ${media.lessThan('sm')`
        font-size: 15px;
    `}
`;

const NFTImage = styled.img`
    width: 500px;
    ${media.lessThan('md')`
        width: 400px;
    `}
    ${media.lessThan('sm')`
        width: 300px;
    `}
	padding: 1px;
	border-radius: 8px;
`

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		width: 520px;
	}
	.card-body {
		padding: 24px;
	}
`;

const Container = styled(GridDiv)`
	overflow: hidden;
	justify-content: center;
	padding: 0px 0px;
`;

const ExchangeTitle = styled(Text.Body).attrs({ weight: 'bold' })`
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	font-size: 30px;
	text-align: center;
`;

const GrayCopy = styled(Copy)`
	margin-top: 8px;
	text-align: center;
	width: 470px;
	font-size: 18px;
	line-height: 100%;
	color: ${(props) => props.theme.colors.selectedTheme.text.clear};
	margin-bottom: 0px;
    ${media.lessThan('sm')`
        width: 405px;
        font-size: 16px;
    `}
`;

const InviteInputContainer = styled(FlexDivColCentered)`
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
`;

const InviteInput = styled(Input)<{ border: boolean }>`
	position: relative;
    padding-top: 20px;
	height: 100%;
    text-align: center;
	border-radius: 8px;
	padding: 10px 15px;
	font-size: 20px;
	background: ${(props) =>
		props.border ? props.theme.colors.selectedTheme.input.secondary.background : 'none'};

	border: ${(props) => (props.border ? props.theme.colors.selectedTheme.border : 'none')};
    ${media.lessThan('sm')`
        font-size: 16px;
    `}
`;