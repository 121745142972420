import { BigNumber } from 'ethers';

export const GWEI_DECIMALS = 9;
export const ETH_UNIT = 1000000000000000000;
export const STALL_TIMEOUT = 5000;
export type GasLimitEstimate = BigNumber | null;

export const SUPPORTED_NETWORKS = [
	1, // Ethereum (mainnet)
	5, // Goerli (testnet)
	10, // Optimism (mainnet)
	420, // Optimism Goerli (testnet)
	168587773, // Blast Sepolia (testnet)
];