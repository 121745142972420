import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ORDER_PREVIEW_ERRORS } from 'queries/futures/constants';
import {
	FuturesMarket,
	FuturesMarketAsset,
	FuturesMarketKey,
	FuturesPotentialTradeDetails,
	PositionSide,
} from 'sdk/types/futures';
import {
	DEFAULT_MAP_BY_NETWORK,
	DEFAULT_QUERY_STATUS,
	LOADING_STATUS,
	SUCCESS_STATUS,
	ZERO_STATE_TRADE_INPUTS
} from 'state/constants';
import { FetchStatus } from 'state/types';
import { MarketKeyByAsset } from 'utils/futures';

import {
	fetchAllTradesForAccount,
	fetchDualityForSelectedMarket,
	fetchIsolatedMarginPositions,
	fetchMarkets,
	fetchTradesForSelectedMarket,
	refetchPosition,
	submitApprove
} from './actions';
import {
	FuturesState,
	InputCurrencyDenomination,
	IsolatedMarginTradeInputs,
	TransactionEstimationPayload,
	TransactionEstimations,
} from './types';

export const FUTURES_INITIAL_STATE: FuturesState = {
	confirmationModalOpen: false,
	markets: [],
	errors: {},
	selectedInputDenomination: 'usd',
	queryStatuses: {
		markets: DEFAULT_QUERY_STATUS,
		isolatedPositions: DEFAULT_QUERY_STATUS,
		isolatedTradePreview: DEFAULT_QUERY_STATUS,
		trades: DEFAULT_QUERY_STATUS,
		duality: DEFAULT_QUERY_STATUS,
		approvalStatus: DEFAULT_QUERY_STATUS,
	},
	transactionEstimations: {} as TransactionEstimations,
	isolatedMargin: {
		accounts: DEFAULT_MAP_BY_NETWORK,
		selectedMarketAsset: FuturesMarketAsset.ETH,
		selectedMarketKey: FuturesMarketKey.ETHPERP,
		leverageSide: PositionSide.LONG,
		buttonSelect: PositionSide.LONG,
		orderType: 'market',
		tradePreview: null,
		tradeInputs: ZERO_STATE_TRADE_INPUTS,
		tradeFee: '0',
		leverageInput: '0',
	},
	closePositionOrderFee: '0',
	isApproved: undefined,
	allowance: undefined,
};

const futuresSlice = createSlice({
	name: 'futures',
	initialState: FUTURES_INITIAL_STATE,
	reducers: {
		setMarketAsset: (state, action) => {
			state.isolatedMargin.selectedMarketAsset = action.payload;
			state.isolatedMargin.selectedMarketKey =
				MarketKeyByAsset[action.payload as FuturesMarketAsset];
			state.isolatedMargin.selectedMarketAsset = action.payload;
			state.isolatedMargin.tradeInputs = ZERO_STATE_TRADE_INPUTS;
		},
		setOrderType: (state, action) => {
			state.isolatedMargin.orderType = action.payload;
		},
		setLeverageSide: (state, action) => {
			state.isolatedMargin.leverageSide = action.payload;
		},
		setButtonSelect: (state, action) => {
			state.isolatedMargin.buttonSelect = action.payload;
		},
		setFuturesMarkets: (state, action: PayloadAction<FuturesMarket<string>[]>) => {
			state.markets = action.payload;
		},
		setIsolatedMarginTradeInputs: (
			state,
			action: PayloadAction<IsolatedMarginTradeInputs<string>>
		) => {
			state.isolatedMargin.tradeInputs = action.payload;
		},
		setSelectedInputDenomination: (state, action: PayloadAction<InputCurrencyDenomination>) => {
			state.selectedInputDenomination = action.payload;
		},
		setIsolatedMarginFee: (state, action: PayloadAction<string>) => {
			state.isolatedMargin.tradeFee = action.payload;
		},
		setIsolatedMarginLeverageInput: (state, action: PayloadAction<string>) => {
			state.isolatedMargin.leverageInput = action.payload;
		},
		setPreviewError: (state, action: PayloadAction<string | null>) => {
			state.errors.tradePreview = action.payload;
		},
		handleIsolatedMarginPreviewError: (futuresState, action: PayloadAction<string>) => {
			const message = Object.values(ORDER_PREVIEW_ERRORS).includes(action.payload)
				? action.payload
				: 'Failed to get trade preview';
			futuresState.queryStatuses.isolatedTradePreview = {
				status: FetchStatus.Error,
				error: message,
			};
			futuresState.isolatedMargin.tradePreview = null;
		},
		setTransactionEstimate: (state, action: PayloadAction<TransactionEstimationPayload>) => {
			state.transactionEstimations[action.payload.type] = {
				limit: action.payload.limit,
				cost: action.payload.cost,
				error: action.payload.error,
			};
		},
		setIsolatedTradePreview: (
			state,
			action: PayloadAction<FuturesPotentialTradeDetails<string> | null>
		) => {
			state.isolatedMargin.tradePreview = action.payload;
		},
		setAllowance: (state, action) => {
			state.allowance = action.payload;
		},
	},
	extraReducers: (builder) => {
		// TODO: Separate markets by network
		// Markets
		builder.addCase(fetchMarkets.pending, (futuresState) => {
			futuresState.queryStatuses.markets = LOADING_STATUS;
		});
		builder.addCase(fetchMarkets.fulfilled, (futuresState, action) => {
			futuresState.queryStatuses.markets = SUCCESS_STATUS;
			if (action.payload?.markets) {
				futuresState.markets = action.payload.markets;
			}
		});
		builder.addCase(fetchMarkets.rejected, (futuresState) => {
			futuresState.queryStatuses.markets = {
				status: FetchStatus.Error,
				error: 'Failed to fetch markets',
			};
		});

		// Isolated margin positions
		builder.addCase(fetchIsolatedMarginPositions.pending, (futuresState) => {
			futuresState.queryStatuses.isolatedPositions = LOADING_STATUS;
		});
		builder.addCase(fetchIsolatedMarginPositions.fulfilled, (futuresState, { payload }) => {
			futuresState.queryStatuses.isolatedPositions = SUCCESS_STATUS;
			if (payload && futuresState.isolatedMargin.accounts[payload.network]) {
				futuresState.isolatedMargin.accounts[payload.network][payload.wallet] = {
					...futuresState.isolatedMargin.accounts[payload.network][payload.wallet],
					positions: payload.positions,
				};
			}
		});
		builder.addCase(fetchIsolatedMarginPositions.rejected, (futuresState) => {
			futuresState.queryStatuses.isolatedPositions = {
				status: FetchStatus.Error,
				error: 'Failed to fetch positions',
			};
		});

		// Refetch selected position
		builder.addCase(refetchPosition.fulfilled, (futuresState, { payload }) => {
			if (payload && futuresState.isolatedMargin.accounts[payload.networkId]) {
				const existingPositions =
					futuresState.isolatedMargin.accounts[payload.networkId][payload.wallet]?.positions || [];
				const index = existingPositions.findIndex(
					(p) => p.marketKey === payload!.position.marketKey
				);
				existingPositions[index] = payload.position;
				futuresState.isolatedMargin.accounts[payload.networkId][payload.wallet] = {
					...futuresState.isolatedMargin.accounts[payload.networkId][payload.wallet],
					positions: existingPositions,
				};
			}
		});

		// Fetch trades for market
		builder.addCase(fetchTradesForSelectedMarket.pending, (futuresState) => {
			futuresState.queryStatuses.trades = LOADING_STATUS;
		});
		builder.addCase(fetchTradesForSelectedMarket.fulfilled, (futuresState, { payload }) => {
			futuresState.queryStatuses.trades = SUCCESS_STATUS;
			if (!payload) return;
			if (futuresState.isolatedMargin.accounts[payload.networkId]) {
				futuresState.isolatedMargin.accounts[payload.networkId][payload.account] = {
					...futuresState.isolatedMargin.accounts[payload.networkId][payload.account],
					trades: payload.trades,
				};
			}
		});
		builder.addCase(fetchTradesForSelectedMarket.rejected, (futuresState) => {
			futuresState.queryStatuses.trades = {
				error: 'Failed to fetch trades',
				status: FetchStatus.Error,
			};
		});

		// Fetch duality for market
		builder.addCase(fetchDualityForSelectedMarket.pending, (futuresState) => {
			futuresState.queryStatuses.duality = LOADING_STATUS;
		});
		builder.addCase(fetchDualityForSelectedMarket.fulfilled, (futuresState, { payload }) => {
			futuresState.queryStatuses.duality = SUCCESS_STATUS;
			if (!payload) return;
			if (futuresState.isolatedMargin.accounts[payload.networkId]) {
				futuresState.isolatedMargin.accounts[payload.networkId][payload.account] = {
					...futuresState.isolatedMargin.accounts[payload.networkId][payload.account],
					duality: payload.trades,
				};
			}
		});
		builder.addCase(fetchDualityForSelectedMarket.rejected, (futuresState) => {
			futuresState.queryStatuses.duality = {
				error: 'Failed to fetch hedges',
				status: FetchStatus.Error,
			};
		});


		// TODO: Combine all with market trades rather than overwrite as the filter is done on selector

		// Fetch all trades for account
		builder.addCase(fetchAllTradesForAccount.pending, (futuresState) => {
			futuresState.queryStatuses.trades = LOADING_STATUS;
		});
		builder.addCase(fetchAllTradesForAccount.fulfilled, (futuresState, { payload }) => {
			futuresState.queryStatuses.trades = SUCCESS_STATUS;
			if (!payload) return;
			if (futuresState.isolatedMargin.accounts[payload.networkId]) {
				futuresState.isolatedMargin.accounts[payload.networkId][payload.account] = {
					...futuresState.isolatedMargin.accounts[payload.networkId][payload.account],
					trades: payload.trades,
				};
			}
		});
		builder.addCase(fetchAllTradesForAccount.rejected, (futuresState) => {
			futuresState.queryStatuses.trades = {
				error: 'Failed to fetch trades',
				status: FetchStatus.Error,
			};
		});

		builder.addCase(submitApprove.pending, (state) => {
			state.queryStatuses.approvalStatus = LOADING_STATUS;
		});
		builder.addCase(submitApprove.fulfilled, (state) => {
			state.queryStatuses.approvalStatus = SUCCESS_STATUS;
		});
		builder.addCase(submitApprove.rejected, (state, action) => {
			state.queryStatuses.approvalStatus = {
				error: 'Failed to fetch allowance',
				status: FetchStatus.Error,
			};
		});
	},
});

export default futuresSlice.reducer;

export const {
	handleIsolatedMarginPreviewError,
	setMarketAsset,
	setOrderType,
	setLeverageSide,
	setButtonSelect,
	setFuturesMarkets,
	setTransactionEstimate,
	setIsolatedMarginLeverageInput,
	setIsolatedMarginTradeInputs,
	setIsolatedTradePreview,
	setIsolatedMarginFee,
	setPreviewError,
	setSelectedInputDenomination,
} = futuresSlice.actions;
