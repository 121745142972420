import { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';
import { clone, omit } from 'lodash';
import InfluxSDK from 'sdk';

import { getEthGasPrice } from 'sdk/common/gas';

import * as sdkErrors from '../common/errors';
import { ContractName } from '../contracts';

const DEFAULT_GAS_BUFFER = 0.2;

export default class TransactionsService {
	private sdk: InfluxSDK;

	constructor(sdk: InfluxSDK) {
		this.sdk = sdk;
	}

	public createContractTxn(
		contract: ethers.Contract,
		method: string,
		args: any[],
		txnOptions: Partial<ethers.providers.TransactionRequest> = {},
		options?: { gasLimitBuffer?: number }
	) {
		const txn = {
			to: contract.address,
			data: contract.interface.encodeFunctionData(method, args),
			value: ethers.BigNumber.from(0),
			...txnOptions,
		};

		return this.createEVMTxn(txn, options);
	}

	public async createEVMTxn(txn: ethers.providers.TransactionRequest, options?: any) {
		const execTxn = clone(txn);

		if (!execTxn.gasLimit) {
			const newGasLimit = await this.estimateGas(execTxn);
			execTxn.gasLimit = wei(newGasLimit ?? 0, 9)
				.mul(1 + (options?.gasLimitBuffer || DEFAULT_GAS_BUFFER))
				.toBN();
		}

		const txnData = await this.sdk.context.signer.sendTransaction(execTxn);

		return txnData;
	}

	public createInfluxTxn(
		contractName: ContractName,
		method: string,
		args: any[],
		txnOptions: Partial<ethers.providers.TransactionRequest> = {},
		options?: any
	) {
		const contract = this.sdk.context.contracts[contractName];

		if (!contract) {
			throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
		}

		return this.createContractTxn(contract, method, args, txnOptions, options);
	}

	public async estimateGas(txn: ethers.providers.TransactionRequest) {
		return this.sdk.context.signer.estimateGas(
			omit(txn, ['gasPrice', 'maxPriorityFeePerGas', 'maxFeePerGas'])
		);
	}

	public getGasPrice() {
		return getEthGasPrice(this.sdk.context.networkId, this.sdk.context.provider);
	}
}
