import { wei } from '@synthetixio/wei';

import {
	CurrencyKey
} from 'constants/currency';
import { FuturesMarketKey } from 'sdk/types/futures';
import { Price, Prices } from 'sdk/types/prices';

export const getPricesForCurrencies = (
	rates: Prices | null,
	base: CurrencyKey | FuturesMarketKey | string,
	quote: CurrencyKey | FuturesMarketKey | null
): Price => {
	if (!rates || !base || !quote || !rates[base] || !rates[quote]) {
		return { offChain: wei(0), onChain: wei(0) };
	}

	const hasOnChain = !!rates[base]?.onChain && !!rates[quote]?.onChain;
	const hasOffChain = !!rates[base]?.offChain && !!rates[quote]?.offChain;

	return {
		onChain: hasOnChain ? rates[base].onChain!.div(rates[quote].onChain!) : wei(0),
		offChain: hasOffChain ? rates[base].offChain!.div(rates[quote].offChain!) : wei(0),
	};
};
