export default function logError(err: Error, skipReport = false): void {
	// eslint-disable-next-line no-console
	console.error(err);
}

export const IGNORE_ERRORS = [
	'Insufficient margin',
	'Network request failed',
	'Network error',
	'timeout exceeded',
	'Unsupported network',
	'request aborted',
];
