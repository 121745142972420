import Wei from '@synthetixio/wei';

import { TransactionStatus } from 'sdk/types/common';
import {
	FuturesButtonType,
	FuturesMarket,
	FuturesMarketAsset,
	FuturesMarketKey,
	FuturesPosition,
	FuturesPositionHistory,
	FuturesPotentialTradeDetails,
	FuturesTrade,
	IsolatedMarginOrderType,
	MarginTransfer,
	PositionSide
} from 'sdk/types/futures';
import { PricesInfo } from 'state/prices/types';
import { QueryStatus } from 'state/types';

export type TradeSizeInputs<T = Wei> = {
	susdSize: T;
	nativeSize: T;
	size: T;
	usd: boolean;
};

export type IsolatedMarginTradeInputs<T = Wei> = TradeSizeInputs<T>;

export type MarkPrices<T = Wei> = Partial<Record<FuturesMarketKey, T>>;

export type MarkPriceInfos<T = Wei> = Partial<Record<FuturesMarketKey, PricesInfo<T>>>;

export type FundingRate<T = Wei> = {
	asset: FuturesMarketKey;
	fundingTitle: string;
	fundingRate: T | null;
};

export type FuturesQueryStatuses = {
	markets: QueryStatus;
	isolatedPositions: QueryStatus;
	isolatedTradePreview: QueryStatus;
	trades: QueryStatus;
	duality: QueryStatus;
	approvalStatus: QueryStatus;
};

export type FuturesTransactionType =
	| 'deposit_isolated'
	| 'withdraw_isolated'
	| 'modify_isolated'
	| 'approve'
	| 'duality';

export type FuturesTransaction = {
	type: FuturesTransactionType;
	status: TransactionStatus;
	error?: string;
	hash: string | null;
};

export type TransactionEstimation<T = Wei> = {
	error?: string | null;
	limit: T;
	cost: T;
};

export type TransactionEstimations = Record<FuturesTransactionType, TransactionEstimation<string>>;

export type TransactionEstimationPayload = {
	type: FuturesTransactionType;
	limit: string;
	cost: string;
	error?: string | null;
};

type FuturesErrors = {
	tradePreview?: string | undefined | null;
};

type FuturesNetwork = number;

export type InputCurrencyDenomination = 'usd' | 'native';

export type FuturesAccountData = {
	position?: FuturesPosition<string>;
	positions?: FuturesPosition<string>[];
	positionHistory?: FuturesPositionHistory<string>[];
	trades?: FuturesTrade<string>[];
	duality?: FuturesTrade<string>[];
	marginTransfers?: MarginTransfer[];
};

export type IsolatedAccountData = FuturesAccountData & {
	openOrders?: DelayedOrderWithDetails<string>[];
};

// TODO: Separate in some way by network and wallet
// so we can have persisted state between switching

export type FuturesState = {
	confirmationModalOpen: boolean;
	isolatedMargin: IsolatedMarginState;
	markets: FuturesMarket<string>[];
	queryStatuses: FuturesQueryStatuses;
	transactionEstimations: TransactionEstimations;
	errors: FuturesErrors;
	selectedInputDenomination: InputCurrencyDenomination;
	closePositionOrderFee: string;
	isApproved?: boolean;
	allowance?: string;
};

export type TradePreviewResult = {
	data: FuturesPotentialTradeDetails<string> | null;
	error: string | null;
};

export type IsolatedMarginState = {
	tradeInputs: IsolatedMarginTradeInputs<string>;
	orderType: IsolatedMarginOrderType;
	tradePreview: FuturesPotentialTradeDetails<string> | null;
	leverageSide: PositionSide;
	buttonSelect: FuturesButtonType;
	selectedMarketKey: FuturesMarketKey;
	selectedMarketAsset: FuturesMarketAsset;
	leverageInput: string;
	tradeFee: string;
	accounts: Record<
		FuturesNetwork,
		{
			[wallet: string]: IsolatedAccountData;
		}
	>;
};

export type ModifyIsolatedPositionInputs = {
	sizeDelta: Wei;
	delayed: boolean;
	offchain: boolean;
};

export type CancelDelayedOrderInputs = {
	marketAddress: string;
	isOffchain: boolean;
};

export type ExecuteDelayedOrderInputs = {
	marketKey: FuturesMarketKey;
	marketAddress: string;
	isOffchain: boolean;
};

export type DelayedOrderWithDetails<T = Wei> = {
	account: string;
	market: string;
	asset: FuturesMarketAsset;
	marketKey: FuturesMarketKey;
	size: T;
	margin: T;
	lastMargin: T;
};

export const futuresPositionKeys = new Set([
	'margin',
	'lastMargin',
	'size',
	'position.notionalValue',
	'position.accruedFunding',
	'position.leverage',
	'position.remainingMargin',
	'position.actualMargin',
	'position.liquidationPrice',
]);

export const futuresPositionHistoryKeys = new Set([
	'size',
	'feesPaid',
	'netFunding',
	'netTransfers',
	'totalDeposits',
	'initialMargin',
	'margin',
	'entryPrice',
	'avgEntryPrice',
	'exitPrice',
	'leverage',
	'pnl',
	'pnlWithFeesPaid',
	'totalVolume',
]);
