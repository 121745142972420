import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'state/store';
import { FetchStatus } from 'state/types';
import { unserializeBalances } from 'utils/balances';
import { toWei } from 'utils/formatters/number';

export const selectBalancesFetchStatus = (state: RootState) => state.balances.status;

export const selectTotalUSDBalanceWei = createSelector(
	(state: RootState) => state.balances.totalUSDBalance,
	(totalUSDBalance) => toWei(totalUSDBalance)
);

export const selectSynthBalancesLoading = createSelector(
	(state: RootState) => state.balances.status,
	(status) => status === FetchStatus.Loading
);

export const selectSusdBalance = createSelector(
	(state: RootState) => state.balances.susdWalletBalance,
	(susdWalletBalance) => toWei(susdWalletBalance)
);

export const selectBalances = createSelector(
	(state: RootState) => state.balances,
	(balances) => {
		return unserializeBalances(
			balances.synthBalancesMap,
			balances.totalUSDBalance || '0',
			balances.tokenBalances,
			balances.allowances,
			balances.susdWalletBalance || '0',
			balances.testnetNFTBalance || '0',
			balances.inviteCodes || []
		);
	}
);

export const selectHasTestnetNFT = createSelector(
	(state: RootState) => state.balances,
	(balances) => {
		return (balances.testnetNFTBalance ?? '0') !== '0';
	}	
);

export const selectInviteCodes = createSelector(
	(state: RootState) => state.balances,
	(balances) => {
		return (balances.inviteCodes || []);
	}	
);

export const selectOpenModal = createSelector(
	(state: RootState) => state.balances,
	(balances) => {
		return balances.modal;
	}
);