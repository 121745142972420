import { useRouter } from 'next/router';
import { useCallback } from 'react';
import styled from 'styled-components';

import BaseModal from 'components/BaseModal';
import { Body } from 'components/Text';
import ROUTES from 'constants/routes';
import Connector from 'containers/Connector';
import { InviteCodeModal } from 'sections/shared/modals/InviteCodeModal';
import { InviteModal } from 'sections/shared/modals/InviteModal';
import { selectHasTestnetNFT, selectOpenModal } from 'state/balances/selectors';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { submitRedeem } from 'state/token/actions';

export default function AcknowledgementModal() {
	const { walletAddress } = Connector.useContainer();
	const router = useRouter();
	const dispatch = useAppDispatch();
	const onRedeem = useCallback(() => {
        dispatch(submitRedeem());
    }, [dispatch]);

	const hasInvite = useAppSelector(selectHasTestnetNFT);

	const store = useAppSelector(selectOpenModal);
	const openInvite = store;

	const protectedRoute =
		router.asPath.startsWith(ROUTES.Faucet.Home) ||
		router.asPath.startsWith(ROUTES.Exchange.Home) ||
		router.asPath.includes('/market');

	if (
		!protectedRoute
	) {
		return null;
	}

	return (
		<>{<InviteModal attemptRetry={onRedeem}/>
		}</>
	);
}

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		max-width: 400px;
	}
`;

const BodyText = styled(Body)`
	color: ${(props) => props.theme.colors.common.tertiaryGray};
`;
