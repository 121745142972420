import { createSlice } from '@reduxjs/toolkit';
import { TokenState } from './types';

import { submitRequestTokens, submitRedeem, getCooldown } from './actions';

import { COOLDOWN_PERIOD } from 'sdk/constants/futures';

export const TOKEN_INITIAL_STATE: TokenState = {
	currencyKey: "USDB",
	cooldown: undefined,
	isSubmitting: false,
	txHash: undefined,
	openModal: undefined,
	txError: undefined,
	code: undefined,
	validCode: false,
};

const tokenSlice = createSlice({
	name: 'token',
	initialState: TOKEN_INITIAL_STATE,
	reducers: {
		setCurrencyKey: (state, action) => {
			state.currencyKey = action.payload;
			state.cooldown = undefined;
		},
		setOpenModal: (state, action) => {
			state.openModal = action.payload;
		},
		closeModal: (state) => {
			state.openModal = undefined;
		},
		setCooldown: (state, action) => {
			state.cooldown = action.payload;
		},
		setCode: (state, action) => {
			state.code = action.payload;
		},
		setValid: (state, action) => {
			state.validCode = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(submitRequestTokens.pending, (state) => {
			state.isSubmitting = true;
			state.openModal = 'confirm';
			state.txError = undefined;
		});
		builder.addCase(submitRequestTokens.fulfilled, (state) => {
			state.isSubmitting = false;
			state.openModal = undefined;
		});
		builder.addCase(submitRequestTokens.rejected, (state, action) => {
			state.isSubmitting = false;
			state.openModal = undefined;
			state.txError = action.error.message;
		});
		builder.addCase(submitRedeem.pending, (state) => {
			state.isSubmitting = true;
			state.txError = undefined;
		});
		builder.addCase(submitRedeem.fulfilled, (state) => {
			state.isSubmitting = false;
		});
		builder.addCase(submitRedeem.rejected, (state, action) => {
			state.isSubmitting = false;
			state.txError = action.error.message;
		});
		builder.addCase(getCooldown.fulfilled, (state, action) => {
			state.cooldown = action.payload;
		});
	},
});

export const { setCurrencyKey, setOpenModal, closeModal, setCode, setValid } = tokenSlice.actions;

export default tokenSlice.reducer;
