import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ThunkConfig } from 'state/store';
import { serializeBalances } from 'utils/balances';

import { ZERO_BALANCES } from './reducer';
import { BalancesActionReturn } from './types';

export const fetchBalances = createAsyncThunk<BalancesActionReturn<string>, void, ThunkConfig>(
	'balances/fetchBalances',
	async (_, { getState, extra: { sdk } }) => {
		const { wallet } = getState();
		if (!wallet.walletAddress) return ZERO_BALANCES;
		
		const {tokenBalances, allowances} = await sdk.exchange.getTokenBalances(wallet.walletAddress);
		const testnetNFTBalance = await sdk.invite.testnetNFTBalance();
		const inviteCodes = await sdk.invite.getCodes(wallet.walletAddress);

		const usdWalletBalance = tokenBalances['USDB']?.balance ?? '';
		const totalUSDBalance = usdWalletBalance;

		return serializeBalances({}, totalUSDBalance, tokenBalances, allowances, usdWalletBalance, testnetNFTBalance, inviteCodes);
	}
);