export const PROD_HOSTNAME = 'influx.exchange';

export const EXTERNAL_LINKS = {
	ContactUs: {
		Support: 'mailto:contact@influx.exchange',
	},
	Social: {
		Twitter: 'https://twitter.com/influx_ex',
		Mirror: 'https://mirror.xyz/',
		Discord: 'https://discord.gg/influx-ex',
		GitHub: 'https://github.com/',
		Medium: 'https://medium.com/@influx_ex',
		Telegram: 'https://t.me/influx_ex',
		Galxe: 'https://galxe.com/Dn3xPfpP2mZAEHJRV4asKd',
	},
	Docs: {
		DocsRoot: 'https://docs.influx.exchange/',
	},
	Governance: {
		Vote: 'https://snapshot.org/',
	},
};
