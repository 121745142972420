import { createSlice } from '@reduxjs/toolkit';

import { FetchStatus } from 'state/types';

import {
	fetchTokenList,
	fetchTransactionFee,
	queryAmount,
	resetCurrencyKeys,
	submitApprove,
	submitExchange,
} from './actions';
import { ExchangeState } from './types';

export const EXCHANGES_INITIAL_STATE: ExchangeState = {
	baseCurrencyKey: undefined,
	quoteCurrencyKey: 'USDB',
	txProvider: undefined,
	baseAmount: '',
	quoteAmount: '',
	exact: true,
	ratio: undefined,
	quotePriceRate: undefined,
	basePriceRate: undefined,
	slippagePercent: undefined,
	isSubmitting: false,
	approvalStatus: FetchStatus.Idle,
	tokenListStatus: FetchStatus.Idle,
	synthsMap: {},
	tokensMap: {},
	tokenList: [],
	txHash: undefined,
	openModal: undefined,
	txError: undefined,
	isApproved: undefined,
	allowance: undefined,
};

const exchangeSlice = createSlice({
	name: 'exchange',
	initialState: EXCHANGES_INITIAL_STATE,
	reducers: {
		setQuoteAmount: (state, action) => {
			state.quoteAmount = action.payload;
			state.exact = true;
		},
		setBaseAmount: (state, action) => {
			state.baseAmount = action.payload;
			state.exact = false;
		},
		setQuoteAmountRaw: (state, action) => {
			state.quoteAmount = action.payload;
		},
		setBaseAmountRaw: (state, action) => {
			state.baseAmount = action.payload;
		},
		setQuoteCurrencyKey: (state, action) => {
			if (state.baseCurrencyKey === action.payload) {
				// Swap
				state.baseCurrencyKey = state.quoteCurrencyKey;
				[state.quoteAmount, state.baseAmount] = [state.baseAmount, state.quoteAmount];
				state.exact = !state.exact;
			} else {
				if (state.exact) {
					state.baseAmount = '';
				} else {
					state.quoteAmount = '';
				}
			}
			state.quoteCurrencyKey = action.payload;
		},
		setBaseCurrencyKey: (state, action) => {
			if (state.quoteCurrencyKey === action.payload) {
				// Swap
				state.quoteCurrencyKey = state.baseCurrencyKey;
				[state.quoteAmount, state.baseAmount] = [state.baseAmount, state.quoteAmount];
				state.exact = !state.exact;
			} else {
				if (state.exact) {
					state.baseAmount = '';
				} else {
					state.quoteAmount = '';
				}
			}
			state.baseCurrencyKey = action.payload;
		},
		swapCurrencies: (state) => {
			[state.quoteCurrencyKey, state.baseCurrencyKey] = [
				state.baseCurrencyKey,
				state.quoteCurrencyKey,
			];
			[state.quoteAmount, state.baseAmount] = [state.baseAmount, state.quoteAmount];
			state.exact = !state.exact;
		},
		setOpenModal: (state, action) => {
			state.openModal = action.payload;
		},
		closeModal: (state) => {
			state.openModal = undefined;
		},
		setApprovalStatus: (state, action) => {
			state.approvalStatus = action.payload;
		},
		setAllowance: (state, action) => {
			state.allowance = action.payload;
		},
		setSlippagePercent: (state, action) => {
			state.slippagePercent = action.payload;
		},
		setExact: (state, action) => {
			state.exact = action.payload;
		},
		setRatio: (state, action) => {
			state.ratio = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTransactionFee.fulfilled, (state, action) => {
			state.transactionFee = action.payload.transactionFee;
			state.feeCost = action.payload.feeCost;
		});
		builder.addCase(submitExchange.pending, (state) => {
			state.isSubmitting = true;
			state.openModal = 'confirm';
			state.txError = undefined;
		});
		builder.addCase(submitExchange.fulfilled, (state) => {
			state.isSubmitting = false;
			state.openModal = undefined;
		});
		builder.addCase(submitExchange.rejected, (state, action) => {
			state.isSubmitting = false;
			state.openModal = undefined;
			state.txError = action.error.message;
		});
		builder.addCase(submitApprove.pending, (state) => {
			state.approvalStatus = FetchStatus.Loading;
			state.openModal = 'approve';
			state.txError = undefined;
		});
		builder.addCase(submitApprove.fulfilled, (state) => {
			state.openModal = undefined;
		});
		builder.addCase(submitApprove.rejected, (state, action) => {
			state.openModal = undefined;
			state.approvalStatus = FetchStatus.Error;
			state.txError = action.error.message;
		});
		builder.addCase(fetchTokenList.pending, (state) => {
			state.tokenListStatus = FetchStatus.Loading;
		});
		builder.addCase(fetchTokenList.fulfilled, (state, action) => {
			state.tokenListStatus = FetchStatus.Success;
			state.synthsMap = action.payload.synthsMap;
			state.tokensMap = action.payload.tokensMap;
			state.tokenList = action.payload.tokenList;
		});
		builder.addCase(fetchTokenList.rejected, (state) => {
			state.tokenListStatus = FetchStatus.Error;
		});
		builder.addCase(queryAmount.pending, (state) => {});
		builder.addCase(queryAmount.fulfilled, (state, action) => {
			if (!action.payload.amount) {
				return;
			}
			if (action.payload.side) {
				state.quoteAmount = action.payload.amount;
			} else {
				state.baseAmount = action.payload.amount;
			}
		});
		builder.addCase(queryAmount.rejected, (state) => {});
		builder.addCase(resetCurrencyKeys.fulfilled, (state, action) => {
			state.quotePriceRate = action.payload.quotePriceRate;
			state.basePriceRate = action.payload.basePriceRate;
			state.txProvider = action.payload.txProvider;
			state.allowance = action.payload.allowance;
		});
	},
});

export const {
	setQuoteAmount,
	setBaseAmount,
	swapCurrencies,
	setQuoteCurrencyKey,
	setBaseCurrencyKey,
	setOpenModal,
	closeModal,
	setApprovalStatus,
	setAllowance,
	setExact,
	setSlippagePercent,
	setRatio,
} = exchangeSlice.actions;

export default exchangeSlice.reducer;
