import { DEFAULT_NETWORK_ID } from 'constants/defaults';

import { NetworkId, NetworkNameById, NetworkIdByName } from 'sdk/types/common';

export const getBaseUrl = (networkId: NetworkId) => {
	if (networkId === NetworkIdByName.mainnet) {
		return 'https://etherscan.io';
	}
	if (networkId === 168_587_773) {
		return 'https://testnet.blastscan.io';
	}
	return `https://${NetworkNameById[DEFAULT_NETWORK_ID]}.etherscan.io`;
};

export const generateExplorerFunctions = (baseUrl: string) => ({
	baseLink: baseUrl,
	txLink: (txId: string) => `${baseUrl}/tx/${txId}`,
	addressLink: (address: string) => `${baseUrl}/address/${address}`,
	tokenLink: (address: string) => `${baseUrl}/token/${address}`,
	blockLink: (blockNumber: string) => `${baseUrl}/block/${blockNumber}`,
});
