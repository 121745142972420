import { FuturesMarketAsset } from 'sdk/types/futures';

export const formatUrl = (route: string, params: Record<string, string>) => {
	return route + '?' + new URLSearchParams(params);
};

export const ROUTES = {
	Home: {
		Root: '/',
	},
	Exchange: {
		Home: '/swap',
		MarketPair: (baseCurrencyKey: string, quoteCurrencyKey: string) =>
			`/swap/?quote=${quoteCurrencyKey}&base=${baseCurrencyKey}`,
		Into: (currencyKey: string) => `/swap/?quote=${currencyKey}`,
	},
	Markets: {
		Home: formatUrl('/market', { asset: 'ETH' }),
		MarketPair: (asset: FuturesMarketAsset | string) => formatUrl('/market', { asset }),
		Position: (asset: FuturesMarketAsset) =>
			formatUrl('/market', {
				asset,
				tab: 'position',
			}),
		Trades: (asset: FuturesMarketAsset) => formatUrl('/market', { asset, tab: 'trades' }),
		Hedges: (asset: FuturesMarketAsset) => formatUrl('/market', { asset, tab: 'hedges'}),
	},
	Faucet: {
		Home: '/faucet',
	},
	Pools: {
		Home: '/',
	},
	Cede: {
		Home: '/cede',
	}
};

export default ROUTES;
