import { APP_INITIAL_STATE } from './app/reducer';
import { BALANCES_INITIAL_STATE } from './balances/reducer';
import { EXCHANGES_INITIAL_STATE } from './exchange/reducer';
import { FUTURES_INITIAL_STATE } from './futures/reducer';
import { HOME_INITIAL_STATE } from './home/reducer';
import { PREFERENCES_INITIAL_STATE } from './preferences/reducer';
import { PRICES_INITIAL_STATE } from './prices/reducer';
import { WALLET_INITIAL_STATE } from './wallet/reducer';

export const migrations = {
	4: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
			prices: PRICES_INITIAL_STATE,
		};
	},
	5: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		};
	},
	6: (state: any) => {
		return {
			...state,
			prices: PRICES_INITIAL_STATE,
		};
	},
	7: (state: any) => {
		return {
			...state,
			app: APP_INITIAL_STATE,
			balances: BALANCES_INITIAL_STATE,
			exchanges: EXCHANGES_INITIAL_STATE,
			futures: FUTURES_INITIAL_STATE,
			home: HOME_INITIAL_STATE,
			preferences: PREFERENCES_INITIAL_STATE,
			prices: PRICES_INITIAL_STATE,
			wallet: WALLET_INITIAL_STATE,
		};
	},
	8: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		};
	},
	9: (state: any) => {
		return {
			...state,
			home: HOME_INITIAL_STATE,
		};
	},
	10: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		}
	},
	11: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		}
	},
	12: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		}
	},
	13: (state: any) => {
		return {
			...state,
			futures: FUTURES_INITIAL_STATE,
		}
	}
};

export default migrations;
