// @ts-ignore TODO: remove once types are added
import { wei } from '@synthetixio/wei';
import axios from 'axios';
import { Contract as EthCallContract } from 'ethcall';
import { BigNumber } from 'ethers';
import { get } from 'lodash';
import InfluxSDK from 'sdk';

import {
	CRYPTO_CURRENCY_ETH,
	ETH_ADDRESS,
	ETH_COINGECKO_ADDRESS
} from 'constants/currency';
import { CG_BASE_API_URL } from 'queries/coingecko/constants';
import { PriceResponse } from 'queries/coingecko/types';
import erc20Abi from 'sdk/contracts/abis/ERC20.json';
import { TokenAllowances, TokenBalances, TokenCall, TokenQuery } from 'sdk/types/tokens';

import { allMarkets } from 'sdk/data/market';
import { getMarketsForNetwork, MarketSymbol } from '../data/market';
import { ADDRESSES } from 'sdk/contracts/constants';

export default class ExchangeService {
	private tokensMap: any = {};
	private sdk: InfluxSDK;

	constructor(sdk: InfluxSDK) {
		this.sdk = sdk;
	}

	get exchangeRates() {
		return this.sdk.prices.currentPrices.onChain;
	}

	
	public getTxProvider(baseCurrencyKey: string, quoteCurrencyKey: string) {
		if (!baseCurrencyKey || !quoteCurrencyKey) return undefined;
		return '1inch';
	}
	
	public getCurrencyName(currencyKey: string): string | undefined {
		return allMarkets?.[currencyKey as MarketSymbol]?.name;
	}

	
	public getPriceRate(currencyKey: string, coinGeckoPrices: PriceResponse) {
		const tokenAddress = this.getTokenAddress(currencyKey, true).toLowerCase();
		const price = coinGeckoPrices && coinGeckoPrices[tokenAddress];

		if (price) {
			return wei(price.usd ?? 0);
		} else {
			return wei(0);
		}
	}
	
	public validCurrencyKeys(quoteCurrencyKey?: string, baseCurrencyKey?: string) {
		return [quoteCurrencyKey, baseCurrencyKey].map((currencyKey) => {
			return (
				!!currencyKey &&
				(!!this.synthsMap[currencyKey as MarketSymbol] || !!this.tokensMap[currencyKey])
			);
		});
	}
	
	public async getCoingeckoPrices(
		quoteCurrencyKey: string | undefined,
		baseCurrencyKey: string | undefined
	) {
		const tokenAddresses = [];
		if (quoteCurrencyKey) {
			const quoteCurrencyTokenAddress = this.getTokenAddress(quoteCurrencyKey, true).toLowerCase();
			tokenAddresses.push(quoteCurrencyTokenAddress);
		}
		if (baseCurrencyKey) {
			const baseCurrencyTokenAddress = this.getTokenAddress(baseCurrencyKey, true).toLowerCase();
			tokenAddresses.push(baseCurrencyTokenAddress);
		}

		return this.batchGetCoingeckoPrices(tokenAddresses);
	}
	
	public async batchGetCoingeckoPrices(tokenAddresses: string[], include24hrChange = false) {
		const platform = 'ethereum';
		const response = await axios.get<PriceResponse>(
			`${CG_BASE_API_URL}/simple/token_price/${platform}?contract_addresses=${tokenAddresses
				.join(',')
				.replace(ETH_ADDRESS, ETH_COINGECKO_ADDRESS)}&vs_currencies=usd${
				include24hrChange ? '&include_24hr_change=true' : ''
			}`
		);
		return response.data;
	}

	public getSynthsMap() {
		return this.synthsMap;
	}

	public get synthsMap() {
		return getMarketsForNetwork(this.sdk.context.networkId);
	}

	private isCurrencyETH(currencyKey: string) {
		return currencyKey === CRYPTO_CURRENCY_ETH;
	}

	private getTokenAddress(currencyKey: string, coingecko?: boolean) {
		if (currencyKey != null) {
			if (this.isCurrencyETH(currencyKey)) {
				return coingecko ? ETH_COINGECKO_ADDRESS : ETH_ADDRESS;
			} else {
				return get(allMarkets, [currencyKey, 'addresses', 1], null);
			}
		} else {
			return null;
		}
	}

	public async getTokenBalances(walletAddress: string): Promise<TokenQuery> {
		const symbols = Object.keys(allMarkets);
		const calls = [];
		const queriedSymbols: TokenCall[] = [];
		const FuturesExchange = ADDRESSES.TestnetExchange[this.sdk.context.networkId];

		for (const symbol in allMarkets) {
			const s = allMarkets[symbol as MarketSymbol];
			const address = s['addresses'][this.sdk.context.networkId];

			if (!address) {
				continue;
			}

			if (address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
				calls.push(this.sdk.context.multicallProvider.getEthBalance(walletAddress));
				queriedSymbols.push({ symbol: symbol as MarketSymbol, type: 'balance'});
			} else {
				const tokenContract = new EthCallContract(address, erc20Abi);
				calls.push(tokenContract.balanceOf(walletAddress));
				queriedSymbols.push({ symbol: symbol as MarketSymbol, type: 'balance'});
				if (FuturesExchange) {
					calls.push(tokenContract.allowance(walletAddress, FuturesExchange));
					queriedSymbols.push({ symbol: symbol as MarketSymbol, type: 'allowance'});
				}
			}
		}

		const data = (await this.sdk.context.multicallProvider.all(calls)) as BigNumber[];

		const tokenBalances: TokenBalances = {};
		const allowances: TokenAllowances = {};

		data.forEach((value, index) => {
			if (value.lte(0)) return;
			const {symbol, type} = queriedSymbols[index];
			const token = allMarkets[symbol];

			const address = token['addresses'][this.sdk.context.networkId] as string;
			const tokenData = {
				address,
				chainId: this.sdk.context.networkId,
				decimals: token.decimals,
				logoURI: '',
				name: token.name,
				symbol,
				tags: [],
			};
			if (type === 'balance') {
				tokenBalances[symbol] = {
					balance: wei(value, token.decimals ?? 18),
					token: tokenData,
				};
			} else if (type === 'allowance') {
				allowances[symbol] = {
					allowance: wei(value, token.decimals ?? 18),
					token: tokenData,
				};
			}
		});

		return { tokenBalances, allowances};
	}
}
