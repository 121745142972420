import { NetworkId } from 'sdk/types/common';

export type MarketSymbol = 'USDB' | 'ETH' | 'BTC' | 'SOL' | 'DOGE' | 'BNB' | 'AVAX' | 'UNI' | 'ADA' | 'BLUR' | 'SEI' | 'SUI' | 'SHIB' | 'APT' | 'INJ' | 'LINK' | 'PEPE' | 'APE' | 'BONK' | 'SNX' | 'MEME' | 'FLOKI';

export type MarketToken = {
	name: MarketSymbol;
	description: string;
	asset: string;
	address: string;
	decimals: number;
	category: 'crypto';
};

export type SynthsMap = Partial<Record<MarketSymbol, MarketToken>>;

type MarketData = {
	name: string;
	asset: string;
	addresses: Partial<Record<NetworkId, string>>;
	category: 'crypto';
	decimals: number;
};

export const allMarkets: Record<MarketSymbol, MarketData> = {
	USDB: {
		name: 'USD Blast',
		asset: 'USDB',
		addresses: {
			1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
			5: '0x34372db56009EC73B622aaE933523aDBdCa296D0',
			168_587_773: '0x166889C3A110a5e13cB29cae7e809056B0877c54',
		},
		category: 'crypto',
		decimals: 6,
	},
	ETH: {
		name: 'Ether',
		asset: 'ETH',
		addresses: {
			1: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
			5: '0x108C9909F7Ec52704CF65DcBA916abD1867Db035',
			168_587_773: '0x7a60FbaB70d73AE8906D4c93e86CC2aafcCDc267',
		},
		category: 'crypto',
		decimals: 18,
	},
	BTC: {
		name: 'Bitcoin',
		asset: 'BTC',
		addresses: {
			1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
			5: '0x10CfeD8A7ecC45CE97E6C933b5E20FBce4a2538d',
			168_587_773: '0x29d6e0f972f707aD0C94d4598A44e4aaB6Ea49A3',
		},
		category: 'crypto',
		decimals: 8,
	},
	SOL: {
		name: 'Solana',
		asset: 'SOL',
		addresses: {
			168_587_773: '0x36490e789655B9c7140785a051F6C69916C89819',
		},
		category: 'crypto',
		decimals: 9,
	},
	DOGE: {
		name: 'Dogecoin',
		asset: 'DOGE',
		addresses: {
			168_587_773: '0xCc5550017A332f108aC0AE697a04fBbe396E567A',
		},
		category: 'crypto',
		decimals: 8,
	},
	BNB: {
		name: 'Binance Coin',
		asset: 'BNB',
		addresses: {
			168_587_773: '0x7d6950A15AB085ed69C8604C6F25c99Ae6d78041',
		},
		category: 'crypto',
		decimals: 18,
	},
	// ARB: {
	// 	name: 'Arbitrum One',
	// 	asset: 'ARB',
	// 	addresses: {
	// 		168_587_773: '0x81e6D58db6Ec945afE3Ac630B2873f5adefC8b01',
	// 	},
	// 	category: 'crypto',
	// 	decimals: 18,
	// },
	AVAX: {
		name: 'Avalanche',
		asset: 'AVAX',
		addresses: {
			168_587_773: '0x81e6D58db6Ec945afE3Ac630B2873f5adefC8b01',
		},
		category: 'crypto',
		decimals: 18,
	},
	UNI: {
		name: 'Uniswap',
		asset: 'UNI',
		addresses: {
			1: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
			168_587_773: '0xC6090e0dd0c168C7A0ff8E1608893f6fef9Af098',
		},
		category: 'crypto',
		decimals: 18,
	},
	ADA: {
		name: 'Cardano',
		asset: 'ADA',
		addresses: {
			168_587_773: '0xA23a1a0DB885eB168a7B8A4962d6652b51bd7006',
		},
		category: 'crypto',
		decimals: 6,
	},
	BLUR: {
		name: 'Blur',
		asset: 'BLUR',
		addresses: {
			168_587_773: '0xcC8e40c6FeB801509FF7937dB45f4cB874bC28A4',
		},
		category: 'crypto',
		decimals: 18,
	},
	SEI: {
		name: 'Sei',
		asset: 'SEI',
		addresses: {
			168_587_773: '0x1C3571B01382dFF62738E75023f6fe741b7FB11C',
		},
		category: 'crypto',
		decimals: 18,
	},
	SUI: {
		name: 'Sui',
		asset: 'SUI',
		addresses: {
			168_587_773: '0x41c8234BB34aa1e907F46f09f95eF3B1a23B4C30',
		},
		category: 'crypto',
		decimals: 9,
	},
	SHIB: {
		name: 'Shiba Inu',
		asset: 'SHIB',
		addresses: {
			168_587_773: '0x9Df7353fB0901eF9E3bC53618d3818ab53205244',
		},
		category: 'crypto',
		decimals: 18,
	},
	APT: {
		name: 'Aptos',
		asset: 'APT',
		addresses: {
			168_587_773: '0x5Fb28ab5C81C0015Cc3a30D5992e55d9963CFe08',
		},
		category: 'crypto',
		decimals: 8,
	},
	INJ: {
		name: 'Injective',
		asset: 'INJ',
		addresses: {
			168_587_773: '0xc7f54b8a12bdb3498A53600Dcf288704CaCD534f',
		},
		category: 'crypto',
		decimals: 18,
	},
	LINK: {
		name: 'Chainlink',
		asset: 'LINK',
		addresses: {
			1: '0x514910771af9ca656af840dff83e8264ecf986ca',
			5: '0x1505BC9eeBa101999343905856E6c0C21829344C',
			168_587_773: '0x18243A3BF83f88B95F647fA62b6F7dc40B7F00aB',
		},
		category: 'crypto',
		decimals: 18,
	},
	APE: {
		name: 'Apecoin',
		asset: 'APE',
		addresses: {
			168_587_773: '0x7a9bD7d0d8769fDaaDbfD319d5A3fd6C0ce69eC9',
		},
		category: 'crypto',
		decimals: 18,
	},
	PEPE: {
		name: 'Pepe',
		asset: 'PEPE',
		addresses: {
			168_587_773: '0xAA79c0832aBCfa5B36eDD83b436d6eB65ce3f9f2',
		},
		category: 'crypto',
		decimals: 18,
	},
	BONK: {
		name: 'Bonk',
		asset: 'BONK',
		addresses: {
			168_587_773: '0xa810032F2e2032a11b45a5c5246965B7DA76330b',
		},
		category: 'crypto',
		decimals: 5,
	},
	SNX: {
		name: 'Synthetix',
		asset: 'SNX',
		addresses: {
			1: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
			5: '0x63ab4adF902798059f5120E34dE74E0DC56E6AF7',
		},
		category: 'crypto',
		decimals: 18,
	},
	MEME: {
		name: 'Memecoin',
		asset: 'MEME',
		addresses: {
			168_587_773: '0x3287AbCd10052526fD7db4dC55909a3bb3cFf79D',
		},
		category: 'crypto',
		decimals: 18,
	},
	FLOKI: {
		name: 'Floki',
		asset: 'Floki',
		addresses: {
			168_587_773: '0xba34A8e7E2E5b3F74ab38Cf767767d39C40B7933',
		},
		category: 'crypto',
		decimals: 9,
	},
};

const marketsByNetwork = (id: NetworkId) =>
	Object.entries(allMarkets).reduce((acc, [symbol, config]) => {
		const address = config.addresses[id];

		if (address) {
			acc[symbol as MarketSymbol] = {
				name: symbol as MarketSymbol,
				description: config.name,
				asset: config.asset,
				address,
				decimals: 18,
				category: config.category,
			};
		}

		return acc;
	}, {} as Partial<SynthsMap>);

const SYNTHS_BY_NETWORK: Partial<Record<NetworkId, { map: SynthsMap; list: MarketToken[] }>> = {
	1: {
		map: marketsByNetwork(1),
		list: Object.values(marketsByNetwork(1)),
	},
	10: {
		map: marketsByNetwork(10),
		list: Object.values(marketsByNetwork(10)),
	},
	5: {
		map: marketsByNetwork(5),
		list: Object.values(marketsByNetwork(5)),
	},
	420: {
		map: marketsByNetwork(420),
		list: Object.values(marketsByNetwork(420)),
	},
	168_587_773: {
		map: marketsByNetwork(168_587_773),
		list: Object.values(marketsByNetwork(168_587_773)),
	},
};

export const getMarketsForNetwork = (networkId: NetworkId) => {
	return SYNTHS_BY_NETWORK[networkId]?.map ?? {};
};

export const getMarketsListForNetwork = (networkId: NetworkId) => {
	return SYNTHS_BY_NETWORK[networkId]?.list ?? [];
};
