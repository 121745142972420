import { createSelector } from '@reduxjs/toolkit';

import { sdk } from 'state/config';
import { selectPrices } from 'state/prices/selectors';
import type { RootState } from 'state/store';
import { FetchStatus } from 'state/types';
import { selectIsWalletConnected } from 'state/wallet/selectors';
import { toWei, zeroBN } from 'utils/formatters/number';

export const selectTokenList = (state: RootState) => state.exchange.tokenList;

export const selectQuoteAmountWei = createSelector(
	(state: RootState) => state.exchange.quoteAmount,
	(quoteAmount) => toWei(quoteAmount)
);

export const selectBaseAmountWei = createSelector(
	(state: RootState) => state.exchange.baseAmount,
	(baseAmount) => toWei(baseAmount)
);

export const selectBothSidesSelected = createSelector(
	(state: RootState) => ({
		baseCurrencyKey: state.exchange.baseCurrencyKey,
		quoteCurrencyKey: state.exchange.quoteCurrencyKey,
	}),
	({ baseCurrencyKey, quoteCurrencyKey }) => !!baseCurrencyKey && !!quoteCurrencyKey
);

export const selectQuoteCurrencyName = createSelector(
	(state: RootState) => state.exchange.quoteCurrencyKey,
	(quoteCurrencyKey) =>
		quoteCurrencyKey ? sdk.exchange.getCurrencyName(quoteCurrencyKey) : undefined
);

export const selectBaseCurrencyName = createSelector(
	(state: RootState) => state.exchange.baseCurrencyKey,
	(baseCurrencyKey) => (baseCurrencyKey ? sdk.exchange.getCurrencyName(baseCurrencyKey) : undefined)
);

export const selectShowFee = createSelector(
	(state: RootState) => state.exchange.txProvider,
	(txProvider) => false
);

export const selectQuoteBalanceWei = createSelector(
	(state: RootState) => state.balances.synthBalancesMap,
	(state: RootState) => state.balances.tokenBalances,
	(state: RootState) => state.exchange.quoteCurrencyKey,
	(balancesMap, tokenBalances, quoteCurrencyKey) => {
		return quoteCurrencyKey
			? toWei(balancesMap[quoteCurrencyKey]?.balance ?? tokenBalances?.[quoteCurrencyKey]?.balance)
			: zeroBN;
	}
);

export const selectInsufficientBalance = createSelector(
	selectQuoteAmountWei,
	selectQuoteBalanceWei,
	(quoteAmountWei, quoteBalance) => quoteAmountWei.gt(quoteBalance)
);

export const selectBaseBalanceWei = createSelector(
	(state: RootState) => state.balances.synthBalancesMap,
	(state: RootState) => state.balances.tokenBalances,
	(state: RootState) => state.exchange.baseCurrencyKey,
	(balancesMap, tokenBalances, baseCurrencyKey) => {
		return baseCurrencyKey
			? toWei(balancesMap[baseCurrencyKey]?.balance ?? tokenBalances?.[baseCurrencyKey]?.balance)
			: zeroBN;
	}
);

// TODO: Should probably resolve these from state of prices
// and asset rather than storing the price on state directly
export const selectQuotePriceRateWei = createSelector(
	(state: RootState) => state.exchange.quotePriceRate,
	(quotePriceRate) => toWei(quotePriceRate)
);

export const selectBasePriceRateWei = createSelector(
	(state: RootState) => state.exchange.basePriceRate,
	(basePriceRate) => toWei(basePriceRate)
);

export const selectSlippagePercentWei = createSelector(
	(state: RootState) => state.exchange.slippagePercent,
	(slippagePercent) => toWei(slippagePercent)
);

export const selectTransactionFeeWei = createSelector(
	(state: RootState) => state.exchange.transactionFee,
	(transactionFee) => (transactionFee ? toWei(transactionFee) : undefined)
);

export const selectFeeCostWei = createSelector(
	(state: RootState) => state.exchange.feeCost,
	(feeCost) => toWei(feeCost)
);

export const selectNeedsApproval = createSelector(
	(state: RootState) => state.exchange.txProvider,
	(state: RootState) => state.exchange.quoteCurrencyKey,
	(txProvider, quoteCurrencyKey) => true
);

export const selectIsApproved = createSelector(
	selectNeedsApproval,
	(state: RootState) => state.exchange.allowance,
	selectQuoteAmountWei,
	(needsApproval, allowance, quoteAmount) => {
		return needsApproval ? toWei(allowance).gte(quoteAmount) : true;
	}
);

export const selectIsApproving = createSelector(
	(state: RootState) => state.exchange.approvalStatus,
	(approvalStatus) => approvalStatus === FetchStatus.Loading
);

export const selectSubmissionDisabledReason = createSelector(
	(state: RootState) => state.exchange.txProvider,
	selectBothSidesSelected,
	selectInsufficientBalance,
	(state: RootState) => state.exchange.isSubmitting,
	selectIsApproving,
	selectIsWalletConnected,
	selectBaseAmountWei,
	selectQuoteAmountWei,
	(
		txProvider,
		bothSidesSelected,
		insufficientBalance,
		isSubmitting,
		isApproving,
		isWalletConnected,
		baseAmountWei,
		quoteAmountWei
	) => {
		if (!bothSidesSelected) {
			return 'exchange.summary-info.button.select-token';
		}
		if (insufficientBalance) {
			return 'exchange.summary-info.button.insufficient-balance';
		}
		if (isSubmitting) {
			return 'exchange.summary-info.button.submitting-order';
		}
		if (isApproving) {
			return 'exchange.summary-info.button.approving';
		}
		if (!isWalletConnected || baseAmountWei.lte(0) || quoteAmountWei.lte(0)) {
			return 'exchange.summary-info.button.enter-amount';
		}
		return null;
	}
);

export const selectIsSubmissionDisabled = createSelector(
	selectSubmissionDisabledReason,
	(submissionDisabledReason) => !!submissionDisabledReason
);

export const selectUsdRateWei = createSelector(selectPrices, (prices) => prices['sUSD'].onChain);

export const selectEstimatedBaseTradePrice = createSelector(
	selectBaseAmountWei,
	selectBasePriceRateWei,
	(baseAmount, basePriceRate) => {
		let tradePrice = baseAmount.mul(basePriceRate || 0);
		return tradePrice;
	}
);
