import detectEthereumProvider from '@metamask/detect-provider';
import Wei, { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';

import { DEFAULT_NETWORK_ID } from 'constants/defaults';
import {
	GWEI_DECIMALS,
	GasLimitEstimate,
	SUPPORTED_NETWORKS
} from 'constants/network';
import { NetworkId } from 'sdk/types/common';
import { GasPrice } from 'state/app/types';

import logError from './logError';

type EthereumProvider = {
	isMetaMask: boolean;
	chainId: string;
};

export const staticMainnetProvider = new ethers.providers.InfuraProvider();

export function isSupportedNetworkId(id: NetworkId): boolean {
	return SUPPORTED_NETWORKS.includes(id);
}

export async function getDefaultNetworkId(walletConnected = true) {
	try {
		if (walletConnected && window.ethereum) {
			const provider = (await detectEthereumProvider()) as EthereumProvider;
			if (provider && provider.chainId) {
				const id = Number(provider.chainId) as NetworkId;
				return isSupportedNetworkId(id) ? id : DEFAULT_NETWORK_ID;
			}
		}
		return DEFAULT_NETWORK_ID;
	} catch (e) {
		logError(e);
		return DEFAULT_NETWORK_ID;
	}
}

export const getTotalGasPrice = (gasPriceObj?: GasPrice | null) => {
	if (!gasPriceObj) return wei(0);
	const { gasPrice, baseFeePerGas, maxPriorityFeePerGas } = gasPriceObj;
	if (gasPrice) {
		return wei(gasPrice, GWEI_DECIMALS);
	}
	return wei(baseFeePerGas || 0, GWEI_DECIMALS).add(wei(maxPriorityFeePerGas || 0, GWEI_DECIMALS));
};

export const getTransactionPrice = (
	gasPrice: GasPrice | null,
	gasLimit: GasLimitEstimate,
	ethPrice: Wei | null,
	optimismLayerOneFee: Wei | null
) => {
	if (!gasPrice || !gasLimit || !ethPrice) return null;
	const totalGasPrice = getTotalGasPrice(gasPrice);
	const gasPriceCost = totalGasPrice.mul(wei(gasLimit, GWEI_DECIMALS)).mul(ethPrice);
	const l1Cost = ethPrice.mul(optimismLayerOneFee || 0);
	return gasPriceCost.add(l1Cost);
};
