import Wei, { WeiSource, wei } from '@synthetixio/wei';
import axios from 'codegen-graph-ts/build/src/lib/axios';
import generateGql from 'codegen-graph-ts/build/src/lib/gql';
export type SingleQueryOptions = {
	id: string;
	block?:
		| {
				number: number;
		  }
		| {
				hash: string;
		  };
};
export type MultiQueryOptions<T, R> = {
	first?: number;
	where?: T;
	block?:
		| {
				number: number;
		  }
		| {
				hash: string;
		  };
	orderBy?: keyof R;
	orderDirection?: 'asc' | 'desc';
};
const MAX_PAGE = 1000;
export type CandleFilter = {
	id?: string | null;
	id_not?: string | null;
	id_gt?: string | null;
	id_lt?: string | null;
	id_gte?: string | null;
	id_lte?: string | null;
	id_in?: string[];
	id_not_in?: string[];
	synth?: string | null;
	synth_not?: string | null;
	synth_gt?: string | null;
	synth_lt?: string | null;
	synth_gte?: string | null;
	synth_lte?: string | null;
	synth_in?: string[];
	synth_not_in?: string[];
	synth_contains?: string | null;
	synth_contains_nocase?: string | null;
	synth_not_contains?: string | null;
	synth_not_contains_nocase?: string | null;
	synth_starts_with?: string | null;
	synth_starts_with_nocase?: string | null;
	synth_not_starts_with?: string | null;
	synth_not_starts_with_nocase?: string | null;
	synth_ends_with?: string | null;
	synth_ends_with_nocase?: string | null;
	synth_not_ends_with?: string | null;
	synth_not_ends_with_nocase?: string | null;
	open?: WeiSource | null;
	open_not?: WeiSource | null;
	open_gt?: WeiSource | null;
	open_lt?: WeiSource | null;
	open_gte?: WeiSource | null;
	open_lte?: WeiSource | null;
	open_in?: WeiSource[];
	open_not_in?: WeiSource[];
	high?: WeiSource | null;
	high_not?: WeiSource | null;
	high_gt?: WeiSource | null;
	high_lt?: WeiSource | null;
	high_gte?: WeiSource | null;
	high_lte?: WeiSource | null;
	high_in?: WeiSource[];
	high_not_in?: WeiSource[];
	low?: WeiSource | null;
	low_not?: WeiSource | null;
	low_gt?: WeiSource | null;
	low_lt?: WeiSource | null;
	low_gte?: WeiSource | null;
	low_lte?: WeiSource | null;
	low_in?: WeiSource[];
	low_not_in?: WeiSource[];
	close?: WeiSource | null;
	close_not?: WeiSource | null;
	close_gt?: WeiSource | null;
	close_lt?: WeiSource | null;
	close_gte?: WeiSource | null;
	close_lte?: WeiSource | null;
	close_in?: WeiSource[];
	close_not_in?: WeiSource[];
	average?: WeiSource | null;
	average_not?: WeiSource | null;
	average_gt?: WeiSource | null;
	average_lt?: WeiSource | null;
	average_gte?: WeiSource | null;
	average_lte?: WeiSource | null;
	average_in?: WeiSource[];
	average_not_in?: WeiSource[];
	timestamp?: WeiSource | null;
	timestamp_not?: WeiSource | null;
	timestamp_gt?: WeiSource | null;
	timestamp_lt?: WeiSource | null;
	timestamp_gte?: WeiSource | null;
	timestamp_lte?: WeiSource | null;
	timestamp_in?: WeiSource[];
	timestamp_not_in?: WeiSource[];
	period?: WeiSource | null;
	period_not?: WeiSource | null;
	period_gt?: WeiSource | null;
	period_lt?: WeiSource | null;
	period_gte?: WeiSource | null;
	period_lte?: WeiSource | null;
	period_in?: WeiSource[];
	period_not_in?: WeiSource[];
	aggregatedPrices?: WeiSource | null;
	aggregatedPrices_not?: WeiSource | null;
	aggregatedPrices_gt?: WeiSource | null;
	aggregatedPrices_lt?: WeiSource | null;
	aggregatedPrices_gte?: WeiSource | null;
	aggregatedPrices_lte?: WeiSource | null;
	aggregatedPrices_in?: WeiSource[];
	aggregatedPrices_not_in?: WeiSource[];
	_change_block?: any | null;
};
export type CandleResult = {
	id: string;
	synth: string;
	open: Wei;
	high: Wei;
	low: Wei;
	close: Wei;
	average: Wei;
	timestamp: Wei;
	period: Wei;
	aggregatedPrices: Wei;
};
export type CandleFields = {
	id: true;
	synth: true;
	open: true;
	high: true;
	low: true;
	close: true;
	average: true;
	timestamp: true;
	period: true;
	aggregatedPrices: true;
};
export type CandleArgs<K extends keyof CandleResult> = {
	[Property in keyof Pick<CandleFields, K>]: CandleFields[Property];
};
export const getCandleById = async function <K extends keyof CandleResult>(
	url: string,
	options: SingleQueryOptions,
	args: CandleArgs<K>
): Promise<Pick<CandleResult, K>> {
	const res = await axios.post(url, {
		query: generateGql('candle', options, args),
	});
	const r = res.data as any;
	if (r.errors && r.errors.length) {
		throw new Error(r.errors[0].message);
	}
	const obj = r.data[Object.keys(r.data)[0]] as any;
	const formattedObj: any = {};
	if (obj['id']) formattedObj['id'] = obj['id'];
	if (obj['synth']) formattedObj['synth'] = obj['synth'];
	if (obj['open']) formattedObj['open'] = wei(obj['open']);
	if (obj['high']) formattedObj['high'] = wei(obj['high']);
	if (obj['low']) formattedObj['low'] = wei(obj['low']);
	if (obj['close']) formattedObj['close'] = wei(obj['close']);
	if (obj['average']) formattedObj['average'] = wei(obj['average']);
	if (obj['timestamp']) formattedObj['timestamp'] = wei(obj['timestamp'], 0);
	if (obj['period']) formattedObj['period'] = wei(obj['period'], 0);
	if (obj['aggregatedPrices']) formattedObj['aggregatedPrices'] = wei(obj['aggregatedPrices'], 0);
	return formattedObj as Pick<CandleResult, K>;
};
export const getCandles = async function <K extends keyof CandleResult>(
	url: string,
	options: MultiQueryOptions<CandleFilter, CandleResult>,
	args: CandleArgs<K>
): Promise<Pick<CandleResult, K>[]> {
	const paginatedOptions: Partial<MultiQueryOptions<CandleFilter, CandleResult>> = { ...options };
	let paginationKey: keyof CandleFilter | null = null;
	let paginationValue = '';
	if (options.first && options.first > MAX_PAGE) {
		paginatedOptions.first = MAX_PAGE;
		paginatedOptions.orderBy = options.orderBy || 'id';
		paginatedOptions.orderDirection = options.orderDirection || 'asc';
		paginationKey = (paginatedOptions.orderBy +
			(paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt')) as keyof CandleFilter;
		paginatedOptions.where = { ...options.where };
	}
	let results: Pick<CandleResult, K>[] = [];
	do {
		if (paginationKey && paginationValue)
			paginatedOptions.where![paginationKey] = paginationValue as any;
		const res = await axios.post(url, {
			query: generateGql('candles', paginatedOptions, args),
		});
		const r = res.data as any;
		if (r.errors && r.errors.length) {
			throw new Error(r.errors[0].message);
		}
		const rawResults = r.data[Object.keys(r.data)[0]] as any[];
		const newResults = rawResults.map((obj) => {
			const formattedObj: any = {};
			if (obj['id']) formattedObj['id'] = obj['id'];
			if (obj['synth']) formattedObj['synth'] = obj['synth'];
			if (obj['open']) formattedObj['open'] = wei(obj['open']);
			if (obj['high']) formattedObj['high'] = wei(obj['high']);
			if (obj['low']) formattedObj['low'] = wei(obj['low']);
			if (obj['close']) formattedObj['close'] = wei(obj['close']);
			if (obj['average']) formattedObj['average'] = wei(obj['average']);
			if (obj['timestamp']) formattedObj['timestamp'] = wei(obj['timestamp'], 0);
			if (obj['period']) formattedObj['period'] = wei(obj['period'], 0);
			if (obj['aggregatedPrices'])
				formattedObj['aggregatedPrices'] = wei(obj['aggregatedPrices'], 0);
			return formattedObj as Pick<CandleResult, K>;
		});
		results = results.concat(newResults);
		if (newResults.length < 1000) {
			break;
		}
		if (paginationKey) {
			paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy!];
		}
	} while (paginationKey && options.first && results.length < options.first);
	return options.first ? results.slice(0, options.first) : results;
};
export type FuturesTradeFilter = {
	id?: string | null;
	id_not?: string | null;
	id_gt?: string | null;
	id_lt?: string | null;
	id_gte?: string | null;
	id_lte?: string | null;
	id_in?: string[];
	id_not_in?: string[];
	blockTimestamp?: WeiSource | null;
	blockTimestamp_not?: WeiSource | null;
	blockTimestamp_gt?: WeiSource | null;
	blockTimestamp_lt?: WeiSource | null;
	blockTimestamp_gte?: WeiSource | null;
	blockTimestamp_lte?: WeiSource | null;
	blockTimestamp_in?: WeiSource[];
	blockTimestamp_not_in?: WeiSource[];
	_user?: string | null;
	_user_not?: string | null;
	_user_in?: string[];
	_user_not_in?: string[];
	_user_contains?: string | null;
	_user_not_contains?: string | null;
	size?: WeiSource | null;
	size_not?: WeiSource | null;
	size_gt?: WeiSource | null;
	size_lt?: WeiSource | null;
	size_gte?: WeiSource | null;
	size_lte?: WeiSource | null;
	size_in?: WeiSource[];
	size_not_in?: WeiSource[];
	asset?: string | null;
	asset_not?: string | null;
	asset_in?: string[];
	asset_not_in?: string[];
	asset_contains?: string | null;
	asset_not_contains?: string | null;
	_tokenAddress?: string | null;
	_tokenAddress_not?: string | null;
	_tokenAddress_in?: string[];
	_tokenAddress_not_in?: string[];
	_tokenAddress_contains?: string | null;
	_tokenAddress_not_contains?: string | null;
	price?: WeiSource | null;
	price_not?: WeiSource | null;
	price_gt?: WeiSource | null;
	price_lt?: WeiSource | null;
	price_gte?: WeiSource | null;
	price_lte?: WeiSource | null;
	price_in?: WeiSource[];
	price_not_in?: WeiSource[];
	positionId?: string | null;
	positionId_not?: string | null;
	positionId_gt?: string | null;
	positionId_lt?: string | null;
	positionId_gte?: string | null;
	positionId_lte?: string | null;
	positionId_in?: string[];
	positionId_not_in?: string[];
	positionSize?: WeiSource | null;
	positionSize_not?: WeiSource | null;
	positionSize_gt?: WeiSource | null;
	positionSize_lt?: WeiSource | null;
	positionSize_gte?: WeiSource | null;
	positionSize_lte?: WeiSource | null;
	positionSize_in?: WeiSource[];
	positionSize_not_in?: WeiSource[];
	_change_block?: any | null;
};
export type FuturesTradeResult = {
	id: string;
	blockTimestamp: Wei;
	_user: string;
	_tokenAddress: string;
	_cost: Wei;
	_position: Wei;
};
export type FuturesTradeFields = {
	id: true;
	blockTimestamp: true;
	_user: true;
	_tokenAddress: true;
	_cost: true;
	_position: true;
};
export type FuturesTradeArgs<K extends keyof FuturesTradeResult> = {
	[Property in keyof Pick<FuturesTradeFields, K>]: FuturesTradeFields[Property];
};
export const getFuturesTrades = async function <K extends keyof FuturesTradeResult>(
	url: string,
	query: string,
	options: MultiQueryOptions<FuturesTradeFilter, FuturesTradeResult>,
	args: FuturesTradeArgs<K>
): Promise<Pick<FuturesTradeResult, K>[]> {
	const paginatedOptions: Partial<MultiQueryOptions<FuturesTradeFilter, FuturesTradeResult>> = {
		...options,
	};
	let paginationKey: keyof FuturesTradeFilter | null = null;
	let paginationValue = '';
	if (options.first && options.first > MAX_PAGE) {
		paginatedOptions.first = MAX_PAGE;
		paginatedOptions.orderBy = options.orderBy || 'id';
		paginatedOptions.orderDirection = options.orderDirection || 'asc';
		paginationKey = (paginatedOptions.orderBy +
			(paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt')) as keyof FuturesTradeFilter;
		paginatedOptions.where = { ...options.where };
	}
	let results: Pick<FuturesTradeResult, K>[] = [];
	do {
		if (paginationKey && paginationValue)
			paginatedOptions.where![paginationKey] = paginationValue as any;
		const res = await axios.post(url, {
			query: generateGql(query, paginatedOptions, args),
		});
		const r = res.data as any;
		if (r.errors && r.errors.length) {
			throw new Error(r.errors[0].message);
		}
		const rawResults = r.data[Object.keys(r.data)[0]] as any[];
		const newResults = rawResults.map((obj) => {
			const formattedObj: any = {};
			if (obj['id']) formattedObj['id'] = obj['id'];
			if (obj['blockTimestamp']) formattedObj['blockTimestamp'] = wei(obj['blockTimestamp'], 0);
			if (obj['_user']) formattedObj['_user'] = obj['_user'];
			if (obj['_tokenAddress']) formattedObj['_tokenAddress'] = obj['_tokenAddress'];
			if (obj['_cost']) formattedObj['_cost'] = wei(obj['_cost'], 0);
			if (obj['_position']) formattedObj['_position'] = wei(obj['_position'], 0);
			return formattedObj as Pick<FuturesTradeResult, K>;
		});
		results = results.concat(newResults);

		if (newResults.length < 1000) {
			break;
		}
		if (paginationKey) {
			paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy!];
		}
	} while (paginationKey && options.first && results.length < options.first);
	return options.first ? results.slice(0, options.first) : results;
};
