import { wei } from '@synthetixio/wei';
import { BigNumber } from 'ethers';


export const DEFAULT_MAX_LEVERAGE = wei(25);
export const MIN_MARGIN_AMOUNT = wei(0);
export const APP_MAX_LEVERAGE_NUM = 25;
export const APP_MAX_LEVERAGE = wei(APP_MAX_LEVERAGE_NUM);

export const USD_DECIMALS = 6;

export const FIXED_POINT_X96 = BigNumber.from(2).pow(96);
export const MAX_SQRT_PRICE = BigNumber.from('125437238852233750000000000000000000000000000');
export const MIN_SQRT_PRICE = BigNumber.from('50041772226676');

export const FUNDING_RATE_DIVISOR = 24; // Display as per-hour

export const SAFETY_CONVERSION_MARGIN = wei('1.01');