import { createAsyncThunk } from '@reduxjs/toolkit';
import { wei } from '@synthetixio/wei';
import { ethers } from 'ethers';

import { DEFAULT_CRYPTO_DECIMALS } from 'constants/defaults';
import { monitorTransaction } from 'contexts/RelayerContext';
import { fetchBalances } from 'state/balances/actions';
import { FetchStatus, ThunkConfig } from 'state/types';
import { toWei, truncateNumbers, zeroBN } from 'utils/formatters/number';

import {
	selectBaseBalanceWei,
	selectInsufficientBalance,
	selectIsApproved,
	selectQuoteBalanceWei,
} from './selectors';
import { SwapRatio } from './types';

export const fetchTransactionFee = createAsyncThunk<
	{
		transactionFee?: string;
		feeCost?: string;
	},
	void,
	ThunkConfig
>('exchange/fetchTransactionFee', async (_, { getState, extra: { sdk } }) => {
	const {
		exchange: { quoteCurrencyKey, baseCurrencyKey, quoteAmount, baseAmount },
	} = getState();

	const isApproved = selectIsApproved(getState());
	const insufficientBalance = selectInsufficientBalance(getState());
	if (!isApproved || insufficientBalance) {
		return {
			transactionFee: '0',
			feeCost: '0',
		};
	}

	return { transactionFee: undefined, feeCost: undefined };
});

export const submitExchange = createAsyncThunk<void, void, ThunkConfig>(
	'exchange/submitExchange',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			exchange: {
				quoteCurrencyKey,
				baseCurrencyKey,
				quoteAmount,
				baseAmount,
				exact,
				slippagePercent,
			},
		} = getState();

		if (quoteCurrencyKey && baseCurrencyKey) {
			const hash = await sdk.uniswap.submitSwap(
				quoteCurrencyKey,
				baseCurrencyKey,
				quoteAmount,
				baseAmount,
				exact,
				slippagePercent ?? undefined
			);

			if (hash) {
				monitorTransaction({
					txHash: hash,
					onTxConfirmed: () => {
						dispatch({ type: 'exchange/setQuoteAmountRaw', payload: '' });
						dispatch({ type: 'exchange/setBaseAmountRaw', payload: '' });
						dispatch(fetchBalances());
						dispatch(resetCurrencyKeys());
					},
				});
			}
		}
	}
);

export const submitApprove = createAsyncThunk<void, void, ThunkConfig>(
	'exchange/submitApprove',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			exchange: { quoteCurrencyKey },
		} = getState();

		if (quoteCurrencyKey) {
			const hash = await sdk.uniswap.approve(quoteCurrencyKey);

			if (hash) {
				monitorTransaction({
					txHash: hash,
					onTxConfirmed: () => {
						dispatch({ type: 'exchange/setApprovalStatus', payload: FetchStatus.Success });
						dispatch({
							type: 'exchange/setAllowance',
							payload: wei(ethers.utils.formatEther(ethers.constants.MaxUint256)).toString(),
						});
					},
					onTxFailed: () => {
						dispatch({ type: 'exchange/setApprovalStatus', payload: FetchStatus.Error });
					},
				});
			}
		}
	}
);

export const fetchTokenList = createAsyncThunk<any, void, ThunkConfig>(
	'exchange/fetchTokenList',
	async (_, { extra: { sdk } }) => {
		const synthsMap = sdk.exchange.getSynthsMap();

		return { synthsMap };
	}
);

export const resetCurrencyKeys = createAsyncThunk<any, void, ThunkConfig>(
	'exchange/resetCurrencyKeys',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			exchange: { quoteCurrencyKey, baseCurrencyKey },
			wallet: { walletAddress },
		} = getState();

		let quotePriceRate = undefined;
		let basePriceRate = undefined;
		let txProvider: ReturnType<typeof sdk.exchange.getTxProvider> = undefined;
		let allowance = undefined;

		if (walletAddress) {
			if (quoteCurrencyKey && baseCurrencyKey) {
				txProvider = sdk.exchange.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
			}

			const coinGeckoPrices = await sdk.exchange.getCoingeckoPrices(
				baseCurrencyKey,
				quoteCurrencyKey
			);
			if (quoteCurrencyKey) {
				quotePriceRate = sdk.exchange.getPriceRate(quoteCurrencyKey, coinGeckoPrices);
				allowance = await sdk.uniswap.checkApprove(quoteCurrencyKey);
			}
			if (baseCurrencyKey) {
				basePriceRate = sdk.exchange.getPriceRate(baseCurrencyKey, coinGeckoPrices);
			}
		}

		dispatch(queryAmount());

		return {
			quotePriceRate: quotePriceRate?.toString(),
			basePriceRate: basePriceRate?.toString(),
			txProvider,
			allowance: allowance?.toString(),
		};
	}
);

export const changeQuoteCurrencyKey = createAsyncThunk<any, string, ThunkConfig>(
	'exchange/changeQuoteCurrencyKey',
	async (currencyKey, { dispatch }) => {
		dispatch({ type: 'exchange/setQuoteCurrencyKey', payload: currencyKey });
		dispatch(resetCurrencyKeys());
	}
);

export const changeBaseCurrencyKey = createAsyncThunk<any, string, ThunkConfig>(
	'exchange/changeBaseCurrencyKey',
	async (currencyKey, { dispatch }) => {
		dispatch({ type: 'exchange/setBaseCurrencyKey', payload: currencyKey });
		dispatch(resetCurrencyKeys());
	}
);

export const resetCurrencies = createAsyncThunk<
	void,
	{
		quoteCurrencyFromQuery: string | undefined;
		baseCurrencyFromQuery: string | undefined;
	},
	ThunkConfig
>(
	'exchange/resetCurrencies',
	async ({ quoteCurrencyFromQuery, baseCurrencyFromQuery }, { dispatch, extra: { sdk } }) => {
		await dispatch(fetchTokenList());

		const [validQuoteCurrency, validBaseCurrency] = sdk.exchange.validCurrencyKeys(
			quoteCurrencyFromQuery,
			baseCurrencyFromQuery
		);

		const quoteCurrencyKey = validQuoteCurrency ? quoteCurrencyFromQuery : 'USDB';
		const baseCurrencyKey = validBaseCurrency ? baseCurrencyFromQuery : undefined;

		dispatch({ type: 'exchange/setQuoteCurrencyKey', payload: quoteCurrencyKey });
		dispatch({ type: 'exchange/setBaseCurrencyKey', payload: baseCurrencyKey });
		if (!baseCurrencyKey) {
			dispatch({ type: 'exchange/setExact', payload: true });
		}
		dispatch(resetCurrencyKeys());
	}
);

export const setMaxQuoteBalance = createAsyncThunk<void, void, ThunkConfig>(
	'exchange/setMaxQuoteBalance',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState();
		const quoteBalance = selectQuoteBalanceWei(state);

		dispatch({
			type: 'exchange/setQuoteAmount',
			payload: truncateNumbers(quoteBalance, DEFAULT_CRYPTO_DECIMALS),
		});

		dispatch(queryAmount());
	}
);

export const setMaxBaseBalance = createAsyncThunk<void, void, ThunkConfig>(
	'exchange/setMaxBaseBalance',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState();
		const baseBalance = selectBaseBalanceWei(state);

		dispatch({
			type: 'exchange/setBaseAmount',
			payload: truncateNumbers(baseBalance, DEFAULT_CRYPTO_DECIMALS),
		});

		dispatch(queryAmount());
	}
);

export const queryAmount = createAsyncThunk<any, void, ThunkConfig>(
	'exchange/quoteAmount',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			exchange: {
				quoteCurrencyKey,
				baseCurrencyKey,
				quoteAmount,
				baseAmount,
				exact,
				slippagePercent,
			},
		} = getState();
		let amount = '';
		if (quoteCurrencyKey && baseCurrencyKey) {
			amount = await sdk.uniswap.querySwap(
				quoteCurrencyKey,
				baseCurrencyKey,
				quoteAmount,
				baseAmount,
				exact,
				slippagePercent ?? undefined
			);
		}

		return {
			side: !exact,
			amount: amount,
		};
	}
);

export const changeExact = createAsyncThunk<void, boolean, ThunkConfig>(
	'exchange/changeExact',
	async (exact, { dispatch, getState, extra: { sdk } }) => {
		dispatch({ type: 'exchange/setExact', payload: exact });
		dispatch(queryAmount());
	}
);

export const changeRatio = createAsyncThunk<void, SwapRatio, ThunkConfig>(
	'exchange/changeRatio',
	async (ratio, { dispatch, getState, extra: { sdk } }) => {
		const {
			balances: { synthBalancesMap, tokenBalances },
			exchange: { quoteCurrencyKey },
		} = getState();
		const balance = quoteCurrencyKey
			? toWei(
					synthBalancesMap[quoteCurrencyKey]?.balance ?? tokenBalances?.[quoteCurrencyKey]?.balance
			  )
			: zeroBN;

		const ratioBalance = balance.mul(ratio).div(100);

		const displayAmount = ratioBalance;
		dispatch({ type: 'exchange/setQuoteAmount', payload: displayAmount.toString() });
		dispatch({ type: 'exchange/setRatio', payload: ratio });
		dispatch(queryAmount());
	}
);

export const flipCurrencyKeys = createAsyncThunk<void, void, ThunkConfig>(
	'exchange/flipCurrencyKeys',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		dispatch({ type: 'swapCurrencies' });
		dispatch(resetCurrencyKeys());
	}
);
