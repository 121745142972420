import { createAsyncThunk } from '@reduxjs/toolkit';
import { monitorTransaction } from 'contexts/RelayerContext';
import { COOLDOWN_PERIOD } from 'sdk/constants/futures';
import { FetchStatus, ThunkConfig } from 'state/types';
import { fetchBalances } from 'state/balances/actions';
import { closeModal, setCode, setValid } from './reducer';

export const submitRequestTokens = createAsyncThunk<void, void, ThunkConfig>(
	'token/requestTokens',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			token: { currencyKey },
		} = getState();

		if (currencyKey) {
			const hash = await sdk.token.requestTokens(currencyKey);

			if (hash) {
				monitorTransaction({
					txHash: hash,
					onTxConfirmed: () => {
						const currentCurrencyKey = getState().token.currencyKey;
						if (currencyKey === currentCurrencyKey) {
							dispatch({ type: 'token/setCooldown', payload: Date.now() + COOLDOWN_PERIOD * 1000 });
						}
						dispatch(fetchBalances());
					},
				});
			}
		}
	}
);

export const submitRedeem = createAsyncThunk<void, void, ThunkConfig>(
	'token/redeem',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			token: { code }
		} = getState();
		if (code) {
			const hash = await sdk.invite.redeem(code);
			monitorTransaction({
				txHash: hash,
				onTxConfirmed: () => {
					dispatch(closeModal());
				},
			});
		}
	}
);

export const changeCurrencyKey = createAsyncThunk<any, string, ThunkConfig>(
	'token/changeCurrencyKey',
	async (currencyKey, { dispatch }) => {
		dispatch({ type: 'token/setCurrencyKey', payload: currencyKey });
		dispatch(getCooldown());
	}
);

export const getCooldown = createAsyncThunk<number, void, ThunkConfig>(
	'token/getCooldown',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const {
			token: { currencyKey },
		} = getState();

		if (currencyKey) {
			const time = await sdk.token.nextRequestTime(currencyKey);
			if (time) {
				return Date.now() + time * 1000;
			} else {
				return 1;
			}
		}

		return 0;
	}
);

export const checkCode = createAsyncThunk<any, string, ThunkConfig>(
	'token/checkCode',
	async (code, { getState, dispatch, extra: { sdk }}) => {
		dispatch(setCode(code));
		const validCode = await sdk.invite.checkCode(code);
		dispatch(setValid(validCode));
	}	
);