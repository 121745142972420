export const ADDRESSES: Record<string, Record<number, string>> = {
	SynthesisTestnetNFT: {
		168_587_773: '0x437F402485D17D24cB512D4806245c7101a20B67',
	},
	InviteValidator: {
		168_587_773: '0x9B443454ee3a9b9dD65eFF3f4f3e1C6256E19335',
	},
	IPyth: {
		1: '0x4305FB66699C3B2702D4d05CF36551390A4c69C6',
		168_587_773: '0xA2aa501b19aff244D90cc15a4Cf739D2725B5729',
	},
	UniswapV3IQuoter: {
		1: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
		5: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
		10: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
		420: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
		168_587_773: '0x0f8A3C50C25214983BCdCF8a634Ca31F9B428068',
	},
	UniswapV3SwapRouter: {
		1: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
		5: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
		10: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
		420: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
		168_587_773: '0xB379dDb3B8a152B5010DFBd93DEC16CC89C2703b',
	},
	TestnetExchange: {
		5: '0xA0F8CdEe9ea9Edc3b067C907E304Cb923f54B485',
		168_587_773: '0x852084F733652db9a3d38d045D4Ca11Bc9BC44B4',
	},
};
