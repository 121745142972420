import { Chain, connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
	braveWallet,
	coinbaseWallet,
	injectedWallet,
	metaMaskWallet,
	rainbowWallet,
	safeWallet,
	walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createClient } from 'wagmi';
import {
	arbitrum,
	avalanche,
	bsc,
	goerli,
	mainnet,
	optimism,
	polygon,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import BlastIcon from 'assets/png/rainbowkit/blast.png';
import Frame from 'components/Rainbowkit/Frame';
import Tally from 'components/Rainbowkit/Tally';
import { STALL_TIMEOUT } from 'constants/network';
import { AnkrProvider } from '@ankr.com/ankr.js';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

const bscWithIcon: Chain = {
	...bsc,
};

export const blastSepolia: Chain = {
	id: 168_587_773,
	name: 'Blast Sepolia',
	network: 'blast-sepolia',
	nativeCurrency: {
	  	name: 'Ether',
	  	symbol: 'ETH',
	  	decimals: 18,
	},
	rpcUrls: {
		public: {
			http: ['https://sepolia.blast.io'],
	  	},
		default: {
			http: [process.env.NEXT_PUBLIC_ANKR_PROVIDER as string],
	  	},
	},
	blockExplorers: {
		public: {
			name: 'Blastscan',
			url: 'https://testnet.blastscan.io',
	  	},
		default: {
			name: 'Blastscan',
			url: 'https://testnet.blastscan.io',
	  	},
	},
	testnet: true,
	iconUrl: async () => BlastIcon,
};

export const chain = {
	blastSepolia,
};

const { chains, provider } = configureChains(Object.values(chain), [
	// infuraProvider({
	// 	apiKey: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID!,
	// 	stallTimeout: STALL_TIMEOUT,
	// 	priority: process.env.NEXT_PUBLIC_PROVIDER_ID === 'INFURA' ? 0 : 2,
	// }),
	jsonRpcProvider({
		rpc: () => {
		  return {
			http: process.env.NEXT_PUBLIC_ANKR_PROVIDER as string,
		  };
		},
		stallTimeout: STALL_TIMEOUT,
		priority: 6,
	}),
	publicProvider({ stallTimeout: STALL_TIMEOUT, priority: 5 }),
]);

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_V2_ID as string;

const connectors = connectorsForWallets([
	{
		groupName: 'Popular',
		wallets: [
			safeWallet({ chains }),
			metaMaskWallet({ projectId, chains }),
			rainbowWallet({ projectId, chains }),
			coinbaseWallet({ appName: 'Influx', chains }),
			walletConnectWallet({ projectId, chains }),
		],
	},
	{
		groupName: 'More',
		wallets: [
			braveWallet({ chains, shimDisconnect: true }),
			Tally({ chains, shimDisconnect: true }),
			Frame({ chains, shimDisconnect: true }),
			injectedWallet({ chains, shimDisconnect: true }),
		],
	},
]);

export const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
});

const MAIN_CHAIN = chain.blastSepolia;

export { MAIN_CHAIN, chains };