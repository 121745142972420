import Wei from '@synthetixio/wei';
import { BigNumber } from 'ethers';

export type FundingRateInput = {
	marketAddress: string | undefined;
	marketKey: FuturesMarketKey;
	price: Wei | undefined;
	currentFundingRate: Wei | undefined;
};

export type SynthSuspensionReason =
	| 'system-upgrade'
	| 'market-closure'
	| 'circuit-breaker'
	| 'emergency';

export type MarketClosureReason = SynthSuspensionReason;

export type FuturesMarket<T = Wei> = {
	market: string;
	marketKey: FuturesMarketKey;
	marketName: string;
	asset: FuturesMarketAsset;
	price: T;
	openLongInterest: T;
	openShortInterest: T;
	currentFundingRate: T;
	maxLeverage: T;
	minInitialMargin: T;
	isSuspended: boolean;
	marketClosureReason: SynthSuspensionReason;
};

export type FundingRateUpdate = {
	funding: Wei;
	timestamp: number;
};

export type FundingRateResponse = {
	asset: FuturesMarketKey;
	fundingTitle: string;
	fundingRate: Wei | null;
};

export enum FuturesMarketKey {
	BTCPERP = 'BTCPERP',
	ETHPERP = 'ETHPERP',
	SOLPERP = 'SOLPERP',
	DOGEPERP = 'DOGEPERP',
	BNBPERP = 'BNBPERP',
	AVAXPERP = 'AVAXPERP',
	UNIPERP = 'UNIPERP',
	ADAPERP = 'ADAPERP',
	BLURPERP = 'BLURPERP',
	SEIPERP = 'SEIPERP',
	SUIPERP = 'SUIPERP',
	SHIBPERP = 'SHIBPERP',
	APTPERP = 'APTPERP',
	INJPERP = 'INJPERP',
	LINKPERP = 'LINKPERP',
	PEPEPERP = 'PEPEPERP',
	APEPERP = 'APEPERP',
	BONKPERP = 'BONKPERP',
	SNXPERP = 'SNXPERP',
	MEMEPERP = 'MEMEPERP',
	FLOKIPERP = 'FLOKIPERP',
}

export enum FuturesMarketAsset {
	BTC = 'BTC',
	ETH = 'ETH',
	SOL = 'SOL',
	DOGE = 'DOGE',
	BNB = 'BNB',
	AVAX = 'AVAX',
	UNI = 'UNI',
	ADA = 'ADA',
	BLUR = 'BLUR',
	SEI = 'SEI',
	SUI = 'SUI',
	SHIB = 'SHIB',
	APT = 'APT',
	INJ = 'INJ',
	LINK = 'LINK',
	PEPE = 'PEPE',
	APE = 'APE',
	BONK = 'BONK',
	SNX = 'SNX',
	MEME = 'MEME',
	FLOKI = 'FLOKI',
}

export interface FuturesMarketConfig {
	key: FuturesMarketKey;
	asset: FuturesMarketAsset;
	supports: 'mainnet' | 'testnet' | 'both';
	version: 1 | 2;
	pythIds?: {
		mainnet: string;
		testnet: string;
	};
	disabled?: boolean;
	decimals: number;
}

export type FuturesVolumes<T = Wei> = {
	[asset: string]: {
		volume: T;
		trades: T;
	};
};

export type PositionDetail = {
	remainingMargin: Wei;
	accessibleMargin: Wei;
	orderPending: boolean;
	order: {
		pending: boolean;
		fee: Wei;
		leverage: Wei;
	};
	position: {
		fundingIndex: Wei;
		lastPrice: Wei;
		size: Wei;
		margin: Wei;
	};
	accruedFunding: Wei;
	notionalValue: Wei;
	liquidationPrice: Wei;
	profitLoss: Wei;
};

export enum PositionSide {
	LONG = 'long',
	SHORT = 'short',
}

export type FuturesButtonType = PositionSide | 'hedge' | 'redeem';

export enum ContractOrderType {
	MARKET = 0,
	DELAYED = 1,
	DELAYED_OFFCHAIN = 2,
}

export const OrderEnumByType: Record<string, ContractOrderType> = {
	market: ContractOrderType.MARKET,
	delayed: ContractOrderType.DELAYED,
	delayed_offchain: ContractOrderType.DELAYED_OFFCHAIN,
};

export type FuturesFilledPosition<T = Wei> = {
	canLiquidatePosition: boolean;
	side: PositionSide;
	notionalValue: T;
	accruedFunding: T;
	leverage: T;
	remainingMargin: T;
	actualMargin: T;
	liquidationPrice: T;
};

export type FuturesPositionHistory<T = Wei> = {
	id: Number;
	transactionHash: string;
	timestamp: number;
	openTimestamp: number;
	closeTimestamp: number | undefined;
	market: string;
	asset: FuturesMarketAsset;
	marketKey: FuturesMarketKey;
	account: string;
	abstractAccount: string;
	isOpen: boolean;
	isLiquidated: boolean;
	size: T;
	feesPaid: T;
	netFunding: T;
	netTransfers: T;
	totalDeposits: T;
	initialMargin: T;
	margin: T;
	entryPrice: T;
	avgEntryPrice: T;
	exitPrice: T;
	leverage: T;
	side: PositionSide;
	pnl: T;
	pnlWithFeesPaid: T;
	totalVolume: T;
	trades: number;
};

export type FuturesPosition<T = Wei> = {
	asset: FuturesMarketAsset;
	marketKey: FuturesMarketKey;
	margin: T;
	lastMargin: T;
	size: T;
	side: PositionSide;
	position: FuturesFilledPosition<T> | null;
};

export type ModifyPositionOptions<T extends boolean> = {
	delayed?: boolean;
	offchain?: boolean;
	estimationOnly?: T;
};

// This type exists to rename enum types from the subgraph to display-friendly types
export type FuturesOrderTypeDisplay =
	| 'Next Price'
	| 'Limit'
	| 'Stop Market'
	| 'Market'
	| 'Liquidation'
	| 'Delayed'
	| 'Delayed Market';

export type FuturesOrder<T = Wei> = {
	id: string; // formatted subgraph id
	contractId: number;
	account: string;
	asset: FuturesMarketAsset;
	market: string;
	marketKey: FuturesMarketKey;
	size: T;
	targetPrice: T | null;
	marginDelta: T;
	targetRoundId: T | null;
	orderType: FuturesOrderTypeDisplay;
	sizeTxt?: string;
	targetPriceTxt?: string;
	side?: PositionSide;
	isStale?: boolean;
	isExecutable?: boolean;
	isCancelling?: boolean;
};

export type FuturesPotentialTradeDetails<T = Wei> = {
	size: T;
	sizeDelta: T;
	liqPrice: T;
	margin: T;
	price: T;
	fee: T;
	leverage: T;
	notionalValue: T;
	side: PositionSide;
	status: PotentialTradeStatus;
	showStatus: boolean;
	statusMessage: string;
	priceImpact: T;
	slippageAmount: T;
};

export enum PotentialTradeStatus {
	// Contract status mapping
	OK = 0,
	INVALID_PRICE = 1,
	INVALID_ORDER_PRICE = 2,
	PRICE_OUT_OF_BOUNDS = 3,
	CAN_LIQUIDATE = 4,
	CANNOT_LIQUIDATE = 5,
	MAX_MARKET_SIZE_EXCEEDED = 6,
	MAX_LEVERAGE_EXCEEDED = 7,
	INSUFFICIENT_MARGIN = 8,
	NOT_PERMITTED = 9,
	NIL_ORDER = 10,
	NO_POSITION_OPEN = 11,
	PRICE_TOO_VOLATILE = 12,
	PRICE_IMPACT_TOLERANCE_EXCEEDED = 13,

	// Our own local status
	INSUFFICIENT_FREE_MARGIN = 100,
}

export type PostTradeDetailsResponse = {
	margin: BigNumber;
	size: BigNumber;
	price: BigNumber;
	liqPrice: BigNumber;
	fee: BigNumber;
	status: number;
};

export type IsolatedMarginOrderType = 'delayed' | 'delayed_offchain' | 'market';
export type FuturesOrderType = IsolatedMarginOrderType;

export type FuturesTrade<T = Wei> = {
	size: T;
	asset: string;
	price: T;
	txnHash: string;
	timestamp: T;
	positionSize: T;
	side: PositionSide;
};

export type MarginTransfer = {
	timestamp: number;
	account: string;
	size: number;
	txHash: string;
	action: string;
	market?: string;
	asset?: FuturesMarketAsset;
};
