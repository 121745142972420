import { BigNumber, utils } from "ethers";
import InfluxSDK from "sdk";
import { ADDRESSES } from "sdk/contracts/constants";

export default class InviteService {
    private sdk: InfluxSDK;
    constructor(sdk: InfluxSDK) {
        this.sdk = sdk;
    }

    public getValidatorAddress(): string {
        return ADDRESSES.InviteValidator[this.sdk.context.networkId];
    }

    public getNFTAddress(): string {
        return ADDRESSES.SynthesisTestnetNFT[this.sdk.context.networkId];
    }

    public async testnetNFTBalance(): Promise<string> {
        const nft = this.getNFTAddress();
        const nftContract = this.sdk.context.contracts.SynthesisTestnetNFT?.callStatic;
        if (nft && nftContract && this.sdk.context.walletAddress) {
            const balance = await nftContract.balanceOf(this.sdk.context.walletAddress);
            return balance.toString();
        }
        return '0';
    }

    public async checkCode(code: string): Promise<boolean> {
        const validator = this.getValidatorAddress();
        const valContract = this.sdk.context.contracts.InviteValidator?.callStatic;
        if (validator && valContract && this.sdk.context.walletAddress && code.length === 16) {
            const codeNum = BigNumber.from("0x" + code);
            const confirmation: boolean = await valContract.validate(codeNum);
            return confirmation;
        }
        return false;
    }

    public hashStatus(walletAddress: string, i: number, status: BigNumber): string {
        return utils.keccak256(utils.solidityPack(["address", "uint256", "uint256", "uint256"], [walletAddress, i, status, 9453029165053])).slice(-16);
    }

    public async getCodes(address: string): Promise<string[]> {
        const validator = this.getValidatorAddress();
        const valContract = this.sdk.context.contracts.InviteValidator?.callStatic;
        if (validator && valContract && address) {
            const codes: BigNumber[] = await valContract.getStatus(address);
            return codes.map((code, i) => {
                return this.hashStatus(address, i, code);
            });
        }
        return [];
    }

    public async redeem(code: string) {
        const codeNum = BigNumber.from("0x" + code);
        const { hash } = await this.sdk.transactions.createInfluxTxn(
            'InviteValidator',
            'redeem',
            [this.sdk.context.walletAddress, codeNum]
        );
        return hash;
    }
}