import { ethers } from 'ethers';

import Context, { IContext } from './context';
import ExchangeService from './services/exchange';
import FuturesService from './services/futures';
import InviteService from './services/invites';
import PricesService from './services/prices';
import TokenService from './services/token';
import TransactionsService from './services/transactions';
import UniswapService from './services/uniswap';

export default class InfluxSDK {
	public context: Context;

	public exchange: ExchangeService;
	public futures: FuturesService;
	public invite: InviteService;
	public transactions: TransactionsService;
	public prices: PricesService;
	public token: TokenService;
	public uniswap: UniswapService;

	constructor(context: IContext) {
		this.context = new Context(context);
		this.exchange = new ExchangeService(this);
		this.futures = new FuturesService(this);
		this.invite = new InviteService(this);
		this.prices = new PricesService(this);
		this.transactions = new TransactionsService(this);
		this.token = new TokenService(this);
		this.uniswap = new UniswapService(this);
	}

	public setProvider(provider: ethers.providers.Provider) {
		return this.context.setProvider(provider);
	}

	public setSigner(signer: ethers.Signer) {
		return this.context.setSigner(signer);
	}
}
