import { createSelector } from '@reduxjs/toolkit';
import Wei, { wei } from '@synthetixio/wei';

import { DEFAULT_MAX_LEVERAGE, SAFETY_CONVERSION_MARGIN } from 'constants/futures';
import { MAIN_CHAIN } from 'containers/Connector/config';
import { TransactionStatus } from 'sdk/types/common';
import { FuturesPosition, PositionSide } from 'sdk/types/futures';
import { unserializePotentialTrade } from 'sdk/utils/futures';
import { deserializeWeiObject } from 'state/helpers';
import { selectOffchainPricesInfo, selectPrices } from 'state/prices/selectors';
import { RootState } from 'state/store';
import { selectNetwork, selectWallet } from 'state/wallet/selectors';
import { getKnownError } from 'utils/formatters/error';
import { toWei, zeroBN } from 'utils/formatters/number';
import {
	MarketKeyByAsset,
	unserializeIsolatedMarginTradeInputs,
	unserializeMarkets,
	unserializePositionHistory,
	unserializeTrades,
	updatePositionUpnl
} from 'utils/futures';

import { selectHasTestnetNFT } from 'state/balances/selectors';
import { MarkPriceInfos, MarkPrices, futuresPositionKeys } from './types';

export const selectQueryStatuses = (state: RootState) => state.futures.queryStatuses;

export const selectMarketsQueryStatus = (state: RootState) => state.futures.queryStatuses.markets;

export const selectIsolatedLeverageInput = (state: RootState) =>
	state.futures.isolatedMargin.leverageInput;

export const selectFuturesSupportedNetwork = (state: RootState) =>
	state.wallet.networkId === MAIN_CHAIN.id;

export const selectIsolatedAccountData = createSelector(
	selectWallet,
	selectNetwork,
	selectFuturesSupportedNetwork,
	(state: RootState) => state.futures.isolatedMargin,
	(wallet, network, supportedNetwork, isolatedMargin) => {
		return wallet && supportedNetwork ? isolatedMargin.accounts[network][wallet] : null;
	}
);

export const selectMarketKey = createSelector(
	(state: RootState) => state.futures.isolatedMargin.selectedMarketAsset,
	(marketAsset) => MarketKeyByAsset[marketAsset]
);

export const selectMarketAsset = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.isolatedMargin.selectedMarketAsset
);

export const selectMarkets = createSelector(
	(state: RootState) => state.futures.markets,
	(markets) => unserializeMarkets(markets)
);

export const selectMarketKeys = createSelector(
	(state: RootState) => state.futures.markets,
	(markets) => markets.map(({ asset }) => MarketKeyByAsset[asset])
);

export const selectMarketInfo = createSelector(
	selectMarkets,
	selectMarketKey,
	(markets, selectedMarket) => {
		return markets.find((market) => market.marketKey === selectedMarket);
	}
);

export const selectOrderType = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.isolatedMargin.orderType
);

export const selectMarketPrice = createSelector(
	selectMarketAsset,
	selectPrices,
	(marketAsset, prices) => {
		const price = prices[marketAsset];
		// Note this assumes the order type is always delayed off chain
		return price?.offChain ?? price?.onChain ?? wei(0);
	}
);

export const selectMarketPriceInfo = createSelector(
	selectMarketInfo,
	selectOffchainPricesInfo,
	(marketInfo, pricesInfo) => {
		if (!marketInfo || !pricesInfo[marketInfo.asset]) return;
		return pricesInfo[marketInfo.asset];
	}
);

export const selectExchangePrice = createSelector(selectMarketInfo, (marketInfo) => {
	return marketInfo?.price;
});

export const selectOpenLongInterest = createSelector(selectMarketInfo, (marketInfo) => {
	return marketInfo?.openLongInterest;
});

export const selectOpenShortInterest = createSelector(selectMarketInfo, (marketInfo) => {
	return marketInfo?.openShortInterest;
});

export const selectSkewAdjustedPrice = createSelector(selectMarketPrice, (price) => {
	return price;
});

export const selectSkewAdjustedPriceInfo = createSelector(selectMarketPriceInfo, (priceInfo) => {
	return priceInfo;
});

export const selectMarketPrices = createSelector(
	selectMarketAsset,
	selectPrices,
	(marketAsset, prices) => {
		return prices[marketAsset] ?? {};
	}
);

export const selectMarkPrices = createSelector(selectMarkets, selectPrices, (markets, prices) => {
	const markPrices: MarkPrices = {};
	return markets.reduce((acc, market) => {
		const price = prices[market.asset]?.offChain ?? wei(0);
		return {
			...acc,
			[market.marketKey]: wei(price),
		};
	}, markPrices);
});

export const selectMarkPriceInfos = createSelector(
	selectMarkets,
	selectOffchainPricesInfo,
	(markets, prices) => {
		const markPrices: MarkPriceInfos = {};
		return markets.reduce((acc, market) => {
			const price = prices[market.asset]?.price ?? wei(0);
			return {
				...acc,
				[market.marketKey]: {
					price: wei(price),
					change: prices[market.asset]?.change ?? null,
				},
			};
		}, markPrices);
	}
);

export const selectFuturesTokenBalance = createSelector(
	selectMarketAsset,
	(state: RootState) => state.balances.synthBalancesMap,
	(state: RootState) => state.balances.tokenBalances,
	(asset, balancesMap, tokenBalances) => {
		return toWei(balancesMap[asset]?.balance ?? tokenBalances?.[asset]?.balance);
	}
);

export const selectFuturesTokenAllowance = createSelector(
	selectMarketAsset,
	(state: RootState) => state.balances.allowances,
	(asset, allowances) => {
		return toWei(allowances?.[asset]?.allowance);
	}
);


export const selectFuturesAccount = createSelector(selectWallet, (wallet) => {
	return wallet;
});

export const selectPositionHistory = createSelector(
	selectIsolatedAccountData,
	(isolatedAccountData) => {
		return unserializePositionHistory(isolatedAccountData?.positionHistory ?? []);
	}
);

export const selectSelectedMarketPositionHistory = createSelector(
	selectMarketAsset,
	selectPositionHistory,
	(marketAsset, positionHistory) => {
		return positionHistory.find(({ asset, isOpen }) => isOpen && asset === marketAsset);
	}
);

export const selectIsolatedMarginPositions = createSelector(
	selectMarkPrices,
	selectIsolatedAccountData,
	(prices, account) => {
		return account?.positions?.map((p) => updatePositionUpnl(p, prices)) ?? [];
	}
);

export const selectFuturesPositions = createSelector(
	selectIsolatedMarginPositions,
	(isolatedMarginPositions) => {
		return isolatedMarginPositions;
	}
);

export const selectSubmittingFuturesTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		);
	}
);

export const selectApprovingFuturesTx = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return submitting && (app.transaction?.type === 'approve');
	}	
);

export const selectIsolatedTransferError = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (app.transaction?.type === 'deposit_isolated' ||
			app.transaction?.type === 'withdraw_isolated') &&
			app.transaction?.status === TransactionStatus.Failed
			? app.transaction?.error ?? 'Transaction failed'
			: null;
	}
);

export const selectIsModifyingIsolatedPosition = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return app.transaction?.type === 'modify_isolated' && submitting;
	}
);

export const selectIsExecutingOrder = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return false;
	}
);

export const selectIsMarketCapReached = createSelector(
	(state: RootState) => state.futures.isolatedMargin.leverageSide,
	selectMarketInfo,
	selectMarketPrice,
	(leverageSide, marketInfo, marketAssetRate) => {
		return false;
	}
);

export const selectPosition = createSelector(
	selectFuturesPositions,
	selectMarketInfo,
	(positions, market) => {
		const position = positions.find((p) => p.marketKey === market?.marketKey);
		return position
			? (deserializeWeiObject(position, futuresPositionKeys) as FuturesPosition)
			: undefined;
	}
);

export const selectLeverageSide = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.isolatedMargin.leverageSide
);

export const selectButton = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.isolatedMargin.buttonSelect
);

export const selectSide = createSelector(selectPosition, (position) => {
	return position?.side;
});

export const selectMaxLeverage = createSelector(
	selectMarketInfo,
	(market) => {
		return DEFAULT_MAX_LEVERAGE;
	}
);

export const selectAboveMaxLeverage = createSelector(
	selectMaxLeverage,
	(maxLeverage) => {
		return maxLeverage;
	}
);

export const selectAvailableMargin = createSelector(
	selectMarketInfo,
	selectPosition,
	(marketInfo, position) => {
		if (!marketInfo || !position) return zeroBN;
		if (!position?.position) return zeroBN;

		// check if available margin will be less than 0
		return position.position.remainingMargin.gt(0) ? position.position.remainingMargin : zeroBN;
	}
);

export const selectIsolatedMarginTradeInputs = createSelector(
	selectLeverageSide,
	(state: RootState) => state.futures.isolatedMargin.tradeInputs,
	(side, tradeInputs) => {
		const inputs = unserializeIsolatedMarginTradeInputs(tradeInputs);
		const deltas = {
			susdSizeDelta: side === PositionSide.LONG ? inputs.susdSize : inputs.susdSize.neg(),
			nativeSizeDelta: side === PositionSide.LONG ? inputs.nativeSize : inputs.nativeSize.neg(),
		};
		return {
			...inputs,
			...deltas,
			susdSizeString: tradeInputs.susdSize,
			nativeSizeString: tradeInputs.nativeSize,
		};
	}
);

export const selectSelectedInputDenomination = (state: RootState) =>
	state.futures.selectedInputDenomination;

export const selectIsolatedMarginFee = (state: RootState) =>
	wei(state.futures.isolatedMargin.tradeFee);

export const selectTradeSizeInputs = createSelector(
	selectIsolatedMarginTradeInputs,
	(isolatedInputs) => {
		return isolatedInputs;
	}
);

export const selectIsolatedMarginLeverage = createSelector(
	selectPosition,
	selectIsolatedMarginTradeInputs,
	(position, { susdSize }) => {
		const remainingMargin = position?.position?.actualMargin;
		if (!remainingMargin || remainingMargin.eq(0) || !susdSize) return wei(0);
		return susdSize.div(remainingMargin);
	}
);

export const selectPlaceOrderTranslationKey = createSelector(
	selectPosition,
	(state: RootState) => state.futures.isolatedMargin.buttonSelect,
	selectIsMarketCapReached,
	selectIsolatedMarginTradeInputs,
	selectHasTestnetNFT,
	(position, orderType, isMarketCapReached, { susdSizeString }, hasNFT) => {
		let remainingMargin;
		remainingMargin = position?.position?.remainingMargin || zeroBN;

		if (orderType === 'hedge') return 'futures.market.trade.button.hedge';
		if (orderType === 'redeem') return 'futures.market.trade.button.redeem';
		if (!!position?.position) return 'futures.market.trade.button.modify-position';
		if (isMarketCapReached) return 'futures.market.trade.button.oi-caps-reached';
		// if (susdSizeString && !hasNFT) return 'futures.market.trade.button.no-nft';
		return 'futures.market.trade.button.open-position';
	}
);

export const selectFuturesPortfolio = createSelector(
	selectIsolatedMarginPositions,
	(isolatedPositions) => {
		const isolatedValue =
			isolatedPositions.reduce((sum, { position }) => sum.add(position?.actualMargin ?? zeroBN), wei(0)) ?? wei(0);
		const totalValue = isolatedValue;
		return {
			total: totalValue,
			isolatedMarginFutures: isolatedValue,
		};
	}
);

export const selectTradePreview = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		const preview = futures.isolatedMargin.tradePreview;
		return preview ? unserializePotentialTrade(preview) : null;
	}
);

export const selectTradePreviewError = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.isolatedTradePreview.error;
	}
);

export const selectModifyPositionError = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return app.transaction?.type === 'modify_isolated' && app.transaction?.error
			? getKnownError(app.transaction.error)
			: null;
	}
);

export const selectTradePreviewStatus = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.isolatedPositions;
	}
);

export const selectPositionStatus = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.isolatedPositions;
	}
);

export const selectUsersTradesForMarket = createSelector(
	selectFuturesAccount,
	selectMarketAsset,
	selectIsolatedAccountData,
	(account, asset, isolatedAccountData) => {
		let trades;
		if (account) {
			trades = unserializeTrades(isolatedAccountData?.trades ?? []);
		}
		return trades ?? [];
	}
);

export const selectUserDualityForMarket = createSelector(
	selectFuturesAccount,
	selectMarketAsset,
	selectIsolatedAccountData,
	(account, asset, isolatedAccountData) => {
		let duality;
		if (account) {
			duality = unserializeTrades(isolatedAccountData?.duality ?? []);
		}
		return duality ?? [];
	}
);

export const selectHasRemainingMargin = createSelector(selectPosition, (position) => {
	const posMargin = position?.position?.remainingMargin ?? zeroBN;
	return posMargin.gt(0);
});

export const selectMaxUsdInputAmount = createSelector(
	selectButton,
	selectPosition,
	selectMaxLeverage,
	selectFuturesTokenBalance,
	selectMarketPrice,
	(button, position, maxLeverage, assetBalance, assetRate) => {
		const margin = position?.position?.remainingMargin ?? wei(0);
		let maxUsd = maxLeverage.mul(margin);
		maxUsd = maxUsd.gte(0) ? maxUsd : wei(0);
		if (button === PositionSide.SHORT || button === PositionSide.LONG) {
			if (button !== position?.side) {
				return maxUsd.add(position?.position?.notionalValue?.abs() ?? wei(0));
			}
			return maxUsd;
		}
		if (button === 'hedge') {
			if (PositionSide.LONG === position?.side) {
				maxUsd = maxUsd.add(position?.position?.notionalValue?.abs() ?? wei(0));
			}
			return maxUsd.div(maxLeverage);
		}
		if (button === 'redeem') {
			return assetBalance.mul(assetRate).div(SAFETY_CONVERSION_MARGIN);
		}
		return wei(0);
	}
);

export const selectPreviewAvailableMargin = createSelector(
	selectMarketInfo,
	selectTradePreview,
	(marketInfo, tradePreview) => {
		if (!marketInfo || !tradePreview) return zeroBN;

		let inaccessible = tradePreview.notionalValue.div(marketInfo.maxLeverage).abs() ?? zeroBN;
		const totalDeposit = zeroBN;

		// If the user has a position open, we'll enforce a min initial margin requirement.
		if (inaccessible.gt(0) && inaccessible.lt(marketInfo.minInitialMargin)) {
			inaccessible = marketInfo.minInitialMargin;
		}

		// check if available margin will be less than 0
		return tradePreview.margin.sub(inaccessible).sub(totalDeposit).gt(0)
			? tradePreview.margin.sub(inaccessible).sub(totalDeposit).abs()
			: zeroBN;
	}
);

export const selectAverageEntryPrice = createSelector(
	selectTradePreview,
	selectSelectedMarketPositionHistory,
	(tradePreview, positionHistory) => {
		if (positionHistory && tradePreview) {
			const { avgEntryPrice, side, size } = positionHistory;
			const currentSize = side === PositionSide.SHORT ? size.neg() : size;

			// If the trade switched sides (long -> short or short -> long), use oracle price
			if (currentSize.mul(tradePreview.size).lt(0)) return tradePreview.price;

			// If the trade reduced position size on the same side, average entry remains the same
			if (tradePreview.size.abs().lt(size)) return avgEntryPrice;

			// If the trade increased position size on the same side, calculate new average
			const existingValue = avgEntryPrice.mul(size);
			const newValue = tradePreview.price.mul(tradePreview.sizeDelta.abs());
			const totalValue = existingValue.add(newValue);
			return totalValue.div(tradePreview.size.abs());
		}
		return null;
	}
);

type PositionPreviewData = {
	fillPrice: Wei;
	sizeIsNotZero: boolean;
	positionSide: string;
	positionSize: Wei;
	leverage: Wei;
	liquidationPrice: Wei;
	avgEntryPrice: Wei;
	notionalValue: Wei;
	showStatus: boolean;
};

export const selectPreviewData = createSelector(
	selectTradePreview,
	selectPosition,
	selectAverageEntryPrice,
	(tradePreview, position, modifiedAverage) => {
		if (!position?.position || tradePreview === null) {
			return {} as PositionPreviewData;
		}

		return {
			fillPrice: tradePreview.price,
			sizeIsNotZero: tradePreview.size && !tradePreview.size?.eq(0),
			positionSide: tradePreview.size?.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
			positionSize: tradePreview.size?.abs(),
			notionalValue: tradePreview.notionalValue,
			leverage: tradePreview.margin.gt(0)
				? tradePreview.notionalValue.div(tradePreview.margin).abs()
				: zeroBN,
			liquidationPrice: tradePreview.liqPrice,
			avgEntryPrice: modifiedAverage || zeroBN,
			showStatus: tradePreview.showStatus,
		} as PositionPreviewData;
	}
);

export const selectPreviewTradeData = createSelector(
	selectTradePreview,
	selectPreviewAvailableMargin,
	selectMarketInfo,
	(tradePreview, previewAvailableMargin, marketInfo) => {
		const potentialMarginUsage = tradePreview?.margin.gt(0)
			? tradePreview!.margin.sub(previewAvailableMargin).div(tradePreview!.margin).abs() ?? zeroBN
			: zeroBN;

		const maxPositionSize =
			!!tradePreview && !!marketInfo
				? tradePreview.margin
						.mul(marketInfo.maxLeverage)
						.mul(tradePreview.side === PositionSide.LONG ? 1 : -1)
				: null;

		const potentialBuyingPower = !!maxPositionSize
			? maxPositionSize.sub(tradePreview?.notionalValue).abs()
			: zeroBN;

		return {
			showPreview: !!tradePreview && tradePreview.sizeDelta.abs().gt(0),
			totalMargin: tradePreview?.margin || zeroBN,
			availableMargin: previewAvailableMargin.gt(0) ? previewAvailableMargin : zeroBN,
			buyingPower: potentialBuyingPower.gt(0) ? potentialBuyingPower : zeroBN,
			marginUsage: potentialMarginUsage.gt(1) ? wei(1) : potentialMarginUsage,
		};
	}
);

export const selectBuyingPower = createSelector(
	selectPosition,
	selectMaxLeverage,
	(position, maxLeverage) => {
		const totalMargin = position?.position?.remainingMargin ?? zeroBN;
		return totalMargin.gt(zeroBN) ? totalMargin.mul(maxLeverage ?? zeroBN) : zeroBN;
	}
);

export const selectMarginUsage = createSelector(
	selectAvailableMargin,
	selectPosition,
	(availableMargin, position) => {
		const totalMargin = position?.position?.remainingMargin ?? zeroBN;
		return availableMargin.gt(zeroBN)
			? totalMargin.sub(availableMargin).div(totalMargin)
			: totalMargin.gt(zeroBN)
			? wei(1)
			: zeroBN;
	}
);

export const selectMarketSuspended = createSelector(
	selectMarketInfo,
	(marketInfo) => marketInfo?.isSuspended
);

export const selectAllowance = createSelector(
	(state: RootState) => state.futures.allowance,
	(allowance) => {
		return allowance;
	}
);
