import { TransactionNotifier as BaseTN } from '@synthetixio/transaction-notifier';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';

import { NetworkId } from 'sdk/types/common';
import { sdk } from 'state/config';
import { useAppDispatch } from 'state/hooks';
import { setSigner } from 'state/wallet/actions';
import { setNetwork } from 'state/wallet/reducer';

import { generateExplorerFunctions, getBaseUrl } from './blockExplorer';
import { MAIN_CHAIN, wagmiClient } from './config';

export let transactionNotifier = new BaseTN(wagmiClient.provider);
export let blockExplorer = generateExplorerFunctions(getBaseUrl(10));

const useConnector = () => {
	const dispatch = useAppDispatch();
	const { chain: activeChain } = useNetwork();
	const { address, isConnected: isWalletConnected } = useAccount({
		onDisconnect: () => dispatch(setSigner(null)),
	});
	const [providerReady, setProviderReady] = useState(false);

	const DEFAULT_CHAIN = MAIN_CHAIN;

	const network = activeChain ?? DEFAULT_CHAIN;
	// const network = useMemo(() => {
	// 	return activeChainIds.includes(activeChain?.id ?? DEFAULT_CHAIN.id)
	// 		? activeChain ?? DEFAULT_CHAIN
	// 		: DEFAULT_CHAIN;
	// }, [activeChain]);

	const walletAddress = useMemo(() => address ?? null, [address]);

	const provider = useProvider({ chainId: network.id });
	const { data: signer } = useSigner();

	const handleNetworkChange = useCallback(
		(networkId: NetworkId) => {
			dispatch(setNetwork(networkId));
			blockExplorer = generateExplorerFunctions(getBaseUrl(networkId));
		},
		[dispatch]
	);

	useEffect(() => {
		if (!!provider) {
			sdk.setProvider(provider).then((networkId) => {
				handleNetworkChange(networkId);
				setProviderReady(true);
			});
			transactionNotifier = new BaseTN(provider);
		}
	}, [provider, handleNetworkChange]);

	useEffect(() => {
		dispatch(setSigner(signer));
	}, [signer, dispatch]);

	return {
		activeChain,
		isWalletConnected,
		walletAddress,
		provider,
		signer,
		network,
		providerReady,
	};
};

const Connector = createContainer(useConnector);

export default Connector;
