/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  TestnetExchange,
  TestnetExchangeInterface,
} from "../TestnetExchange";

const _abi = [
  {
    type: "function",
    name: "checkMargin",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "trader",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "duality",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "exactUsd",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "amountSpecified",
        type: "int256",
        internalType: "int256",
      },
    ],
    outputs: [
      {
        name: "cost",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "position",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "exchange",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "buy",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "amountSpecified",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "sqrtPriceLimitX96",
        type: "uint160",
        internalType: "uint160",
      },
    ],
    outputs: [
      {
        name: "cost",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "position",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "exchangeWithUpdate",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "buy",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "amountSpecified",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "sqrtPriceLimitX96",
        type: "uint160",
        internalType: "uint160",
      },
      {
        name: "priceUpdateData",
        type: "bytes[]",
        internalType: "bytes[]",
      },
    ],
    outputs: [
      {
        name: "cost",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "position",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "fundingRate",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "newTotalFundingRateX96",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "fundingRateUpdate",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "getData",
    inputs: [
      {
        name: "tokenAddresses",
        type: "address[]",
        internalType: "address[]",
      },
    ],
    outputs: [
      {
        name: "",
        type: "tuple[]",
        internalType: "struct ITestnetExchange.QueryData[]",
        components: [
          {
            name: "price",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "fundingRate",
            type: "int256",
            internalType: "int256",
          },
          {
            name: "openLongInterest",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "openShortInterest",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getMargin",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "trader",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "totalMargin",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getPositionInfo",
    inputs: [
      {
        name: "tokenAddresses",
        type: "address[]",
        internalType: "address[]",
      },
      {
        name: "trader",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "tuple[]",
        internalType: "struct ITestnetExchange.PositionData[]",
        components: [
          {
            name: "position",
            type: "int256",
            internalType: "int256",
          },
          {
            name: "margin",
            type: "int256",
            internalType: "int256",
          },
          {
            name: "lastMargin",
            type: "int256",
            internalType: "int256",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "initialize",
    inputs: [
      {
        name: "_requestTimeout",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "_requestAmount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "_influxFactory",
        type: "address",
        internalType: "address",
      },
      {
        name: "_influxManager",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "lastRequest",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "liquidate",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "trader",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "nextRequestTime",
    inputs: [
      {
        name: "requestee",
        type: "address",
        internalType: "address",
      },
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "positions",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "size",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "margin",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "fundingRateX96",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "postMargin",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "reinitialize",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "renounceOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "requestAmount",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "requestTimeout",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "requestTokens",
    inputs: [
      {
        name: "requestee",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "value",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setAccessNFT",
    inputs: [
      {
        name: "_testnetNFT",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "skewRate",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "skewX96",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "state",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "totalFundingRateX96",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "lastTime",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "openLongInterest",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "openShortInterest",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "sync",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "",
        type: "int256",
        internalType: "int256",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "tokenInfo",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "oracle",
        type: "address",
        internalType: "address",
      },
      {
        name: "decimals",
        type: "uint8",
        internalType: "uint8",
      },
      {
        name: "exchange",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "transferOwnership",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "uniswapV3SwapCallback",
    inputs: [
      {
        name: "amount0Delta",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "amount1Delta",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "_data",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "withdrawMargin",
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Duality",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "size",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "cost",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Exchange",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_buy",
        type: "bool",
        indexed: false,
        internalType: "bool",
      },
      {
        name: "_amountSpecified",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "_sqrtPriceLimitX96",
        type: "uint160",
        indexed: false,
        internalType: "uint160",
      },
      {
        name: "_cost",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "_position",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Initialized",
    inputs: [
      {
        name: "version",
        type: "uint8",
        indexed: false,
        internalType: "uint8",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Liquidate",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "size",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "cost",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MarginDeposit",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "deposit",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MarginWithdraw",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "withdraw",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Trade",
    inputs: [
      {
        name: "_user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_tokenAddress",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "_cost",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "_position",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
    ],
    anonymous: false,
  },
];

export class TestnetExchange__factory {
  static readonly abi = _abi;
  static createInterface(): TestnetExchangeInterface {
    return new utils.Interface(_abi) as TestnetExchangeInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TestnetExchange {
    return new Contract(address, _abi, signerOrProvider) as TestnetExchange;
  }
}
