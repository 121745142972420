import { createSlice } from '@reduxjs/toolkit';

import { FuturesMarket } from 'sdk/types/futures';
import { FetchStatus } from 'state/types';

type HomeState = {
	optimismMarkets: FuturesMarket<string>[];
	marketsQueryStatus: FetchStatus;
};

export const HOME_INITIAL_STATE: HomeState = {
	optimismMarkets: [],
	marketsQueryStatus: FetchStatus.Idle,
};

export const homeSlice = createSlice({
	name: 'home',
	initialState: HOME_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {},
});

export default homeSlice.reducer;
