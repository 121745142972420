import InfluxSDK from 'sdk';

import Wei, { wei } from '@synthetixio/wei';
import { ETH_COINGECKO_ADDRESS } from 'constants/currency';
import { BigNumber, ethers } from 'ethers';
import erc20Abi from 'sdk/contracts/abis/ERC20.json';
import { ADDRESSES } from 'sdk/contracts/constants';
import { MarketSymbol, allMarkets } from 'sdk/data/market';
import * as sdkErrors from '../common/errors';
import { DEFAULT_UNISWAP_SLIPPAGE, SLIPPAGE_DECIMALS, UNISWAP_FEE } from 'constants/uniswap';

export default class UniswapService {
	private sdk: InfluxSDK;

	constructor(sdk: InfluxSDK) {
		this.sdk = sdk;
	}

	public getRouterAddress(): string {
		return ADDRESSES.UniswapV3SwapRouter[this.sdk.context.networkId];
	}

	public getTokenAddress(currencyKey: string): string {
		let lookup = allMarkets[currencyKey as MarketSymbol]?.addresses[this.sdk.context.networkId];
		if (!lookup) {
			throw new Error(sdkErrors.BAD_TOKEN);
		}
		if (lookup === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
			// ETH
			lookup = ETH_COINGECKO_ADDRESS;
		}
		return lookup;
	}

	public getTokenDecimals(currencyKey: string): number {
		let lookup = allMarkets[currencyKey as MarketSymbol]?.decimals;
		if (!lookup) {
			throw new Error(sdkErrors.BAD_TOKEN);
		}
		return lookup;
	}

	public async getDeadline(): Promise<ethers.BigNumber> {
		return BigNumber.from(10).pow(10);
	}

	public encodePath(path: string[]) {
		let encoded = '0x';
		for (let i = 0; i < path.length - 1; i++) {
			// 20 byte encoding of the address
			encoded += path[i].slice(2);
			// 3 byte encoding of the fee
			encoded += UNISWAP_FEE.toString(16).padStart(6, '0');
		}
		// encode the final token
		encoded += path[path.length - 1].slice(2);

		return encoded.toLowerCase();
	}

	public async querySwap(
		quoteCurrencyKey: string,
		baseCurrencyKey: string,
		quoteAmount: string,
		baseAmount: string,
		exact: boolean,
		slippagePercent: string | undefined
	): Promise<string> {
		if (quoteCurrencyKey && baseCurrencyKey) {
			if (exact && !quoteAmount) {
				return '';
			}
			if (!exact && !baseAmount) {
				return '';
			}

			const inputAddress = this.getTokenAddress(quoteCurrencyKey);
			const outputAddress = this.getTokenAddress(baseCurrencyKey);
			const USDCAddress = this.getTokenAddress('USDB');

			const quoteDecimals = this.getTokenDecimals(quoteCurrencyKey);
			const baseDecimals = this.getTokenDecimals(baseCurrencyKey);
			const USDCDecimals = this.getTokenDecimals('USDB');

			const double = quoteCurrencyKey !== 'USDB' && baseCurrencyKey !== 'USDB';

			const slippage = wei(slippagePercent ?? DEFAULT_UNISWAP_SLIPPAGE, 4).toBN();

			const quoter = this.sdk.context.contracts.UniswapV3IQuoter?.callStatic;

			if (!quoter) {
				return '';
			}

			if (exact && !double) {
				const quote = (await quoter.quoteExactInputSingle(
					inputAddress,
					outputAddress,
					UNISWAP_FEE,
					wei(quoteAmount, quoteDecimals).toBN(),
					wei(0).toBN()
				)) as ethers.BigNumber;

				return wei(quote, baseDecimals).toString();
			} else if (!exact && !double) {
				const quote = (await quoter.quoteExactOutputSingle(
					inputAddress,
					outputAddress,
					UNISWAP_FEE,
					wei(baseAmount, baseDecimals).toBN(),
					wei(0).toBN()
				)) as ethers.BigNumber;

				const adjustedQuote = quote.mul(SLIPPAGE_DECIMALS.add(slippage)).div(SLIPPAGE_DECIMALS);

				return wei(adjustedQuote, quoteDecimals).toString();
			} else if (exact && double) {
				const path = [inputAddress, USDCAddress, outputAddress];
				const encodedPath = this.encodePath(path);
				const quote = (await quoter.quoteExactInput(
					encodedPath,
					wei(quoteAmount, quoteDecimals).toBN()
				)) as ethers.BigNumber;

				return wei(quote, baseDecimals).toString();
			} else if (!exact && double) {
				const path = [outputAddress, USDCAddress, inputAddress];
				const encodedPath = this.encodePath(path);
				const quote = (await quoter.quoteExactOutput(
					encodedPath,
					wei(baseAmount, baseDecimals).toBN()
				)) as ethers.BigNumber;

				const adjustedQuote = quote.mul(SLIPPAGE_DECIMALS.add(slippage)).div(SLIPPAGE_DECIMALS);

				return wei(adjustedQuote, quoteDecimals).toString();
			}
		}
		return '';
	}

	public async submitSwap(
		quoteCurrencyKey: string,
		baseCurrencyKey: string,
		quoteAmount: string,
		baseAmount: string,
		exact: boolean,
		slippagePercent: string | undefined
	): Promise<string> {
		if (quoteCurrencyKey && baseCurrencyKey) {
			const inputAddress = this.getTokenAddress(quoteCurrencyKey);
			const outputAddress = this.getTokenAddress(baseCurrencyKey);
			const USDCAddress = this.getTokenAddress('USDB');

			const quoteDecimals = this.getTokenDecimals(quoteCurrencyKey);
			const baseDecimals = this.getTokenDecimals(baseCurrencyKey);
			const USDCDecimals = this.getTokenDecimals('USDB');

			const double = quoteCurrencyKey !== 'USDB' && baseCurrencyKey !== 'USDB';

			const slippage = wei(slippagePercent ?? DEFAULT_UNISWAP_SLIPPAGE, 4).toBN();
			// const slippage = BigNumber.from(50000);

			const deadline = await this.getDeadline();

			if (exact && !double) {
				const slippageFraction = SLIPPAGE_DECIMALS.sub(slippage);

				const amountIn = wei(quoteAmount, quoteDecimals).toBN();
				const amountOutMinimum = wei(baseAmount, baseDecimals)
					.toBN()
					.mul(slippageFraction)
					.div(SLIPPAGE_DECIMALS);

				const { hash } = await this.sdk.transactions.createInfluxTxn(
					'UniswapV3SwapRouter',
					'exactInputSingle',
					[
						[
							inputAddress,
							outputAddress,
							UNISWAP_FEE,
							this.sdk.context.walletAddress,
							deadline,
							amountIn,
							amountOutMinimum,
							wei('0').toBN(),
						],
					]
				);
				return hash;
			} else if (!exact && !double) {
				const slippageFraction = SLIPPAGE_DECIMALS.add(slippage);
				const amountInMaximum = wei(quoteAmount, quoteDecimals).toBN();
				const amountOut = wei(baseAmount, baseDecimals).toBN();

				const { hash } = await this.sdk.transactions.createInfluxTxn(
					'UniswapV3SwapRouter',
					'exactOutputSingle',
					[
						[
							inputAddress,
							outputAddress,
							UNISWAP_FEE,
							this.sdk.context.walletAddress,
							deadline,
							amountOut,
							amountInMaximum,
							wei('0').toBN(),
						],
					]
				);
				return hash;
			} else if (exact && double) {
				const slippageFraction = SLIPPAGE_DECIMALS.sub(slippage);

				const amountIn = wei(quoteAmount, quoteDecimals).toBN();
				const amountOutMinimum = wei(baseAmount, baseDecimals)
					.toBN()
					.mul(slippageFraction)
					.div(SLIPPAGE_DECIMALS);

				const path = [inputAddress, USDCAddress, outputAddress];
				const encodedPath = this.encodePath(path);

				const { hash } = await this.sdk.transactions.createInfluxTxn(
					'UniswapV3SwapRouter',
					'exactInput',
					[[encodedPath, this.sdk.context.walletAddress, deadline, amountIn, amountOutMinimum]]
				);
				return hash;
			} else if (!exact && double) {
				const path = [outputAddress, USDCAddress, inputAddress];
				const encodedPath = this.encodePath(path);

				const slippageFraction = SLIPPAGE_DECIMALS.add(slippage);
				const amountInMaximum = wei(quoteAmount, quoteDecimals).toBN();
				const amountOut = wei(baseAmount, baseDecimals).toBN();

				const { hash } = await this.sdk.transactions.createInfluxTxn(
					'UniswapV3SwapRouter',
					'exactOutputSingle',
					[[encodedPath, this.sdk.context.walletAddress, deadline, amountOut, amountInMaximum]]
				);
				return hash;
			}
		}
		return '';
	}

	public async approve(currencyKey: string): Promise<string> {
		if (currencyKey) {
			const tokenAddress = this.getTokenAddress(currencyKey);
			const tokenContract = this.createERC20Contract(tokenAddress);
			const routerAddress = this.getRouterAddress();
			const { hash } = await this.sdk.transactions.createContractTxn(tokenContract, 'approve', [
				routerAddress,
				ethers.constants.MaxUint256,
			]);

			return hash;
		}
		return '';
	}

	public async checkApprove(currencyKey: string): Promise<Wei | undefined> {
		if (currencyKey) {
			const tokenAddress = this.getTokenAddress(currencyKey);
			const tokenDecimals = this.getTokenDecimals(currencyKey);
			const tokenContract = this.createERC20Contract(tokenAddress);
			const routerAddress = this.getRouterAddress();
			const allowance = (await tokenContract.allowance(
				this.sdk.context.walletAddress,
				routerAddress
			)) as ethers.BigNumber;

			return wei(allowance, tokenDecimals);
		}
	}

	private createERC20Contract(tokenAddress: string) {
		return new ethers.Contract(tokenAddress, erc20Abi, this.sdk.context.provider);
	}
}
