import Wei, { wei } from '@synthetixio/wei';
import { BigNumber, ethers } from 'ethers';
import InfluxSDK from 'sdk';

import { ETH_COINGECKO_ADDRESS } from 'constants/currency';
import { APP_MAX_LEVERAGE, FIXED_POINT_X96, FUNDING_RATE_DIVISOR, MAX_SQRT_PRICE, MIN_MARGIN_AMOUNT, MIN_SQRT_PRICE, USD_DECIMALS } from 'constants/futures';
import { UNSUPPORTED_NETWORK } from 'sdk/common/errors';
import {
	MARKETS
} from 'sdk/constants/futures';
import {
	getContractsByNetwork
} from 'sdk/contracts';
import erc20Abi from 'sdk/contracts/abis/ERC20.json';
import { ADDRESSES } from 'sdk/contracts/constants';
import { MarketSymbol, allMarkets } from 'sdk/data/market';
import {
	queryTrades
} from 'sdk/queries/futures';
import { NetworkOverrideOptions } from 'sdk/types/common';
import {
	FuturesMarket,
	FuturesMarketAsset,
	FuturesMarketKey,
	PositionSide
} from 'sdk/types/futures';
import {
	getFuturesEndpoint,
	getMarketName,
	mapTrades,
	marketsForNetwork
} from 'sdk/utils/futures';
import { MarketKeyByAsset } from 'utils/futures';
import * as sdkErrors from '../common/errors';
import pythAbi from 'sdk/contracts/abis/PythOracle.json';

export default class FuturesService {
	private sdk: InfluxSDK;
	public markets: FuturesMarket[] | undefined;

	constructor(sdk: InfluxSDK) {
		this.sdk = sdk;
	}

	get futuresGqlEndpoint() {
		return getFuturesEndpoint(this.sdk.context.networkId);
	}

	public getTokenAddress(currencyKey: string): string {
		let lookup = allMarkets[currencyKey as MarketSymbol]?.addresses[this.sdk.context.networkId];
		if (!lookup) {
			return '';
		}
		if (lookup === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
			// ETH
			lookup = ETH_COINGECKO_ADDRESS;
		}
		return lookup;
	}

	public async getMarkets(networkOverride?: NetworkOverrideOptions) {
		const networkId = networkOverride?.networkId || this.sdk.context.networkId;
		const allMarkets = marketsForNetwork(networkId);
		
		const enabledMarkets = allMarkets.map((market, i) => {
			return {
				...market,
				tokenAddress: this.getTokenAddress(market.asset),
			};
		}).filter((x) => {return x.tokenAddress !== '';});
		const tokenAddressList = enabledMarkets.map((market) => {return market.tokenAddress;});

		const contracts =
			networkOverride && networkOverride?.networkId !== this.sdk.context.networkId
				? getContractsByNetwork(networkOverride.networkId, networkOverride.provider)
				: this.sdk.context.contracts;
		const TestnetExchange = contracts.TestnetExchange;

		if (!TestnetExchange) {
			throw new Error(UNSUPPORTED_NETWORK);
		}

		const data = await TestnetExchange.getData(tokenAddressList);

		const futuresMarkets = enabledMarkets.map(
			({ key, asset, decimals }, i: number): FuturesMarket => {
				// }
				return {
					market: key,
					marketKey: key,
					marketName: getMarketName(asset),
					asset: asset,
					currentFundingRate: wei(data[i].fundingRate, 18).div(FIXED_POINT_X96).div(FUNDING_RATE_DIVISOR),
					price: wei(data[i].price, 18)
						.mul(BigNumber.from(10).pow(decimals + 18 - USD_DECIMALS))
						.div(FIXED_POINT_X96),
					openLongInterest: wei(data[i].openLongInterest, decimals),
					openShortInterest: wei(data[i].openShortInterest, decimals),
					maxLeverage: APP_MAX_LEVERAGE,
					minInitialMargin: MIN_MARGIN_AMOUNT,
					isSuspended: false,
					marketClosureReason: 'system-upgrade',
				};
			}
		);
		return futuresMarkets;
	}

	public async getFuturesPositions(
		address: string, // Cross margin or EOA address
		futuresMarkets: { asset: FuturesMarketAsset; marketKey: FuturesMarketKey; address: string }[]
	) {
		const TestnetExchange = this.sdk.context.contracts.TestnetExchange;

		if (!TestnetExchange) {
			throw new Error(UNSUPPORTED_NETWORK);
		}

		const tokenAddresses = futuresMarkets.map((e) => {
			return this.getTokenAddress(e.asset);
		});

		const data = await TestnetExchange.getPositionInfo(
			tokenAddresses,
			this.sdk.context.walletAddress
		);

		const positions = data.map((p, i) => {
			const decimals = MARKETS[futuresMarkets[i].marketKey].decimals;
			const position = wei(p.position, decimals);

			return {
				margin: wei(p.margin, USD_DECIMALS),
				lastMargin: wei(p.margin, USD_DECIMALS),
				size: position,
				marketKey: futuresMarkets[i].marketKey,
				asset: futuresMarkets[i].asset,
				side: position.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
			};
		});

		return positions;
	}

	// TODO: Support pagination

	public async getTradesForMarket(
		currencyKey: string,
		walletAddress: string,
		query: string,
		pageLength: number = 16
	) {
		const response = await queryTrades(this.sdk, query, {
			currencyKey,
			walletAddress,
			pageLength,
		});
		return response ? mapTrades(response, currencyKey) : [];
	}

	public async getAllTrades(walletAddress: string, query: string, pageLength: number = 16) {
		const response = await queryTrades(this.sdk, query, {
			walletAddress,
			pageLength,
		});
		return response ? mapTrades(response, '__') : [];
	}

	public async checkApprove() {
		const token = 'USDB';
		const tokenAddress = this.getTokenAddress(token);
		const tokenDecimals = USD_DECIMALS;
		const tokenContract = this.createERC20Contract(tokenAddress);
		const exchangeAddress = this.getExchangeAddress();
		const allowance = (await tokenContract.allowance(
			this.sdk.context.walletAddress,
			exchangeAddress
		)) as ethers.BigNumber;

		return wei(allowance, tokenDecimals);
	}

	public getTokenDecimals(currencyKey: string): number {
		let lookup = allMarkets[currencyKey as MarketSymbol]?.decimals;
		if (!lookup) {
			throw new Error(sdkErrors.BAD_TOKEN);
		}
		return lookup;
	}

	// Contract mutations

	public async depositIsolatedMargin(token: FuturesMarketAsset, amount: Wei) {
		const tokenAddress = this.getTokenAddress(token);
		const txn = this.sdk.transactions.createInfluxTxn('TestnetExchange', 'postMargin', [
			tokenAddress,
			amount.toBN(),
		]);
		return txn;
	}

	public async withdrawIsolatedMargin(token: FuturesMarketAsset, amount: Wei) {
		const tokenAddress = this.getTokenAddress(token);
		const txn = this.sdk.transactions.createInfluxTxn('TestnetExchange', 'withdrawMargin', [
			tokenAddress,
			amount.toBN(),
		]);
		return txn;
	}

	public async approve(token: string = 'USDB') {
		const tokenAddress = this.getTokenAddress(token);
		const tokenContract = this.createERC20Contract(tokenAddress);
		const exchangeAddress = this.getExchangeAddress();
		const tx = await this.sdk.transactions.createContractTxn(tokenContract, 'approve', [
			exchangeAddress,
			ethers.constants.MaxUint256,
		]);

		return tx;
	}

	private async initializePythOracle(
		token: FuturesMarketAsset,
		pythAddress: string,
	) {
		const updatePriceData = await this.sdk.prices.getPythPriceUpdateData(MarketKeyByAsset[token]);
		const oracle = this.sdk.context.contracts.IPyth?.callStatic;
		if (!oracle) {
			throw new Error('IPyth oracle not found')
		}
		const getUpdateFee = await oracle.getUpdateFee(updatePriceData);
		const pythContract = new ethers.Contract(pythAddress, pythAbi, this.sdk.context.provider);
		const tx = await this.sdk.transactions.createContractTxn(pythContract, 'updatePrice', [
			updatePriceData
		], {
			value: getUpdateFee
		});
	}

	public async modifyIsolatedMarginPosition(
		token: FuturesMarketAsset,
		buy: boolean,
		amount: string,
		exactIn: boolean
	) {
		const tokenAddress = this.getTokenAddress(token);
		const decimals = buy == exactIn ? USD_DECIMALS : this.getTokenDecimals(token);
		const oracle = this.sdk.context.contracts.IPyth?.callStatic;
		if (!oracle) {
			throw new Error('IPyth oracle not found')
		}
		const updatePriceData = await this.sdk.prices.getPythPriceUpdateData(MarketKeyByAsset[token]);
		const getUpdateFee = await oracle.getUpdateFee(updatePriceData);

		const tx = await this.sdk.transactions.createInfluxTxn('TestnetExchange', 'exchangeWithUpdate', [
			tokenAddress,
			buy,
			exactIn ? wei(amount, decimals).toBN() : wei(amount, decimals).neg().toBN(),
			buy ? MAX_SQRT_PRICE : MIN_SQRT_PRICE,
			updatePriceData
		], {
			value: getUpdateFee
		});
		return tx;
	}

	public async triggerDuality(
		token: FuturesMarketAsset,
		exactUsd: boolean,
		amount: string
	) {
		const tokenAddress = this.getTokenAddress(token);
		const decimals = exactUsd ? USD_DECIMALS : this.getTokenDecimals(token);
		const tx = await this.sdk.transactions.createInfluxTxn('TestnetExchange', 'duality', [
			tokenAddress,
			exactUsd,
			wei(amount, decimals).toBN()
		]);
		return tx;
	}


	private createERC20Contract(tokenAddress: string) {
		return new ethers.Contract(tokenAddress, erc20Abi, this.sdk.context.provider);
	}

	public getExchangeAddress(): string {
		return ADDRESSES.TestnetExchange[this.sdk.context.networkId];
	}
}
