import { createAsyncThunk } from '@reduxjs/toolkit';
import Wei, { wei } from '@synthetixio/wei';
import { BigNumber, ethers } from 'ethers';
import { debounce } from 'lodash';

import { notifyError } from 'components/ErrorView/ErrorNotifier';
import { NetworkId, TransactionStatus } from 'sdk/types/common';
import {
	FuturesButtonType,
	FuturesMarket,
	FuturesPosition,
	FuturesTrade,
	PositionSide
} from 'sdk/types/futures';
import { unserializeGasPrice } from 'state/app/helpers';
import {
	handleTransactionError,
	setOpenModal,
	setTransaction,
	updateTransactionHash,
	updateTransactionStatus,
} from 'state/app/reducer';
import { fetchBalances } from 'state/balances/actions';
import { ZERO_STATE_CM_TRADE_INPUTS } from 'state/constants';
import { serializeWeiObject } from 'state/helpers';
import { selectLatestEthPrice } from 'state/prices/selectors';
import { AppDispatch, AppThunk, RootState } from 'state/store';
import { ThunkConfig } from 'state/types';
import { selectNetwork, selectWallet } from 'state/wallet/selectors';
import { computeMarketFee } from 'utils/costCalculations';
import { stipZeros } from 'utils/formatters/number';
import {
	marketOverrides,
	serializeMarkets,
	serializeTrades
} from 'utils/futures';
import logError from 'utils/logError';
import { getTransactionPrice } from 'utils/network';
import { refetchWithComparator } from 'utils/queries';

import {
	setButtonSelect,
	setIsolatedMarginFee,
	setIsolatedMarginLeverageInput,
	setIsolatedMarginTradeInputs,
	setIsolatedTradePreview,
	setLeverageSide,
	setOrderType,
	setTransactionEstimate
} from './reducer';
import {
	selectFuturesAccount,
	selectFuturesSupportedNetwork,
	selectIsolatedMarginTradeInputs,
	selectMarketAsset,
	selectMarketInfo,
	selectMarketPrice,
	selectMaxLeverage,
	selectMaxUsdInputAmount,
	selectPosition,
	selectTradeSizeInputs
} from './selectors';
import {
	FuturesTransactionType
} from './types';

export const fetchMarkets = createAsyncThunk<
	{ markets: FuturesMarket<string>[] } | undefined,
	void,
	ThunkConfig
>('futures/fetchMarkets', async (_, { getState, extra: { sdk } }) => {
	const supportedNetwork = selectFuturesSupportedNetwork(getState());
	if (!supportedNetwork) return;
	try {
		const markets = await sdk.futures.getMarkets();
		// apply overrides
		const overrideMarkets = markets.map((m) => {
			return marketOverrides[m.marketKey]
				? {
						...m,
						...marketOverrides[m.marketKey],
				  }
				: m;
		});

		const serializedMarkets = serializeMarkets(overrideMarkets);
		return { markets: serializedMarkets };
	} catch (err) {
		logError(err);
		notifyError('Failed to fetch markets', err);
		throw err;
	}
});

export const fetchFuturesPositionsForType = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchFuturesPositionsForType',
	async (_, { dispatch, getState }) => {
		const { futures } = getState();
		dispatch(fetchIsolatedMarginPositions());
	}
);

export const fetchIsolatedMarginPositions = createAsyncThunk<
	{ positions: FuturesPosition<string>[]; wallet: string; network: NetworkId } | undefined,
	void,
	ThunkConfig
>('futures/fetchIsolatedMarginPositions', async (_, { getState, extra: { sdk } }) => {
	const { wallet, futures } = getState();
	const supportedNetwork = selectFuturesSupportedNetwork(getState());
	const network = selectNetwork(getState());

	if (!wallet.walletAddress || !supportedNetwork) return;
	try {
		const positions = await sdk.futures.getFuturesPositions(
			wallet.walletAddress,
			futures.markets.map((m) => ({ asset: m.asset, marketKey: m.marketKey, address: m.market }))
		);
		return {
			positions: positions.map((p) => serializeWeiObject(p) as FuturesPosition<string>),
			wallet: wallet.walletAddress,
			network: network,
		};
	} catch (err) {
		logError(err);
		notifyError('Failed to fetch isolated margin positions', err);
		throw err;
	}
});

export const refetchPosition = createAsyncThunk<
	{
		position: FuturesPosition<string>;
		wallet: string;
		networkId: NetworkId;
	} | null,
	void,
	ThunkConfig
>('futures/refetchPosition', async (_, { getState, dispatch, extra: { sdk } }) => {
	const account = selectFuturesAccount(getState());
	if (!account) throw new Error('No wallet connected');
	const marketInfo = selectMarketInfo(getState());
	const networkId = selectNetwork(getState());
	const position = selectPosition(getState());
	if (!marketInfo || !position) throw new Error('Market or position not found');

	dispatch(queryAllowance());

	const result = await refetchWithComparator(
		() =>
			sdk.futures.getFuturesPositions(account!, [
				{ asset: marketInfo.asset, marketKey: marketInfo.marketKey, address: marketInfo.market },
			]),
		position.margin,
		(existing, next) => {
			return existing === next[0]?.margin.toString();
		}
	);

	if (result.data[0]) {
		const serialized = serializeWeiObject(result.data[0] as FuturesPosition) as FuturesPosition<
			string
		>;
		return { position: serialized, wallet: account, networkId };
	}
	return null;
});

export const fetchIsolatedMarginAccountData = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchIsolatedMarginAccountData',
	async (_, { dispatch }) => {
		dispatch(fetchIsolatedMarginPositions());
	}
);

export const clearTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearTradeInputs',
	async (_, { dispatch }) => {
		dispatch(setIsolatedMarginFee('0'));
		dispatch(setIsolatedMarginLeverageInput(''));
		dispatch(setIsolatedTradePreview(null));
		dispatch(queryAllowance());
	}
);

export const editIsolatedMarginSize = (size: string, currencyType: 'usd' | 'native'): AppThunk => (
	dispatch,
	getState
) => {
	const assetRate = selectMarketPrice(getState());
	const position = selectPosition(getState());
	const maxUsdSize = selectMaxUsdInputAmount(getState());
	const maxLeverage = selectMaxLeverage(getState());
	if (size === '' || assetRate.eq(0) || !position?.margin || position?.margin.eq(0)) {
		dispatch(setIsolatedMarginTradeInputs(ZERO_STATE_CM_TRADE_INPUTS));
		dispatch(setIsolatedTradePreview(null));
		dispatch(setIsolatedMarginLeverageInput(''));
		return;
	}

	const nativeSize = currencyType === 'native' ? size : wei(size).div(assetRate).toString();
	const usdSize = currencyType === 'native' ? stipZeros(assetRate.mul(size).toString()) : size;
	const leverage = maxUsdSize.eq(0) ? '' : maxLeverage.mul(wei(usdSize).div(maxUsdSize)).toString(2);
	dispatch(setIsolatedMarginLeverageInput(leverage));
	dispatch(
		setIsolatedMarginTradeInputs({
			size: size,
			susdSize: usdSize,
			nativeSize,
			usd: currencyType === 'usd',
		})
	);
	dispatch(calculateIsolatedMarginFees());
	debouncedPrepareIsolatedMarginTradePreview(dispatch);
};

export const editTradeSizeInput = (size: string, currencyType: 'usd' | 'native'): AppThunk => (
	dispatch,
	getState
) => {
	dispatch(editIsolatedMarginSize(size, currencyType));
};

export const changeLeverageSide = (side: FuturesButtonType): AppThunk => (dispatch, getState) => {
	const { nativeSizeString } = selectTradeSizeInputs(getState());
	if (side !== 'hedge' && side !== 'redeem') {
		dispatch(setLeverageSide(side));
	}
	dispatch(editTradeSizeInput(nativeSizeString, 'native'));
};

export const changeButtonType = (side: FuturesButtonType): AppThunk => (dispatch, getState) => {
	dispatch(setButtonSelect(side));
}

export const debouncedPrepareIsolatedMarginTradePreview = debounce((dispatch) => {
	dispatch(prepareIsolatedMarginTradePreview());
}, 500);

export const fetchTradesForSelectedMarket = createAsyncThunk<
	| {
			trades: FuturesTrade<string>[];
			account: string;
			wallet: string;
			networkId: NetworkId;
	  }
	| undefined,
	void,
	ThunkConfig
>('futures/fetchTradesForSelectedMarket', async (_, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState());
		const networkId = selectNetwork(getState());
		const marketAsset = selectMarketAsset(getState());
		const account = selectFuturesAccount(getState());
		const futuresSupported = selectFuturesSupportedNetwork(getState());
		if (!futuresSupported || !wallet || !account) return;
		const trades = await sdk.futures.getTradesForMarket(marketAsset, wallet, 'exchanges');
		return { trades: serializeTrades(trades), networkId, account, wallet };
	} catch (err) {
		notifyError('Failed to fetch futures trades', err);
		throw err;
	}
});

export const fetchDualityForSelectedMarket = createAsyncThunk<
	| {
			trades: FuturesTrade<string>[];
			account: string;
			wallet: string;
			networkId: NetworkId;
	  }
	| undefined,
	void,
	ThunkConfig
>('futures/fetchDualityForSelectedMarket', async (_, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState());
		const networkId = selectNetwork(getState());
		const marketAsset = selectMarketAsset(getState());
		const account = selectFuturesAccount(getState());
		const futuresSupported = selectFuturesSupportedNetwork(getState());
		if (!futuresSupported || !wallet || !account) return;
		const trades = await sdk.futures.getTradesForMarket(marketAsset, wallet, 'dualities');
		return { trades: serializeTrades(trades), networkId, account, wallet };
	} catch (err) {
		notifyError('Failed to fetch futures trades', err);
		throw err;
	}
});

export const fetchAllTradesForAccount = createAsyncThunk<
	| {
			trades: FuturesTrade<string>[];
			account: string;
			wallet: string;
			networkId: NetworkId;
	  }
	| undefined,
	void,
	ThunkConfig
>('futures/fetchAllTradesForAccount', async (_, { getState, extra: { sdk } }) => {
	try {
		const wallet = selectWallet(getState());
		const networkId = selectNetwork(getState());
		const account = selectFuturesAccount(getState());
		const futuresSupported = selectFuturesSupportedNetwork(getState());
		if (!futuresSupported || !wallet || !account) return;
		const trades = await sdk.futures.getAllTrades(wallet, 'exchanges');
		return { trades: serializeTrades(trades), networkId, account, wallet };
	} catch (err) {
		notifyError('Failed to fetch futures trades', err);
		throw err;
	}
});

export const calculateIsolatedMarginFees = (): AppThunk => (dispatch, getState) => {
	const market = selectMarketInfo(getState());
	const { susdSize, susdSizeDelta } = selectIsolatedMarginTradeInputs(getState());
	const staticRate = computeMarketFee(market, susdSizeDelta);
	const tradeFee = susdSize.mul(staticRate);
	dispatch(setIsolatedMarginFee(tradeFee.toString()));
};

export const prepareIsolatedMarginTradePreview = createAsyncThunk<void, void, ThunkConfig>(
	'futures/prepareIsolatedMarginTradePreview',
	async (_, { getState, dispatch }) => {
		// const tradeInputs = selectIsolatedMarginTradeInputs(getState());
		// dispatch(fetchIsolatedMarginTradePreview(tradeInputs.nativeSizeDelta));
	}
);

// Contract Mutations

export const depositIsolatedMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositIsolatedMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		if (!marketInfo) throw new Error('Market info not found');
		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_isolated',
					hash: null,
				})
			);
			const tx = await sdk.futures.depositIsolatedMargin(marketInfo.asset, amount);
			await monitorAndAwaitTransaction(dispatch, tx);
			dispatch(setOpenModal(null));
			dispatch(refetchPosition());
			dispatch(fetchBalances());
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
);

export const withdrawIsolatedMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawIsolatedMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		if (!marketInfo) throw new Error('Market info not found');
		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_isolated',
					hash: null,
				})
			);
			const tx = await sdk.futures.withdrawIsolatedMargin(marketInfo.asset, amount);
			await monitorAndAwaitTransaction(dispatch, tx);
			dispatch(refetchPosition());
			dispatch(setOpenModal(null));
			dispatch(fetchBalances());
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
);

export const modifyIsolatedPosition = createAsyncThunk<void, void, ThunkConfig>(
	'futures/modifyIsolatedPosition',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		const { size, usd } = getState().futures.isolatedMargin.tradeInputs;
		const { leverageSide } = getState().futures.isolatedMargin;
		const side = leverageSide === PositionSide.LONG;
		const exactIn = side === usd;
		if (!marketInfo) throw new Error('Market info not found');

		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'modify_isolated',
					hash: null,
				})
			);
			const tx = await sdk.futures.modifyIsolatedMarginPosition(
				marketInfo.asset,
				side,
				size,
				exactIn
			);
			await monitorAndAwaitTransaction(dispatch, tx);
			dispatch(refetchPosition());
			dispatch(setOrderType('market'));
			dispatch(setOpenModal(null));
			dispatch(clearTradeInputs());
			dispatch(fetchBalances());
			dispatch(fetchTradesForSelectedMarket());
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
);

export const activateDuality = createAsyncThunk<void, void, ThunkConfig>(
	'futures/activateDuality',
	async(_, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		const { size, usd } = getState().futures.isolatedMargin.tradeInputs;
		const { buttonSelect } = getState().futures.isolatedMargin;
		const hedge = buttonSelect === 'hedge';
		if (!marketInfo) throw new Error('Market info not found');
		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'duality',
					hash: null,
				})
			);
			const tx = await sdk.futures.triggerDuality(
				marketInfo.asset,
				usd,
				(hedge ? '-' : '') + size
			);
			await monitorAndAwaitTransaction(dispatch, tx);
			dispatch(refetchPosition());
			dispatch(setOrderType('market'));
			dispatch(setOpenModal(null));
			dispatch(clearTradeInputs());
			dispatch(fetchBalances());
			dispatch(fetchDualityForSelectedMarket());
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
)

export const estimateGasInteralAction = async (
	gasLimitEstimate: () => Promise<BigNumber>,
	type: FuturesTransactionType,
	config: {
		getState: () => RootState;
		dispatch: AppDispatch;
	}
) => {
	const { app } = config.getState();
	const ethPrice = selectLatestEthPrice(config.getState());

	try {
		const limit = await gasLimitEstimate();
		const estimate = getTransactionPrice(
			unserializeGasPrice(app.gasPrice),
			limit,
			ethPrice,
			wei(0)
		);

		config.dispatch(
			setTransactionEstimate({
				type: type,
				limit: limit.toString(),
				cost: estimate?.toString() ?? '0',
			})
		);
	} catch (err) {
		config.dispatch(
			setTransactionEstimate({
				type: type,
				limit: '0',
				cost: '0',
				error: err.message,
			})
		);
		throw err;
	}
};

const monitorAndAwaitTransaction = async (
	dispatch: AppDispatch,
	tx: ethers.providers.TransactionResponse
) => {
	dispatch(updateTransactionHash(tx.hash));
	await tx.wait();
	dispatch(updateTransactionStatus(TransactionStatus.Confirmed));
};

export const submitApprove = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitApprove',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		const asset = marketInfo?.asset;
		if (!asset) {
			return;
		}

		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'approve',
					hash: null,
				})
			);
			const tx = await sdk.futures.approve();

			await monitorAndAwaitTransaction(dispatch, tx);
			dispatch({
				type: 'futures/setAllowance',
				payload: wei(ethers.utils.formatEther(ethers.constants.MaxUint256)).toString(),
			});
			dispatch(queryAllowance());
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
);

export const queryAllowance = createAsyncThunk<void, void, ThunkConfig>(
	'futures/queryAllowance',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const allowance = await sdk.futures.checkApprove();

		dispatch({
			type: 'futures/setAllowance',
			payload: allowance?.toString() ?? '',
		});
	}
);

export const submitTokenApprove = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitTokenApprove',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const marketInfo = selectMarketInfo(getState());
		const asset = marketInfo?.asset;
		if (!asset) {
			return;
		}

		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'approve',
					hash: null,
				})
			);
			const tx = await sdk.futures.approve(asset);

			await monitorAndAwaitTransaction(dispatch, tx);
		} catch (err) {
			dispatch(handleTransactionError(err.message));
			throw err;
		}
	}
);