import InfluxSDK from 'sdk';

import { ethers } from 'ethers';
import erc20Abi from 'sdk/contracts/abis/ERC20.json';
import { MarketSymbol, allMarkets } from 'sdk/data/market';
import * as sdkErrors from '../common/errors';

export default class TokenService {
	private sdk: InfluxSDK;

	constructor(sdk: InfluxSDK) {
		this.sdk = sdk;
	}

	public async requestTokens(currencyKey: string) {
		const tokenAddress = this.getTokenAddress(currencyKey);

		const { hash } = await this.sdk.transactions.createInfluxTxn(
			'TestnetExchange',
			'requestTokens',
			[this.sdk.context.walletAddress, tokenAddress]
		);

		return hash;
	}

	public async nextRequestTime(currencyKey: string): Promise<number> {
		const tokenAddress = this.getTokenAddress(currencyKey);
		const value = await this.sdk.context.contracts.TestnetExchange?.nextRequestTime(
			this.sdk.context.walletAddress,
			tokenAddress
		);

		return value ? Number(value.toString()) : 0;
	}

	public getTokenAddress(currencyKey: string): string {
		const lookup = allMarkets[currencyKey as MarketSymbol]?.addresses[this.sdk.context.networkId];
		if (!lookup) {
			throw new Error(sdkErrors.BAD_TOKEN);
		}
		return lookup;
	}

	private createERC20Contract(tokenAddress: string) {
		return new ethers.Contract(tokenAddress, erc20Abi, this.sdk.context.provider);
	}
}
