import { Contract as EthCallContract } from 'ethcall';
import { Contract, ethers } from 'ethers';

import { NetworkId } from 'sdk/types/common';

import TestnetExchangeABI from './abis/TestnetExchange.json';
import { ADDRESSES } from './constants';
import {
	TestnetExchange__factory,
	UniswapV3SwapRouter__factory,
	UniswapV3IQuoter__factory,
	IPyth__factory,
	InviteValidator__factory,
	SynthesisTestnetNFT__factory,
} from './types';

type ContractFactory = {
	connect: (address: string, provider: ethers.providers.Provider) => Contract;
};

export type AllContractsMap = Record<
	ContractName,
	{ addresses: Partial<Record<NetworkId, string>>; Factory: ContractFactory }
>;

export const getContractsByNetwork = (
	networkId: NetworkId,
	provider: ethers.providers.Provider
) => {
	return {
		SynthesisTestnetNFT: ADDRESSES.SynthesisTestnetNFT[networkId]
			? SynthesisTestnetNFT__factory.connect(ADDRESSES.SynthesisTestnetNFT[networkId], provider)
			: undefined,
		InviteValidator: ADDRESSES.InviteValidator[networkId]
			? InviteValidator__factory.connect(ADDRESSES.InviteValidator[networkId], provider)
			: undefined,
		UniswapV3IQuoter: ADDRESSES.UniswapV3IQuoter[networkId]
			? UniswapV3IQuoter__factory.connect(ADDRESSES.UniswapV3IQuoter[networkId], provider)
			: undefined,
		UniswapV3SwapRouter: ADDRESSES.UniswapV3SwapRouter[networkId]
			? UniswapV3SwapRouter__factory.connect(ADDRESSES.UniswapV3SwapRouter[networkId], provider)
			: undefined,
		TestnetExchange: ADDRESSES.TestnetExchange[networkId]
			? TestnetExchange__factory.connect(ADDRESSES.TestnetExchange[networkId], provider)
			: undefined,
		IPyth: ADDRESSES.IPyth[networkId]
			? IPyth__factory.connect(ADDRESSES.IPyth[networkId], provider)
			: undefined,
	};
};

export const getMulticallContractsByNetwork = (networkId: NetworkId) => {
	return {
		TestnetExchange: ADDRESSES.TestnetExchange[networkId]
			? new EthCallContract(ADDRESSES.TestnetExchange[networkId], TestnetExchangeABI)
			: undefined,
	};
};

export type ContractsMap = ReturnType<typeof getContractsByNetwork>;
export type MulticallContractsMap = ReturnType<typeof getMulticallContractsByNetwork>;
export type ContractName = keyof ContractsMap;
