import Wei, { wei } from '@synthetixio/wei';

import { DEFAULT_NETWORK_ID } from 'constants/defaults';
import { get } from 'lodash';
import {
	FuturesTradeResult
} from 'queries/futures/subgraph';
import {
	FUTURES_ENDPOINTS,
	MAINNET_MARKETS,
	MAIN_ENDPOINTS,
	TESTNET_MARKETS
} from 'sdk/constants/futures';
import { SECONDS_PER_DAY } from 'sdk/constants/period';
import { allMarkets } from 'sdk/data/market';
import {
	FundingRateUpdate,
	FuturesMarketAsset,
	FuturesOrderType,
	FuturesOrderTypeDisplay,
	FuturesPotentialTradeDetails,
	FuturesTrade,
	PositionSide,
	PotentialTradeStatus
} from 'sdk/types/futures';
import { zeroBN } from 'utils/formatters/number';
import logError from 'utils/logError';

export const getFuturesEndpoint = (networkId: number): string => {
	switch (networkId) {
		case 5:
			return FUTURES_ENDPOINTS[networkId]
		default:
			return FUTURES_ENDPOINTS[DEFAULT_NETWORK_ID];
	}
};

export const getMainEndpoint = (networkId: number): string => {
	return MAIN_ENDPOINTS[networkId] || MAIN_ENDPOINTS[420];
};

export const calculateFundingRate = (
	minTimestamp: number,
	periodLength: number,
	fundingRates: FundingRateUpdate[],
	assetPrice: Wei,
	currentFundingRate: Wei
): Wei | null => {
	const numUpdates = fundingRates.length;
	if (numUpdates < 2) return null;

	// variables to keep track
	let fundingPaid = wei(0);
	let timeTotal = 0;
	let lastTimestamp = minTimestamp;

	// iterate through funding updates
	for (let ind = 0; ind < numUpdates - 1; ind++) {
		const minFunding = fundingRates[ind];
		const maxFunding = fundingRates[ind + 1];

		const fundingStart = new Wei(minFunding.funding, 18, true);
		const fundingEnd = new Wei(maxFunding.funding, 18, true);

		const fundingDiff = fundingStart.sub(fundingEnd);
		const timeDiff = maxFunding.timestamp - Math.max(minFunding.timestamp, lastTimestamp);
		const timeMax = maxFunding.timestamp - minFunding.timestamp;

		if (timeMax > 0) {
			fundingPaid = fundingPaid.add(fundingDiff.mul(timeDiff).div(timeMax));
			timeTotal += timeDiff;
		}
		lastTimestamp = maxFunding.timestamp;
	}

	// add funding from current rate
	const timeLeft = Math.max(periodLength - timeTotal, 0);
	if (timeLeft > 0) {
		fundingPaid = fundingPaid.add(
			wei(currentFundingRate).mul(timeLeft).div(SECONDS_PER_DAY).mul(assetPrice)
		);
	}

	const fundingRate = fundingPaid.div(assetPrice);
	return fundingRate;
};

export const marketsForNetwork = (networkId: number) => {
	switch (networkId) {
		case 1:
			return MAINNET_MARKETS;
		case 5:
		case 168_587_773:
			return TESTNET_MARKETS;
		default:
			logError(new Error('Futures is not supported on this network.'));
			return [];
	}
};

export const getMarketName = (asset: FuturesMarketAsset | null) => {
	return `${getDisplayAsset(asset)}-PERP`;
};

export const getDisplayAsset = (asset: string | null) => {
	return asset ? (asset[0] === 's' ? asset.slice(1) : asset) : null;
};

export const serializePotentialTrade = (
	preview: FuturesPotentialTradeDetails
): FuturesPotentialTradeDetails<string> => ({
	...preview,
	size: preview.size.toString(),
	sizeDelta: preview.sizeDelta.toString(),
	liqPrice: preview.liqPrice.toString(),
	margin: preview.margin.toString(),
	price: preview.price.toString(),
	fee: preview.fee.toString(),
	leverage: preview.leverage.toString(),
	notionalValue: preview.notionalValue.toString(),
	priceImpact: preview.priceImpact.toString(),
	slippageAmount: preview.slippageAmount.toString(),
});

export const unserializePotentialTrade = (
	preview: FuturesPotentialTradeDetails<string>
): FuturesPotentialTradeDetails => ({
	...preview,
	size: wei(preview.size),
	sizeDelta: wei(preview.sizeDelta),
	liqPrice: wei(preview.liqPrice),
	margin: wei(preview.margin),
	price: wei(preview.price),
	fee: wei(preview.fee),
	leverage: wei(preview.leverage),
	notionalValue: wei(preview.notionalValue),
	priceImpact: wei(preview.priceImpact),
	slippageAmount: wei(preview.slippageAmount),
});

const SUCCESS = 'Success';
const UNKNOWN = 'Unknown';

export const getTradeStatusMessage = (status: PotentialTradeStatus): string => {
	if (typeof status !== 'number') {
		return UNKNOWN;
	}

	if (status === 0) {
		return SUCCESS;
	} else if (PotentialTradeStatus[status]) {
		return POTENTIAL_TRADE_STATUS_TO_MESSAGE[PotentialTradeStatus[status]];
	} else {
		return UNKNOWN;
	}
};

export const POTENTIAL_TRADE_STATUS_TO_MESSAGE: { [key: string]: string } = {
	OK: 'Ok',
	INVALID_PRICE: 'Invalid price',
	INVALID_ORDER_PRICE: 'Invalid order price',
	PRICE_OUT_OF_BOUNDS: 'Price out of acceptable range',
	CAN_LIQUIDATE: 'Position can be liquidated',
	CANNOT_LIQUIDATE: 'Position cannot be liquidated',
	MAX_MARKET_SIZE_EXCEEDED: 'Open interest limit exceeded',
	MAX_LEVERAGE_EXCEEDED: 'Max leverage exceeded',
	INSUFFICIENT_MARGIN: 'Insufficient margin',
	NOT_PERMITTED: 'Not permitted by this address',
	NO_POSITION_OPEN: 'No position open',
	PRICE_TOO_VOLATILE: 'Price too volatile',
	PRICE_IMPACT_TOLERANCE_EXCEEDED: 'Price impact tolerance exceeded',
	INSUFFICIENT_FREE_MARGIN: 'Insufficient free margin',
};

export const getPythNetworkUrl = (isMainnet: boolean) => {
	return 'https://hermes.pyth.network';
};

export const normalizePythId = (id: string) => (id.startsWith('0x') ? id : '0x' + id);

export const OrderNameByType: Record<FuturesOrderType, FuturesOrderTypeDisplay> = {
	market: 'Market',
	delayed: 'Delayed',
	delayed_offchain: 'Delayed Market',
};

export const mapTrades = (futuresTrades: FuturesTradeResult[], currencyKey: string): FuturesTrade[] => {
	return futuresTrades.map(
		({
			id,
			blockTimestamp,
			_user,
			_tokenAddress,
			_cost,
			_position,
		}) => {
			const decimals = get(allMarkets, [currencyKey, 'decimals'], 18);

			let cost = new Wei(_cost, 18).mul(wei(10).pow(12));
			let position = (new Wei(_position, 18, true)).neg().mul(wei(10).pow(18 - decimals));
			let price = position.eq(0) ? zeroBN : cost.div(position).mul(wei(10).pow(18));
			return {
				asset: _tokenAddress,
				size: position,
				price,
				txnHash: id.slice(0, -8),
				timestamp: blockTimestamp,
				positionSize: cost,
				side: position.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
			};
		}
	);
};
