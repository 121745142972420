import Wei, { wei } from '@synthetixio/wei';
import { TFunction } from 'i18next';

import {
	FuturesMarket,
	FuturesMarketAsset,
	FuturesMarketKey,
	FuturesOrder,
	FuturesPosition,
	FuturesPositionHistory,
	FuturesTrade,
	FuturesVolumes,
	PositionSide
} from 'sdk/types/futures';
import { PricesMap } from 'sdk/types/prices';
import {
	FundingRate,
	IsolatedMarginTradeInputs,
	MarkPrices,
	TransactionEstimation,
	futuresPositionKeys
} from 'state/futures/types';
import { deserializeWeiObject } from 'state/helpers';

import { APP_MAX_LEVERAGE } from 'constants/futures';
import { zeroBN } from './formatters/number';

export const getMarketName = (asset: FuturesMarketAsset) => {
	return `${getDisplayAsset(asset)}-USDB`;
};

export const getDisplayAsset = (asset: string | null) => {
	return asset ? (asset[0] === 's' ? asset.slice(1) : asset) : null;
};

export const getTokenDescription = (asset: FuturesMarketAsset, t: TFunction) => {
	const assetDisplayName = AssetDisplayByAsset[asset];
	return t('common.currency.futures-market-short-name', {
		currencyName: assetDisplayName,
	});
};

export const getDefaultDecimals = (marketKeyorAsset: string | undefined | null): number => {
	switch(marketKeyorAsset) {
		case 'DOGE':
			return 5;
		case 'BONK':
			return 8;
		case 'SHIB':
			return 8;
		case 'PEPE':
			return 9;
		default:
			return 2;
	}
}

export const MarketAssetByKey: Record<FuturesMarketKey, FuturesMarketAsset> = {
	[FuturesMarketKey.BTCPERP]: FuturesMarketAsset.BTC,
	[FuturesMarketKey.ETHPERP]: FuturesMarketAsset.ETH,
	[FuturesMarketKey.SOLPERP]: FuturesMarketAsset.SOL,
	[FuturesMarketKey.DOGEPERP]: FuturesMarketAsset.DOGE,
	[FuturesMarketKey.BNBPERP]: FuturesMarketAsset.BNB,
	[FuturesMarketKey.AVAXPERP]: FuturesMarketAsset.AVAX,
	[FuturesMarketKey.UNIPERP]: FuturesMarketAsset.UNI,
	[FuturesMarketKey.ADAPERP]: FuturesMarketAsset.ADA,
	[FuturesMarketKey.BLURPERP]: FuturesMarketAsset.BLUR,
	[FuturesMarketKey.SEIPERP]: FuturesMarketAsset.SEI,
	[FuturesMarketKey.SUIPERP]: FuturesMarketAsset.SUI,
	[FuturesMarketKey.SHIBPERP]: FuturesMarketAsset.SHIB,
	[FuturesMarketKey.APTPERP]: FuturesMarketAsset.APT,
	[FuturesMarketKey.INJPERP]: FuturesMarketAsset.INJ,
	[FuturesMarketKey.LINKPERP]: FuturesMarketAsset.LINK,
	[FuturesMarketKey.PEPEPERP]: FuturesMarketAsset.PEPE,
	[FuturesMarketKey.APEPERP]: FuturesMarketAsset.APE,
	[FuturesMarketKey.BONKPERP]: FuturesMarketAsset.BONK,
	[FuturesMarketKey.SNXPERP]: FuturesMarketAsset.SNX,
	[FuturesMarketKey.MEMEPERP]: FuturesMarketAsset.MEME,
	[FuturesMarketKey.FLOKIPERP]: FuturesMarketAsset.FLOKI,
} as const;

export const MarketKeyByAsset: Record<FuturesMarketAsset, FuturesMarketKey> = {
	[FuturesMarketAsset.BTC]: FuturesMarketKey.BTCPERP,
	[FuturesMarketAsset.ETH]: FuturesMarketKey.ETHPERP,
	[FuturesMarketAsset.SOL]: FuturesMarketKey.SOLPERP,
	[FuturesMarketAsset.DOGE]: FuturesMarketKey.DOGEPERP,
	[FuturesMarketAsset.BNB]: FuturesMarketKey.BNBPERP,
	[FuturesMarketAsset.AVAX]: FuturesMarketKey.AVAXPERP,
	[FuturesMarketAsset.UNI]: FuturesMarketKey.UNIPERP,
	[FuturesMarketAsset.ADA]: FuturesMarketKey.ADAPERP,
	[FuturesMarketAsset.BLUR]: FuturesMarketKey.BLURPERP,
	[FuturesMarketAsset.SEI]: FuturesMarketKey.SEIPERP,
	[FuturesMarketAsset.SUI]: FuturesMarketKey.SUIPERP,
	[FuturesMarketAsset.SHIB]: FuturesMarketKey.SHIBPERP,
	[FuturesMarketAsset.APT]: FuturesMarketKey.APTPERP,
	[FuturesMarketAsset.INJ]: FuturesMarketKey.INJPERP,
	[FuturesMarketAsset.LINK]: FuturesMarketKey.LINKPERP,
	[FuturesMarketAsset.PEPE]: FuturesMarketKey.PEPEPERP,
	[FuturesMarketAsset.APE]: FuturesMarketKey.APEPERP,
	[FuturesMarketAsset.BONK]: FuturesMarketKey.BONKPERP,
	[FuturesMarketAsset.SNX]: FuturesMarketKey.SNXPERP,
	[FuturesMarketAsset.MEME]: FuturesMarketKey.MEMEPERP,
	[FuturesMarketAsset.FLOKI]: FuturesMarketKey.FLOKIPERP,
	
} as const;

export const AssetDisplayByAsset: Record<FuturesMarketAsset, string> = {
	[FuturesMarketAsset.BTC]: 'Bitcoin',
	[FuturesMarketAsset.ETH]: 'Ether',
	[FuturesMarketAsset.SOL]: 'Solana',
	[FuturesMarketAsset.DOGE]: 'Dogecoin',
	[FuturesMarketAsset.BNB]: 'Binance Coin',
	[FuturesMarketAsset.AVAX]: 'Avalanche',
	[FuturesMarketAsset.UNI]: 'Uniswap',
	[FuturesMarketAsset.ADA]: 'Cardano',
	[FuturesMarketAsset.BLUR]: 'Blur',
	[FuturesMarketAsset.SEI]: 'Sei',
	[FuturesMarketAsset.SUI]: 'Sui',
	[FuturesMarketAsset.SHIB]: 'Shiba Inu',
	[FuturesMarketAsset.APT]: 'Aptos',
	[FuturesMarketAsset.INJ]: 'Injective',
	[FuturesMarketAsset.LINK]: 'Chainlink',
	[FuturesMarketAsset.PEPE]: 'Pepe',
	[FuturesMarketAsset.APE]: 'Apecoin',
	[FuturesMarketAsset.BONK]: 'Bonk',
	[FuturesMarketAsset.SNX]: 'Synthetix',
	[FuturesMarketAsset.MEME]: 'Memecoin',
	[FuturesMarketAsset.FLOKI]: 'Floki',
} as const;

export const marketOverrides: Partial<Record<FuturesMarketKey, Record<string, any>>> = {
	[FuturesMarketKey.ETHPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.BTCPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.SOLPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.DOGEPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.BNBPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.AVAXPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.UNIPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.ADAPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.BLURPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.SEIPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.SUIPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.SHIBPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.APTPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.INJPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.LINKPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.PEPEPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.APEPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.BONKPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.SNXPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.MEMEPERP]: {
		maxLeverage: wei(25),
	},
	[FuturesMarketKey.FLOKIPERP]: {
		maxLeverage: wei(25),
	}
};

const getPositionChangeState = (existingSize: Wei, newSize: Wei) => {
	if (newSize.eq(0)) return 'closing';
	if (existingSize.eq(newSize)) return 'edit_leverage';
	if (existingSize.eq(0)) return 'increase_size';
	if ((existingSize.gt(0) && newSize.lt(0)) || (existingSize.lt(0) && newSize.gt(0)))
		return 'flip_side';
	if (
		(existingSize.gt(0) && newSize.gt(existingSize)) ||
		(existingSize.lt(0) && newSize.lt(existingSize))
	)
		return 'increase_size';
	return 'reduce_size';
};

export const updatePositionUpnl = (
	positionDetails: FuturesPosition<string>,
	prices: MarkPrices
): FuturesPosition => {
	const deserializedPositionDetails = deserializeWeiObject(
		positionDetails,
		futuresPositionKeys
	) as FuturesPosition;
	const offChainPrice = prices[MarketKeyByAsset[deserializedPositionDetails.asset]];
	const pos = deserializedPositionDetails;

	const notionalValue = pos.size.mul(offChainPrice);
	const actualMargin = pos.margin.add(notionalValue);
	const lockedMargin = notionalValue.div(APP_MAX_LEVERAGE);
	const remainingMargin = actualMargin.sub(lockedMargin.abs());
	const leverage = actualMargin.eq(0) ? actualMargin : notionalValue.div(actualMargin);
	let liquidationPrice;
	if (!offChainPrice || pos.size.eq(0)) {
		liquidationPrice = zeroBN;
	} else {
		liquidationPrice = offChainPrice.sub(actualMargin.div(pos.size));
	}
	if (liquidationPrice.lt(0)) {
		liquidationPrice = zeroBN;
	}
	return {
		...deserializedPositionDetails,
		position: {
			canLiquidatePosition: remainingMargin.lte(0),
			notionalValue,
			side: pos.size.gte(0) ? PositionSide.LONG : PositionSide.SHORT,
			accruedFunding: pos.margin.sub(pos.lastMargin),
			leverage,
			remainingMargin,
			actualMargin,
			liquidationPrice,
		},
	};
	// const pnl =
	// 	!!thisPositionHistory && !!position && !!offChainPrice
	// 		? position.size.mul(
	// 				thisPositionHistory.avgEntryPrice
	// 					.sub(offChainPrice)
	// 					.mul(position.side === PositionSide.LONG ? -1 : 1)
	// 		  )
	// 		: undefined;
	// const pnlPct = pnl?.div(position?.initialMargin);

	// return {
	// 	...deserializedPositionDetails,
	// 	position:
	// 		!!position && !!pnl && !!pnlPct
	// 			? {
	// 					...position,
	// 					pnl,
	// 					pnlPct,
	// 			  }
	// 			: position,
	// };
};

export const serializeMarket = (market: FuturesMarket): FuturesMarket<string> => {
	return {
		...market,
		price: market.price.toString(),
		currentFundingRate: market.currentFundingRate.toString(),
		maxLeverage: market.maxLeverage.toString(),
		minInitialMargin: market.minInitialMargin.toString(),
		openLongInterest: market.openLongInterest.toString(),
		openShortInterest: market.openShortInterest.toString(),
	};
};

export const serializeMarkets = (markets: FuturesMarket[]): FuturesMarket<string>[] => {
	return markets.map((m) => serializeMarket(m));
};

export const unserializeMarkets = (markets: FuturesMarket<string>[]): FuturesMarket[] => {
	return markets.map((m) => ({
		...m,
		price: wei(m.price),
		currentFundingRate: wei(m.currentFundingRate),
		maxLeverage: wei(m.maxLeverage),
		minInitialMargin: wei(m.minInitialMargin),
		openLongInterest: wei(m.openLongInterest),
		openShortInterest: wei(m.openShortInterest),
	}));
};

export const serializeFuturesVolumes = (volumes: FuturesVolumes) => {
	return Object.keys(volumes).reduce<FuturesVolumes<string>>((acc, k) => {
		acc[k] = {
			trades: volumes[k].trades.toString(),
			volume: volumes[k].volume.toString(),
		};
		return acc;
	}, {});
};

export const unserializeFuturesVolumes = (volumes: FuturesVolumes<string>) => {
	return Object.keys(volumes).reduce<FuturesVolumes>((acc, k) => {
		acc[k] = {
			trades: wei(volumes[k].trades),
			volume: wei(volumes[k].volume),
		};
		return acc;
	}, {});
};

export const unserializeIsolatedMarginTradeInputs = (
	tradeInputs: IsolatedMarginTradeInputs<string>
): IsolatedMarginTradeInputs => {
	return {
		size: wei(tradeInputs.size || 0),
		susdSize: wei(tradeInputs.susdSize || 0),
		nativeSize: wei(tradeInputs.nativeSize || 0),
		usd: tradeInputs.usd,
	};
};

export const serializeFuturesOrders = (orders: FuturesOrder[]): FuturesOrder<string>[] => {
	return orders.map((o) => ({
		...o,
		size: o.size.toString(),
		targetPrice: o.targetPrice?.toString() ?? null,
		marginDelta: o.marginDelta.toString(),
		targetRoundId: o.targetRoundId?.toString() ?? null,
	}));
};

export const unserializeFuturesOrders = (orders: FuturesOrder<string>[]): FuturesOrder[] => {
	return orders.map((o) => ({
		...o,
		size: wei(o.size),
		targetPrice: o.targetPrice ? wei(o.targetPrice) : null,
		marginDelta: wei(o.marginDelta),
		targetRoundId: o.targetRoundId ? wei(o.targetRoundId) : null,
	}));
};

export const unserializeGasEstimate = (
	estimate: TransactionEstimation<string>
): TransactionEstimation => ({
	...estimate,
	limit: wei(estimate.limit),
	cost: wei(estimate.cost),
});

export const serializePrices = (prices: PricesMap) => {
	return Object.entries(prices).reduce<PricesMap<string>>((acc, [key, price]) => {
		acc[key as FuturesMarketAsset] = price.toString();
		return acc;
	}, {});
};

export const serializePositionHistory = (
	positions: FuturesPositionHistory[]
): FuturesPositionHistory<string>[] => {
	return positions.map((p) => ({
		...p,
		size: p.size.toString(),
		feesPaid: p.feesPaid.toString(),
		netFunding: p.netFunding.toString(),
		netTransfers: p.netTransfers.toString(),
		totalDeposits: p.totalDeposits.toString(),
		initialMargin: p.initialMargin.toString(),
		margin: p.margin.toString(),
		entryPrice: p.entryPrice.toString(),
		exitPrice: p.exitPrice.toString(),
		pnl: p.pnl.toString(),
		pnlWithFeesPaid: p.pnlWithFeesPaid.toString(),
		totalVolume: p.totalVolume.toString(),
		avgEntryPrice: p.avgEntryPrice.toString(),
		leverage: p.leverage.toString(),
	}));
};

export const unserializePositionHistory = (
	positions: FuturesPositionHistory<string>[]
): FuturesPositionHistory[] => {
	return positions.map((p) => ({
		...p,
		size: wei(p.size),
		feesPaid: wei(p.feesPaid),
		netFunding: wei(p.netFunding),
		netTransfers: wei(p.netTransfers),
		totalDeposits: wei(p.totalDeposits),
		initialMargin: wei(p.initialMargin),
		margin: wei(p.margin),
		entryPrice: wei(p.entryPrice),
		exitPrice: wei(p.exitPrice),
		pnl: wei(p.pnl),
		pnlWithFeesPaid: wei(p.pnlWithFeesPaid),
		totalVolume: wei(p.totalVolume),
		avgEntryPrice: wei(p.avgEntryPrice),
		leverage: wei(p.leverage),
	}));
};

export const serializeTrades = (trades: FuturesTrade[]): FuturesTrade<string>[] => {
	return trades.map((t) => ({
		...t,
		size: t.size.toString(),
		price: t.price.toString(),
		timestamp: t.timestamp.toString(),
		positionSize: t.positionSize.toString(),
	}));
};

export const unserializeTrades = (trades: FuturesTrade<string>[]): FuturesTrade<Wei>[] => {
	return trades.map((t) => ({
		...t,
		size: wei(t.size),
		price: wei(t.price),
		timestamp: wei(t.timestamp),
		positionSize: wei(t.positionSize),
	}));
};

export const unserializeFundingRates = (rates: FundingRate<string>[]): FundingRate[] => {
	return rates.map((r) => ({ ...r, fundingRate: r.fundingRate ? wei(r.fundingRate) : null }));
};
