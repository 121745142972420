import { formatBytes32String } from 'ethers/lib/utils.js';
import { gql } from 'graphql-request';

import {
	FuturesMarketAsset,
	FuturesMarketConfig,
	FuturesMarketKey,
} from 'sdk/types/futures';

export const FUTURES_ENDPOINT_OP_MAINNET = `https://subgraph.satsuma-prod.com/05943208e921/kwenta/optimism-perps/api`;

export const FUTURES_ENDPOINT_GOERLI = "https://api.thegraph.com/subgraphs/name/nreddy-eterna/eterna-goerli-testnet";

export const FUTURES_ENDPOINT_OP_GOERLI =
	'https://api.thegraph.com/subgraphs/name/tburm/optimism-goerli-perps';

export const FUTURES_ENDPOINT_BLAST_SEPOLIA = 
	"https://api.studio.thegraph.com/query/64223/eterna-testnet/version/latest";

export const MAIN_ENDPOINT_MAINNET = `https://gateway.thegraph.com/api/${process.env.NEXT_PUBLIC_THEGRAPH_API_KEY}/subgraphs/id/HLy7PdmPJuVGjjmPNz1vW5RCCRpqzRWony2fSn7UKpf9`;

export const MAIN_ENDPOINT_OP_MAINNET =
	'https://api.thegraph.com/subgraphs/name/kwenta/optimism-main';

export const MAIN_ENDPOINT_OP_GOERLI =
	'https://api.thegraph.com/subgraphs/name/kwenta/optimism-goerli-main';


export const FUTURES_ENDPOINTS: Record<number, string> = {
	5: FUTURES_ENDPOINT_GOERLI,
	10: FUTURES_ENDPOINT_OP_MAINNET,
	420: FUTURES_ENDPOINT_OP_GOERLI,
	168_587_773: FUTURES_ENDPOINT_BLAST_SEPOLIA,
};

export const MAIN_ENDPOINTS: Record<number, string> = {
	1: MAIN_ENDPOINT_MAINNET,
	10: MAIN_ENDPOINT_OP_MAINNET,
	420: MAIN_ENDPOINT_OP_GOERLI,
};

export const MARKETS: Record<FuturesMarketKey, FuturesMarketConfig> = {
	[FuturesMarketKey.ETHPERP]: {
		key: FuturesMarketKey.ETHPERP,
		asset: FuturesMarketAsset.ETH,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
			testnet: '0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6',
		},
		decimals: 18,
	},
	[FuturesMarketKey.BTCPERP]: {
		key: FuturesMarketKey.BTCPERP,
		asset: FuturesMarketAsset.BTC,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
			testnet: '0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b',
		},
		decimals: 8,
	},
	[FuturesMarketKey.SOLPERP]: {
		key: FuturesMarketKey.SOLPERP,
		asset: FuturesMarketAsset.SOL,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
			testnet: '0xfe650f0367d4a7ef9815a593ea15d36593f0643aaaf0149bb04be67ab851decd',
		},
		decimals: 9,
	},
	[FuturesMarketKey.DOGEPERP]: {
		key: FuturesMarketKey.DOGEPERP,
		asset: FuturesMarketAsset.DOGE,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
			testnet: '0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae',
		},
		decimals: 8,
	},
	[FuturesMarketKey.BNBPERP]: {
		key: FuturesMarketKey.BNBPERP,
		asset: FuturesMarketAsset.BNB,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
			testnet: '0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a',
		},
		decimals: 18,
	},
	[FuturesMarketKey.AVAXPERP]: {
		key: FuturesMarketKey.AVAXPERP,
		asset: FuturesMarketAsset.AVAX,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7',
			testnet: '0xd7566a3ba7f7286ed54f4ae7e983f4420ae0b1e0f3892e11f9c4ab107bbad7b9',
		},
		decimals: 18,
	},
	[FuturesMarketKey.UNIPERP]: {
		key: FuturesMarketKey.UNIPERP,
		asset: FuturesMarketAsset.UNI,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501',
			testnet: '0x64ae1fc7ceacf2cd59bee541382ff3770d847e63c40eb6cf2413e7de5e93078a',
		},
		decimals: 18,
	},
	[FuturesMarketKey.ADAPERP]: {
		key: FuturesMarketKey.ADAPERP,
		asset: FuturesMarketAsset.ADA,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d',
			testnet: '0x73dc009953c83c944690037ea477df627657f45c14f16ad3a61089c5a3f9f4f2',
		},
		decimals: 6,
	},
	[FuturesMarketKey.BLURPERP]: {
		key: FuturesMarketKey.BLURPERP,
		asset: FuturesMarketAsset.BLUR,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x856aac602516addee497edf6f50d39e8c95ae5fb0da1ed434a8c2ab9c3e877e9',
			testnet: '0xbe2dbc97659e92bf07462aeda414195246515e6b17abd6997f0ab2297cb03e1d',
		},
		decimals: 18,
	},
	[FuturesMarketKey.SEIPERP]: {
		key: FuturesMarketKey.SEIPERP,
		asset: FuturesMarketAsset.SEI,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb',
			testnet: '0x95f1aef92c74670d40f1f37ee6fb158ffd6a0f8913b07b9e61087f9b7273b11c',
		},
		decimals: 18,
	},
	[FuturesMarketKey.SUIPERP]: {
		key: FuturesMarketKey.SUIPERP,
		asset: FuturesMarketAsset.SUI,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744',
			testnet: '0x50c67b3fd225db8912a424dd4baed60ffdde625ed2feaaf283724f9608fea266',
		},
		decimals: 9,
	},
	[FuturesMarketKey.SHIBPERP]: {
		key: FuturesMarketKey.SHIBPERP,
		asset: FuturesMarketAsset.SHIB,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a',
			testnet: '0x672fbb7d9ec665cfbe8c2ffa643ba321a047b7a72d7b6d7c3d8fb120fc40954b',
		},
		decimals: 18,
	},
	[FuturesMarketKey.APTPERP]: {
		key: FuturesMarketKey.APTPERP,
		asset: FuturesMarketAsset.APT,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x03ae4db29ed4ae33d323568895aa00337e658e348b37509f5372ae51f0af00d5',
			testnet: '0x44a93dddd8effa54ea51076c4e851b6cbbfd938e82eb90197de38fe8876bb66e',
		},
		decimals: 8,
	},
	[FuturesMarketKey.INJPERP]: {
		key: FuturesMarketKey.INJPERP,
		asset: FuturesMarketAsset.INJ,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
			testnet: '0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3',
		},
		decimals: 18,
	},
	[FuturesMarketKey.LINKPERP]: {
		key: FuturesMarketKey.LINKPERP,
		asset: FuturesMarketAsset.LINK,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
			testnet: '0x83be4ed61dd8a3518d198098ce37240c494710a7b9d85e35d9fceac21df08994',
		},
		decimals: 18,
	},
	[FuturesMarketKey.PEPEPERP]: {
		key: FuturesMarketKey.PEPEPERP,
		asset: FuturesMarketAsset.PEPE,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4',
			testnet: '0xed82efbfade01083ffa8f64664c86af39282c9f084877066ae72b635e77718f0',
		},
		decimals: 18,
	},
	[FuturesMarketKey.APEPERP]: {
		key: FuturesMarketKey.APEPERP,
		asset: FuturesMarketAsset.APE,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x15add95022ae13563a11992e727c91bdb6b55bc183d9d747436c80a483d8c864',
			testnet: '0xcb1743d0e3e3eace7e84b8230dc082829813e3ab04e91b503c08e9a441c0ea8b',
		},
		decimals: 18,
	},
	[FuturesMarketKey.BONKPERP]: {
		key: FuturesMarketKey.BONKPERP,
		asset: FuturesMarketAsset.BONK,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x72b021217ca3fe68922a19aaf990109cb9d84e9ad004b4d2025ad6f529314419',
			testnet: '0x53377a90ac8ddbd1cad1a65dd705f12eeba0be01510777a1e25fcd03f295572d',
		},
		decimals: 5,
	},
	[FuturesMarketKey.SNXPERP]: {
		key: FuturesMarketKey.SNXPERP,
		asset: FuturesMarketAsset.SNX,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x39d020f60982ed892abbcd4a06a276a9f9b7bfbce003204c110b6e488f502da3',
			testnet: '0xe956a4199936e913b402474cb29576066f15108121d434606a19b34036e6d5cc',
		},
		decimals: 18,
	},
	[FuturesMarketKey.MEMEPERP]: {
		key: FuturesMarketKey.MEMEPERP,
		asset: FuturesMarketAsset.MEME,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0xcd2cee36951a571e035db0dfad138e6ecdb06b517cc3373cd7db5d3609b7927c',
			testnet: '0xe46dee29c768f526c17b91a27621d53260454633f33a8a393248a69f090844ed',
		},
		decimals: 18,
	},
	[FuturesMarketKey.FLOKIPERP]: {
		key: FuturesMarketKey.FLOKIPERP,
		asset: FuturesMarketAsset.FLOKI,
		supports: 'both',
		version: 2,
		pythIds: {
			mainnet: '0x6b1381ce7e874dc5410b197ac8348162c0dd6c0d4c9cd6322672d6c2b1d58293',
			testnet: '0x57596fe1a697014b962ac9e693dee99c4bb01d6c5eca271a1a26ad475a92cdbd',
		},
		decimals: 9,
	},
};

export const MARKET_ASSETS_BY_PYTH_ID = Object.values(MARKETS)
	.filter((m) => !!m.pythIds)
	.reduce((acc, m) => {
		acc[m.pythIds!.mainnet] = m.asset;
		acc[m.pythIds!.testnet] = m.asset;
		return acc;
	}, {} as Record<string, FuturesMarketAsset>);

export const MARKETS_LIST = Object.values(MARKETS).filter((m) => !m.disabled);

export const V2_MARKETS = Object.entries(MARKETS).reduce((acc, [key, m]) => {
	if (m.version === 2) acc[key as FuturesMarketKey] = m;
	return acc;
}, {} as Record<FuturesMarketKey, FuturesMarketConfig>);

export const V2_MARKETS_LIST = Object.values(V2_MARKETS).filter((m) => !m.disabled);

export const MAINNET_MARKETS = MARKETS_LIST.filter(
	(m) => m.supports === 'mainnet' || m.supports === 'both'
);

export const TESTNET_MARKETS = MARKETS_LIST.filter(
	(m) => m.supports === 'testnet' || m.supports === 'both'
);

export const COOLDOWN_PERIOD = 24 * 60 * 60;