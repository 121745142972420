import Wei, { wei } from '@synthetixio/wei';

export const serializeWeiObject = (object: object) => {
	return Object.entries(object).reduce((acc, [key, value]) => {
		if (!value) {
			acc[key] = value;
		} else if (value instanceof Wei) {
			acc[key] = value.toString();
		} else if (typeof value === 'object') {
			acc[key] = serializeWeiObject(value);
		} else {
			acc[key] = value;
		}

		return acc;
	}, {} as any);
};

export const deserializeWeiObject = (object: object, keys: Set<string>, prefix?: string) => {
	return Object.entries(object).reduce((acc, [key, value]) => {
		if (!value) {
			acc[key] = value;
		} else if (keys.has(prefix ? `${prefix}.${key}` : key)) {
			acc[key] = wei(value);
		} else if (typeof value === 'object') {
			acc[key] = deserializeWeiObject(value, keys, key);
		} else {
			acc[key] = value;
		}

		return acc;
	}, {} as any);
};
