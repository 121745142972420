import InfluxSDK from 'sdk';

import { DEFAULT_NETWORK_ID } from 'constants/defaults';
import { get } from 'lodash';
import {
	getFuturesTrades
} from 'queries/futures/subgraph';
import { allMarkets } from 'sdk/data/market';

export const queryTrades = async (
	sdk: InfluxSDK,
	query: string,
	params: {
		walletAddress: string;
		currencyKey?: string;
		pageLength: number;
	}
) => {
	const filter: Record<string, string> = {
		_user: params.walletAddress,
	};
	if (params.currencyKey) {
		filter['_tokenAddress'] = get(allMarkets, [params.currencyKey ?? '__', 'addresses', DEFAULT_NETWORK_ID], null);
	}
	return getFuturesTrades(
		sdk.futures.futuresGqlEndpoint,
		query,
		{
			first: params.pageLength,
			where: filter,
			orderDirection: 'desc',
			orderBy: 'blockTimestamp',
		},
		{
			id: true,
			blockTimestamp: true,
			_user: true,
			_tokenAddress: true,
			_cost: true,
			_position: true,
		}
	);
};