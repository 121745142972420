import styled from 'styled-components';

import { FlexDivCol } from 'components/layout/flex';
import { Body } from 'components/Text';

export const Title = styled(Body).attrs({ weight: 'bold' })`
	font-size: 16px;
	text-align: left;
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
`;

export const Copy = styled(Body)`
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
	text-align: left;
	color: ${(props) => props.theme.colors.silver};
`;

export const FeatureCard = styled(FlexDivCol)`
	padding: 25px;
	background-color: #171717;
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.05);
	:hover {
		background-color: #1c1c1c;
	}
`;
